import {destroy, flow, getEnv, getParent, Instance, types} from "mobx-state-tree"
import {ApiResult} from "../ApiResult";
import {IBenefit} from "./Benefit";
import {CountryConfig} from "./CountryConfig";
import {IOrganization, Organization} from "./Organization"
import {IVenue, Venue} from "./Venue"
import {IRootStore} from "./RootStore"
import log from "loglevelnext"
import {Country} from "./Country"
import { reset, setGroup } from "../utils/analytics";

const GLOBAL_PERMISSION_ROLE_SUPER_ADMIN = "SUPER_ADMIN"


const UserProfile = types.model("UserProfile")
  .props({
    id: types.maybe(types.string),
    email: types.maybe(types.string),
    name: types.maybe(types.string),
    createdAt: types.maybe(types.string),
    intercomHash: types.maybe(types.string),
    globalPermissions: types.maybe(types.array(types.string))
  })


const getOrganizationIDFromURL = () => {
  const urlVars = window.location.pathname.split('/')
  if (urlVars.length >= 2 && urlVars[1] === 'manage') {
    return urlVars[2]
  } else {
    return ''
  }
}

const removeSpacesFromString = (str: string) => str.replace(/\s+/g, '')

export const UserSessionStore = types.model("UserSessionStore")
  .props({
    loggedIn: types.optional(types.boolean, false),
    alertMessage: types.maybe(types.string),
    alertSource: types.maybe(types.string),
    profile: types.maybe(UserProfile),
    countries: types.optional(types.array(Country), []),
    countryConfig: types.maybeNull(CountryConfig),
    organizations: types.optional(types.array(Organization), []),
    currentOrganization: types.maybe(types.reference(Organization)),
    selectedBranch: types.maybe(types.union(types.reference(Organization), types.reference(Venue))),
    csrfToken: types.maybe(types.string),
    requestEtags: types.optional(types.map(types.string), {})
  })
  .views(self => ({
    isOrganizationContext() {
      return self.selectedBranch && "venues" in self.selectedBranch
    },
    isLoggedIn() {
      return self.loggedIn
    },
    getCSRFToken() {
      return localStorage.getItem("csrfToken")
    },
    getRequestEtag(requestHash: string) {
      return self.requestEtags.get(requestHash)
    },
    get selectedBranchVenueIds() {
      if (!self.selectedBranch) return []
      return "venues" in self.selectedBranch! ?
        self.currentOrganization!.venueIds :
        [self.selectedBranch!.id]
    },
    get isSuperAdmin() {
      return self.profile && self.profile!.globalPermissions!.includes(GLOBAL_PERMISSION_ROLE_SUPER_ADMIN)
    }
  })).actions(self => ({
    fetchCurrentCountryConfig: flow(function* fetchCurrentCountryConfig() {
      const response: ApiResult = yield getEnv(self).api.fetchCountryConfig(self.currentOrganization?.countryId.id)
      self.countryConfig = response.payload.data
    }),
  })).actions(self => ({
    setRequestEtag(requestHash: string, etag: string) {
      self.requestEtags.set(requestHash, etag)
    },
    setSelectedOrganization(organization: IOrganization) {
      log.debug("Set selected organization: ", organization.name)
      self.requestEtags.clear()

      self.currentOrganization = organization
      if (self.currentOrganization!.isSingleVenue()) {
        self.selectedBranch = self.currentOrganization!.venues[0]
      } else {
        self.selectedBranch = self.currentOrganization
      }

      setGroup('organization', organization.id, {
        name: organization.name,
        country: organization.countryId.name,
        isPremium: organization.isPremium,
        isSingleVenue: organization.isSingleVenue(),
        venueCount: organization.venues?.length || 0
      })

      self.fetchCurrentCountryConfig().then()

      const rootStore = getParent(self) as IRootStore
      rootStore.fetchData(true, false, false)
    },
  })).actions(self => ({
    logIn(sessionData: any, initSession: boolean = false) {
      log.debug("UserSessionStore: logIn, user:", sessionData.profile.email + ", initSession:", initSession)
      self.profile = sessionData.profile
      self.countries = sessionData.countries
      self.organizations.replace(sessionData.organizations)

      if (self.profile) {
        const userForGTM = {
          'username': self.profile.name,
          'useremail': self.profile.email,
          'created_at': self.profile.createdAt ? Math.round(new Date(self.profile.createdAt).getTime() / 1000) : '',
          'userid': self.profile.id,
          'event': 'user-loaded',
          'user_hash': self.profile.intercomHash
        }

        // @ts-ignore
        if (window.dataLayer) {
          // @ts-ignore
          window.dataLayer.push(userForGTM);
        }
      }

      if (initSession) {
        self.setSelectedOrganization(self.organizations[0])
      }

      self.loggedIn = true
    },
    setSelectedBranch(branch: IOrganization | IVenue) {
      if ("venues" in branch) {
        self.currentOrganization = branch
        log.debug("UserSessionStore: setSelectedBranch, organization:", branch.name)
      } else {
        self.currentOrganization = getParent(branch, 2) as IOrganization
        log.debug("UserSessionStore: setSelectedBranch, venue:", branch.name)

      }
      self.selectedBranch = branch

      self.fetchCurrentCountryConfig().then()
    },
    setSelectedBranchFallback() {
      const organizationByURL = self.organizations.find(organization => organization.id === getOrganizationIDFromURL())
      if (organizationByURL) {
        self.setSelectedOrganization(organizationByURL)
      } else {
        self.setSelectedOrganization(self.organizations[0])
      }

      //self.currentOrganization = self.organizations[0]
      //self.selectedBranch = self.organizations[0]
    },
    setCSRFToken(csrfToken: string) {
      localStorage.setItem("csrfToken", csrfToken)
    },
    logOut() {
      log.debug("UserSessionStore: logOut")

      self.loggedIn = false
      self.profile = undefined
      self.selectedBranch = undefined
      self.currentOrganization = undefined

      const rootStore = getParent(self) as IRootStore
      rootStore.resetData()

      self.organizations.clear()
      localStorage.removeItem("csrfToken")

      reset()
    },
    setAlertMessage(message: string, source: string | undefined = undefined) {
      log.debug("UserSessionStore: setAlertMessage, source:", source)
      self.alertMessage = message
      self.alertSource = source
    },
    clearAlertMessage() {
      log.debug("UserSessionStore: clearAlertMessage")
      self.alertMessage = undefined
      self.alertSource = undefined
    },
    addOrganization(organization: any) {
      self.organizations.push(organization)
    },
    addOrganizationAndSetAsSelected(organization: any) {
      self.organizations.push(organization)
      self.setSelectedOrganization(self.organizations[self.organizations.length - 1])
    },
    updateOrganization(organization: any) {
      const organizationToUpdateIndex = self.organizations.findIndex(o => o.id === organization.id)
      self.organizations[organizationToUpdateIndex].name = organization.name
    }
  }))

export type IUserSessionStore = Instance<typeof UserSessionStore>
