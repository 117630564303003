import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, useField, useFormikContext } from "formik";
import React, { useState } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { WithTranslation } from "react-i18next/*";
import { MerchantAdminApi } from "../../Api";
import { ServerVenue } from "../../models/server/ServerVenue";
import { IUserSessionStore } from "../../models/UserSessionStore";
import EditLocationModal from "./EditLocationModel";

interface MultiLocationFieldProps extends WithTranslation {
  api?: MerchantAdminApi;
  userSessionStore?: IUserSessionStore;
}

const MultiLocationModalField = (props: MultiLocationFieldProps) => {
  const { t } = props;
  const { values } = useFormikContext<ServerVenue>();
  const [isEditLocationModalOpen, setEditLocationModalOpen] = useState(false);
  const [editLocationIndex, setEditLocationIndex] = useState<number | undefined>(undefined);

  const [locationsField, locationsMeta, locationsHelper] = useField<ServerVenue["locations"]>("locations");
  const [cityField] = useField<ServerVenue["locations"][0]["city"] | undefined>("locations[0].city");

  const canChangeCity = values.locations.length == 0 || // There are no locations
    (values.locations.length == 1 && editLocationIndex === 0); // There is a location, but you're editing it
  return (<>
    <FieldArray name="locations">
      {({ push, remove, replace }) => (
        <>
          <ListGroup className="ml-0 my-3">
            {values.locations.map((location, index) => (
              <ListGroupItem key={location.publicId ?? index} className="p-0 location-item">
                <div className="d-flex align-items-stretch">
                  <div className="gutter">
                    {index + 1}
                  </div>
                  <button className="location-data" onClick={(e) => {
                    e.preventDefault();
                    setEditLocationIndex(index)
                    setEditLocationModalOpen(true)
                  }}>
                    <div className="textInputLabel">{location.name ?? `Location ${index + 1}`}</div>
                    <div>{location.address}</div>
                    <div className="small">{location.city} {location.postcode} {location.country}</div>
                    <div className="overlay d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon icon={["fal", "pencil"]} />
                    </div>
                  </button>
                  {values.locations.length > 1 ?
                    <button className="gutter destructive" onClick={() => remove(index)} >
                      <FontAwesomeIcon icon={["fal", "trash"]} />
                    </button>
                    : null}
                </div>

              </ListGroupItem>
            ))}
            <ListGroupItem className="p-3 location-item d-flex align-items-center justify-content-center flex-row add-location" as="button"
              onClick={(e) => {
                e.preventDefault()
                setEditLocationIndex(undefined)
                setEditLocationModalOpen(true)
              }}
            >
              <div className="mx-2"><FontAwesomeIcon icon={["fas", "plus"]} /></div>
              <div>{props.t("manageVenuesScreen.addNewLocation")}</div>
            </ListGroupItem>
          </ListGroup>
          {locationsMeta.error && locationsMeta.touched ? <small className="text-danger d-block invalid-feedback">{t(`manageVenuesScreen.errors.locations`)}</small> : null}

          <EditLocationModal restrictToCity={canChangeCity ? undefined : cityField.value} venueLocation={editLocationIndex !== undefined ? values.locations[editLocationIndex] : undefined} show={isEditLocationModalOpen} {...props} handleClose={(newLocation) => {
            if (newLocation) {
              if (editLocationIndex !== undefined) {
                replace(editLocationIndex, newLocation)
              } else {
                push(newLocation)
              }
            }
            setEditLocationIndex(undefined)
            setEditLocationModalOpen(false)
          }} />
        </>
      )}
    </FieldArray>


  </>);
};

export default MultiLocationModalField;
