import { ApiResponse } from "apisauce"
import { v4 as uuidv4 } from "uuid"

export interface ApiResult<T = any> {
  modified: boolean
  payload: T
  etag: string
}

export function getApiResult<T = any>(response: ApiResponse<T>): ApiResult<T> {

  // @FIXME: Remove current ETAG-code from the partner hub or add real support for it to the backend
  // This hack just makes sure that the ETAG-code is always new for the new data
  return {
    modified: response.status !== 304,
    payload: response.data,
    etag: response.status === 304 ? response.config?.headers["If-Match"] : (response.headers && response.headers.etag ? response.headers["etag"] : uuidv4() + "" + Date.now())
  } as ApiResult<T>
}
