import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { inject, observer } from "mobx-react"
import { useState } from "react"
import Button from "react-bootstrap/Button"
import Dropdown from "react-bootstrap/Dropdown"
import { withTranslation, WithTranslation } from "react-i18next"
import { RouteComponentProps, withRouter } from "react-router"
import { MerchantAdminApi } from "../Api"
import AlertMessage from "../components/AlertMessage"
import { MessagesTable } from "../components/MessagesTable"
import ConnectEmailOctopusModal from "../components/Modals/ConnectEmailOctopusModal"
import { SummaryHeader, SummaryItem } from "../components/SummaryHeader"
import UpgradeToPremiumModal from "../components/UpgradeToPremiumModal"
import { ManageEmailOctopusApiKeyLocation, SendEmailLocation, SendPushNotificationLocation } from "../Locations"
import { IBenefitReportStore } from "../models/BenefitReportStore"
import { IMessageStore } from "../models/MessageStore"
import { IRootStore } from "../models/RootStore"
import { IUserSessionStore } from "../models/UserSessionStore"
import FeatureStartGuideCard, { GuideType } from '../components/FeatureStartGuideCard'
import SendPushMessageButton from '../components/buttons/SendPushMessageButton'
import { getHasOrganizationVenues } from "../components/FeatureStartGuideCard/utils"
import posthog from "posthog-js"

const BENEFIT_SCREEN_ERROR = "benefitScreen"

export interface MessagesScreenProps extends RouteComponentProps, WithTranslation {
  userSessionStore?: IUserSessionStore
  benefitReportStore?: IBenefitReportStore
  rootStore?: IRootStore
  api?: MerchantAdminApi
  messageStore?: IMessageStore
  memberStore?: any
}

const MEMBER_SCREEN_PUSH_NOTIFICATION_MODAL_ERROR = 'MEMBER_SCREEN_PUSH_NOTIFICATION_MODAL_ERROR'

export const MessagesScreen = (props: Readonly<MessagesScreenProps>) => {
    const [showSendModal, setShowSendModal] = useState(false);
    const [showConnectEmailOctopusModal, setShowConnectEmailOctopusModal] = useState(false);
    const {userSessionStore, memberStore, messageStore, benefitReportStore, history, t} = props

   

    const navigateToManageEmailOctopus = () => history.push(
      ManageEmailOctopusApiKeyLocation.toUrl({...userSessionStore!.selectedBranch!.urlFields()})
    )

const messagesData = userSessionStore?.isOrganizationContext() ?
  messageStore?.messages
  : 
  messageStore?.messages.filter(message => message.configuration?.venues.some(venue => venue.id === userSessionStore?.selectedBranch?.id))

   const summaries: Array<SummaryItem> = [
      {
        title: t("messagesScreen.summary.totalPushMessagesSentTitle"),
        value: messageStore?.getTotalPushNotificationsSent().toString() || '0',
        footer: t("messagesScreen.summary.total"),
      },
      {
        title: t("messagesScreen.summary.totalEmailsTitle"),
        value: messageStore?.getTotalEmailsSent().toString() || '0',
        footer: t("messagesScreen.summary.total"),
      },
    ]

    const isOrganizationPremium = userSessionStore?.currentOrganization?.isPremium
    const hasAddedEmailOctopus = memberStore!.emailOctopus?.id

    const headerButtons = (
      <>
      {!hasAddedEmailOctopus &&
        <Button
          className="mr-2"
          onClick={() => setShowConnectEmailOctopusModal(true)}
          variant="secondary">
            {t("emailOctopus.connect")}
        </Button>
      }
          
          <Dropdown 
            /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
            onClick={(e: any) => e.stopPropagation()}
            className="mr-2"
            alignRight>
            <Dropdown.Toggle variant="success" id="dropdown-edit-benefits-org">
              <FontAwesomeIcon className="mr-2" icon={["fal", "envelope"]}/>{t("messagesScreen.sendEmail")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {isOrganizationPremium ?
                <a className="dropdown-item" href={props.api!.getPartnerEmailCSVUrl(userSessionStore!.currentOrganization!.id)}
                download>{t("memberScreen.sendMessage.downloadCSV")}</a>
                :
                <Dropdown.Item
                  onClick={() => setShowSendModal(true)}
                >{t("memberScreen.sendMessage.downloadCSV")}</Dropdown.Item>
              }
              {hasAddedEmailOctopus &&
                <Dropdown.Item
                  onClick={() => navigateToManageEmailOctopus()}
                >
                  {t("emailOctopus.manage")}
                </Dropdown.Item>
              }
              
              {/* <Dropdown.Item
                onClick={() => isOrganizationPremium ? navigateToSendEmail() : setShowSendModal(true)}
              >{t("messagesScreen.createNewEmail")}</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>

          <SendPushMessageButton 
            onPremiumRequired={() => setShowSendModal(true)} 
          />

      </>
    )
    const membersData = memberStore!.members.slice()


    const hasMessages = Boolean(messageStore?.getTotalPushNotificationsSent());


    const hasVenues = getHasOrganizationVenues({userSessionStore})

    if (posthog.isFeatureEnabled('onboarding')) {
      if (!hasVenues) {
        return <FeatureStartGuideCard guideType={GuideType.CREATE_VENUE} />;
      }

      if (!hasMessages) {
        return <FeatureStartGuideCard guideType={GuideType.SEND_MESSAGE} />;
      }
    }

    return (
      <div>
        <AlertMessage source={BENEFIT_SCREEN_ERROR}/>
        <SummaryHeader summaries={summaries} isSingleVenue={userSessionStore!.currentOrganization!.isSingleVenue()} buttons={headerButtons}/>

        <UpgradeToPremiumModal
          showModal={showSendModal}
          setShowModal={(value: boolean) => setShowSendModal(value)}/>

        <ConnectEmailOctopusModal
          showModal={showConnectEmailOctopusModal}
          setShowModal={(value: boolean) => setShowConnectEmailOctopusModal(value)}/>

        <MessagesTable data={messagesData} onDateFilterChange={() => console.log('filter change')}
                      isOrganizationContext={userSessionStore!.isOrganizationContext()}/>
      </div>
    )
}

// export default withTranslation()(MessagesScreen)
export default withRouter(withTranslation()(inject("userSessionStore", "benefitReportStore", "api", "memberStore", "messageStore")(observer(MessagesScreen))))