import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { inject, observer } from "mobx-react"
import { useState } from "react"
import Dropdown from "react-bootstrap/Dropdown"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { RewardModifyLocation } from "../../Locations"
import { REWARD_TYPE } from "../../models/RewardStore"
import { IUserSessionStore } from "../../models/UserSessionStore"
import { IVenue } from "../../models/Venue"
import UpgradeToPremiumModal from "../../components/UpgradeToPremiumModal"

interface NewRewardButtonProps {
  userSessionStore?: IUserSessionStore
  variant?: "success" | "primary" | "outline-light"
  className?: string
}

const NewRewardButton = inject("userSessionStore")(
  observer(({ userSessionStore, variant = "success", className = "mr-2" }: NewRewardButtonProps) => {
    const { t } = useTranslation()
    const history = useHistory()
    const [showSendModal, setShowSendModal] = useState(false)

    const isOrganizationPremium = userSessionStore?.currentOrganization?.isPremium

    const navToRewardModifyScreen = (venue: IVenue, rewardType: string) =>
      history.push(RewardModifyLocation.toUrl({ ...venue.urlFields(), rewardId: "NEW", rewardType: rewardType }))

    if (!userSessionStore?.isSuperAdmin) {
      return null
    }

    return (
      <>
        <Dropdown
          className={className}
          /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
          onClick={(e: any) => e.stopPropagation()}
        >
          <Dropdown.Toggle variant={variant} id="dropdown-edit-benefits-org">
            <FontAwesomeIcon className="mr-2" icon={["fal", "plus-circle"]} />
            {t("rewardsScreen.newReward")}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                isOrganizationPremium
                  ? navToRewardModifyScreen(userSessionStore.selectedBranch! as IVenue, REWARD_TYPE.REWARD_BIRTHDAY)
                  : setShowSendModal(true)
              }}
            >
              {t("rewardsScreen.birthdayGift")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <UpgradeToPremiumModal
          showModal={showSendModal}
          setShowModal={(value: boolean) => setShowSendModal(value)}
        />
      </>
    )
  })
)

export default NewRewardButton 