import React from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IUserSessionStore } from '../../models/UserSessionStore';
import { SendPushNotificationLocation } from '../../Locations';

interface SendPushMessageButtonProps {
  userSessionStore?: IUserSessionStore;
  variant?: 'primary' | 'secondary' | 'success';
  className?: string;
  onPremiumRequired: () => void;
}

const SendPushMessageButton: React.FC<SendPushMessageButtonProps> = inject('userSessionStore')(observer(({ 
  userSessionStore,
  variant = 'success',
  className = '',
  onPremiumRequired
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isOrganizationPremium = userSessionStore?.currentOrganization?.isPremium;

  const navigateToSendPushNotif = () =>  {
    history.push(
      SendPushNotificationLocation.toUrl({
        ...userSessionStore!.selectedBranch!.urlFields(),
        messageId: "NEW"
      }),
      {
        messageId: "NEW"
      }
    );
  }


  const handleClick = () => {
    if (!isOrganizationPremium) {
      onPremiumRequired();
      return;
    }

   navigateToSendPushNotif()
  };

  return (
    <Button
      className={className}
      onClick={handleClick}
      variant={variant}
    >
      <FontAwesomeIcon className="mr-2" icon={['fal', 'mobile']} />
      {t('messagesScreen.sendPushMessage')}
    </Button>
  );
}));

export default SendPushMessageButton; 