import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Card, Collapse, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  BenefitsLocation,
  EventsLocation,
  InvitationsLocation,
  MembersLocation,
  MessagesLocation
} from '../../Locations';
import { IBenefitStore } from '../../models/BenefitStore';
import { IMessageStore } from '../../models/MessageStore';
import { IUserSessionStore } from '../../models/UserSessionStore';
import { colors } from '../../theme/colors';
import * as featureChecks from './featureCompletionChecks';
import OnboardingItem from './OnboardingItem';

const SKIPPED_ITEMS_STORAGE_KEY = 'cluby_skipped_onboarding_items';

interface SkippedItemsStore {
  organizations: Record<string, string[]>;
  venues: Record<string, string[]>;
}

interface OnboardingFeaturesGuideProps {
  benefitStore?: IBenefitStore;
  messageStore?: IMessageStore;
  userSessionStore?: IUserSessionStore;
  memberStore?: any;
  invitationsStore?: any;
  eventStore?: any;
  defaultCollapsed?: boolean;
}

interface CollapsedHeaderProps {
  welcomeText: string;
  progressPercentage: number;
  completedText: string;
}

const CollapsedHeader: React.FC<CollapsedHeaderProps> = ({ 
  welcomeText, 
  progressPercentage, 
  completedText 
}) => (
  <div 
    style={{ 
      display: "flex", 
      alignItems: "center", 
      height: "80px", 
      padding: "0 1rem"
    }}
  >
    <div style={{ width: "40px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <FontAwesomeIcon
        icon={['fal', 'tasks']}
        style={{ fontSize: "24px", color: colors.primaryText }}
      />
    </div>
    <div style={{ flex: 1, marginLeft: "1rem" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0.5rem" }}>
        <div style={{ fontWeight: 500 }}>{welcomeText}</div>
        <div style={{ fontWeight: 500 }}>{progressPercentage}% {completedText}</div>
      </div>
      <ProgressBar 
        now={progressPercentage} 
        variant="success"
        style={{ height: "6px", backgroundColor: "rgba(255, 255, 255, 0.1)" }}
      />
    </div>
    <div style={{ marginLeft: "1rem" }}>
      <FontAwesomeIcon
        icon={['fal', 'chevron-down']}
        style={{ color: 'rgba(255, 255, 255, 0.8)', fontSize: '22px' }}
      />
    </div>
  </div>
);

interface ExpandedHeaderProps {
  welcomeText: string;
  completeProfileText: string;
}

const ExpandedHeader: React.FC<ExpandedHeaderProps> = ({ 
  welcomeText, 
  completeProfileText 
}) => (
  <Card.Header 
    as="h5" 
    style={{ 
      borderBottom: `1px solid ${colors.stroke}`,
      padding: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: "#252525"
    }}
  >
    <div>
      <div>{welcomeText}</div>
      <div style={{ 
        fontSize: '0.8em',
        marginTop: '0.5rem',
        fontWeight: 'normal'
      }}>
        {completeProfileText}
      </div>
    </div>
    <FontAwesomeIcon
      icon={['fal', 'chevron-up']}
      style={{ color: 'rgba(255, 255, 255, 0.5)' }}
    />
  </Card.Header>
);

interface GuideItem {
  id: string;
  title: string;
  text: string;
  isCompleted: boolean;
  onClick: () => void;
}

interface GuideContentProps {
  isCollapsed: boolean;
  guideItems: GuideItem[];
  skippedItems: string[];
  onSkip: (id: string) => void;
}

const GuideContent: React.FC<GuideContentProps> = ({ 
  isCollapsed, 
  guideItems, 
  skippedItems, 
  onSkip 
}) => {
  const activeGuideItems = guideItems.filter(item => !skippedItems.includes(item.id));
  
  return (
    <div 
      style={{ 
        maxHeight: isCollapsed ? "0" : "2000px", 
        opacity: isCollapsed ? 0 : 1,
        overflow: "hidden",
        visibility: isCollapsed ? "hidden" : "visible",
        transition: isCollapsed 
          ? "max-height 0.4s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s ease, visibility 0s 0.4s"
          : "max-height 0.6s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.4s ease, visibility 0s",
      }}
    >
      {activeGuideItems.map((item, index) => (
        <OnboardingItem
          key={item.id}
          id={item.id}
          title={item.title}
          text={item.text}
          isCompleted={item.isCompleted}
          isSkipped={false}
          onClick={item.onClick}
          onSkip={onSkip}
          showBottomBorder={index < activeGuideItems.length - 1}
        />
      ))}
    </div>
  );
};

interface SkippedItemsProps {
  skippedItems: string[];
  guideItems: GuideItem[];
  onSkip: (id: string) => void;
}

const SkippedItems: React.FC<SkippedItemsProps> = ({ 
  skippedItems, 
  guideItems, 
  onSkip 
}) => {
  const { t } = useTranslation();
  const [showSkippedItems, setShowSkippedItems] = useState(false);
  
  const skippedGuideItems = guideItems.filter(item => skippedItems.includes(item.id));
  
  if (skippedItems.length === 0) {
    return null;
  }
  
  return (
    <div style={{ marginTop: '1rem' }}>
      <div
        onClick={() => setShowSkippedItems(!showSkippedItems)}
        aria-controls="skipped-items-collapse"
        aria-expanded={showSkippedItems}
        style={{ 
          color: colors.secondaryText, 
          textDecoration: 'none', 
          padding: '0.5rem 1rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          textAlign: 'left',
          cursor: 'pointer'
        }}
      >
        <div>
          <FontAwesomeIcon
            icon={['fas', 'forward']}
            style={{ marginRight: '0.5rem' }}
          />
          {t('onboardingFeaturesGuide.skippedItems', { count: skippedItems.length })}
        </div>
        <FontAwesomeIcon
          icon={showSkippedItems ? ['fal', 'chevron-up'] : ['fal', 'chevron-down']}
        />
      </div>
      <Collapse in={showSkippedItems}>
        <div id="skipped-items-collapse" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          {skippedGuideItems.map((item, index) => (
            <OnboardingItem
              key={item.id}
              id={item.id}
              title={item.title}
              text={item.text}
              isCompleted={item.isCompleted}
              isSkipped={true}
              onClick={item.onClick}
              onSkip={onSkip}
              showBottomBorder={index < skippedGuideItems.length - 1}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
};

const generateItemId = (title: string): string => {
  return `onboarding_${title.toLowerCase().replace(/\s+/g, '_')}`;
};

const getDefaultSkippedItemsStore = (): SkippedItemsStore => ({
  organizations: {},
  venues: {}
});

const getSkippedItemsFromStorage = (): SkippedItemsStore => {
  const storedData = localStorage.getItem(SKIPPED_ITEMS_STORAGE_KEY);
  if (!storedData) {
    return getDefaultSkippedItemsStore();
  }
  
  try {
    const parsedData = JSON.parse(storedData);

    return {
      organizations: parsedData.organizations || {},
      venues: parsedData.venues || {}
    };
  } catch (error) {
    console.error('Failed to parse skipped onboarding items from localStorage', error);
    return getDefaultSkippedItemsStore();
  }
};

const saveSkippedItemsToStorage = (data: SkippedItemsStore): void => {
  localStorage.setItem(SKIPPED_ITEMS_STORAGE_KEY, JSON.stringify(data));
};

const useRemoveCompletedFromSkipped = ({
  guideItems,
  skippedItems,
  skippedItemsStore,
  setSkippedItemsStore,
  setSkippedItems,
  isOrganizationContext,
  organizationId,
  selectedBranchId,
}: {
  guideItems: GuideItem[],
  skippedItems: string[],
  skippedItemsStore: SkippedItemsStore,
  setSkippedItemsStore: React.Dispatch<React.SetStateAction<SkippedItemsStore>>,
  setSkippedItems: React.Dispatch<React.SetStateAction<string[]>>,
  isOrganizationContext: boolean,
  organizationId: string | null | undefined,
  selectedBranchId: string | null | undefined,
}) => {
  useEffect(() => {
    const completedItemIds = guideItems
      .filter(item => item.isCompleted && skippedItems.includes(item.id))
      .map(item => item.id);
    
    if (completedItemIds.length === 0) return;
    
    const updatedStore = { ...skippedItemsStore };
    let hasChanges = false;
    
    // Remove completed items from organization skipped items
    if (isOrganizationContext && organizationId && updatedStore.organizations[organizationId]) {
      const orgSkippedItems = updatedStore.organizations[organizationId];
      const filteredOrgItems = orgSkippedItems.filter(id => !completedItemIds.includes(id));
      
      if (filteredOrgItems.length !== orgSkippedItems.length) {
        updatedStore.organizations[organizationId] = filteredOrgItems;
        hasChanges = true;
      }
    }
    
    // Remove completed items from venue skipped items
    if (!isOrganizationContext && selectedBranchId && updatedStore.venues[selectedBranchId]) {
      const venueSkippedItems = updatedStore.venues[selectedBranchId];
      const filteredVenueItems = venueSkippedItems.filter(id => !completedItemIds.includes(id));
      
      if (filteredVenueItems.length !== venueSkippedItems.length) {
        updatedStore.venues[selectedBranchId] = filteredVenueItems;
        hasChanges = true;
      }
    }
    
    if (hasChanges) {
      setSkippedItemsStore(updatedStore);
      setSkippedItems(prevItems => prevItems.filter(id => !completedItemIds.includes(id)));
    }
  }, [
    guideItems, 
    skippedItems, 
    skippedItemsStore, 
    isOrganizationContext, 
    organizationId, 
    selectedBranchId,
    setSkippedItemsStore,
    setSkippedItems
  ]);
};

const OnboardingFeaturesGuide: React.FC<OnboardingFeaturesGuideProps> = inject(
  'benefitStore',
  'messageStore',
  'userSessionStore',
  'memberStore',
  'invitationsStore',
  'eventStore'
)(observer(({ 
  benefitStore,
  messageStore,
  userSessionStore,
  memberStore,
  invitationsStore,
  eventStore,
  defaultCollapsed = false
}) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
  const [skippedItems, setSkippedItems] = useState<string[]>([]);
  const [skippedItemsStore, setSkippedItemsStore] = useState<SkippedItemsStore>(getDefaultSkippedItemsStore());
  const { t } = useTranslation();
  const history = useHistory();

  const isOrganizationContext = userSessionStore?.isOrganizationContext();
  const selectedBranchId = userSessionStore?.selectedBranch?.id;
  const organizationId = isOrganizationContext ? selectedBranchId : userSessionStore?.currentOrganization?.id;
  
  useEffect(() => {
    const storedData = getSkippedItemsFromStorage();
    setSkippedItemsStore(storedData);
    
    const contextSkippedItems: string[] = [];
    

    if (organizationId && storedData.organizations[organizationId]) {
      contextSkippedItems.push(...storedData.organizations[organizationId]);
    }
    

    if (!isOrganizationContext && selectedBranchId && storedData.venues[selectedBranchId]) {
      contextSkippedItems.push(...storedData.venues[selectedBranchId]);
    }
    

    setSkippedItems(Array.from(new Set(contextSkippedItems)));
  }, [isOrganizationContext, selectedBranchId, organizationId]);
  

  useEffect(() => {
    saveSkippedItemsToStorage(skippedItemsStore);
  }, [skippedItemsStore]);

  const selectedVenueId = !isOrganizationContext ? selectedBranchId : null;
  const hasVenues = Boolean(userSessionStore?.currentOrganization?.venues?.length);

  const hasBenefits = featureChecks.hasBenefits({benefitStore, isOrganizationContext, selectedVenueId});
  const hasMessages = featureChecks.hasMessages({messageStore});
  const hasSubscriptions = featureChecks.hasSubscriptions({memberStore, isOrganizationContext, selectedVenueId});
  const hasPrivateMembershipCards = featureChecks.hasPrivateMembershipCards({memberStore, isOrganizationContext, selectedVenueId});
  const hasPublicMembershipCards = featureChecks.hasPublicMembershipCards({memberStore, isOrganizationContext, selectedVenueId});
  const hasInvitedUsers = featureChecks.hasInvitedUsers({invitationsStore});
  const hasEvents = featureChecks.hasEvents({eventStore, isOrganizationContext, selectedVenueId});
  const hasPrivateCardMembers = featureChecks.hasPrivateCardMembers({memberStore, isOrganizationContext, selectedVenueId});


  const guideItems: GuideItem[] = [
    {
      id: generateItemId('invite_partner_hub_users'),
      title: t('invitationsScreen.invitePartnerHubUsers'),
      text: t('homeScreen.inviteFirstUser'),
      isCompleted: hasInvitedUsers,
      onClick: () => history.push(InvitationsLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
    },
    {
      id: generateItemId('benefits'),
      title: t('benefits'),
      text: t('homeScreen.createFirstBenefit'),
      isCompleted: hasBenefits,
      onClick: () => history.push(BenefitsLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
    },
    ...(userSessionStore?.currentOrganization?.isPremium ? [{
      id: generateItemId('messages'),
      title: t('messages'),
      text: t('homeScreen.sendFirstMessage'),
      isCompleted: hasMessages,
      onClick: () => history.push(MessagesLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
    }] : []),
    ...(userSessionStore?.currentOrganization?.isPremium ? [{
      id: generateItemId('private_membership_cards'),
      title: t('homeScreen.privateMembershipCards'),
      text: t('homeScreen.giveFirstMembershipCard'),
      isCompleted: hasPrivateCardMembers,
      onClick: () => history.push(MembersLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
    }] : []),
    {
      id: generateItemId('events'),
      title: t('events'),
      text: t('homeScreen.createFirstEvent'),
      isCompleted: hasEvents,
      onClick: () => history.push(EventsLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
    },
    ...(userSessionStore?.countryConfig?.salesEnabled ? [{
      id: generateItemId('subscriptions'),
      title: t('homeScreen.subscriptions'),
      text: t('homeScreen.createFirstSubscription'),
      isCompleted: hasSubscriptions,
      onClick: () => history.push(MembersLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
    }] : []),
    ...(userSessionStore?.countryConfig?.salesEnabled ? [{
      id: generateItemId('public_membership_cards'),
      title: t('homeScreen.publicMembershipCards'),
      text: t('homeScreen.createFirstMembershipCard'),
      isCompleted: hasPublicMembershipCards,
      onClick: () => history.push(MembersLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
    }] : [])
  ];
  
 
  useRemoveCompletedFromSkipped({
    guideItems,
    skippedItems,
    skippedItemsStore,
    setSkippedItemsStore,
    setSkippedItems,
    isOrganizationContext: Boolean(isOrganizationContext),
    organizationId,
    selectedBranchId
});
  

  const handleSkipItem = (itemId: string) => {

    const item = guideItems.find(item => item.id === itemId);
    if (item && item.isCompleted) return;
    
  
    const updatedStore = { ...skippedItemsStore };
    
    if (isOrganizationContext && organizationId) {
      const orgSkippedItems = updatedStore.organizations[organizationId] || [];
      
      if (!orgSkippedItems.includes(itemId)) {
        updatedStore.organizations[organizationId] = [...orgSkippedItems, itemId];
        setSkippedItems(prevItems => [...prevItems, itemId]);
      } else {
        updatedStore.organizations[organizationId] = orgSkippedItems.filter(id => id !== itemId);
        setSkippedItems(prevItems => prevItems.filter(id => id !== itemId));
      }
    } else if (selectedBranchId) {
    
      const venueSkippedItems = updatedStore.venues[selectedBranchId] || [];
      
      if (!venueSkippedItems.includes(itemId)) {
  
        updatedStore.venues[selectedBranchId] = [...venueSkippedItems, itemId];
        setSkippedItems(prevItems => [...prevItems, itemId]);
      } else {
     
        updatedStore.venues[selectedBranchId] = venueSkippedItems.filter(id => id !== itemId);
        setSkippedItems(prevItems => prevItems.filter(id => id !== itemId));
      }
    }

    setSkippedItemsStore(updatedStore);
  };

  const activeItems = guideItems.filter(item => !skippedItems.includes(item.id));
  const isAllCompleted = activeItems.every(item => item.isCompleted);
  const completedCount = activeItems.filter(item => item.isCompleted).length;
  const progressPercentage = activeItems.length > 0 
    ? Math.round((completedCount / activeItems.length) * 100)
    : 100; 

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const headerClickHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    toggleCollapse();
  };

  if (isAllCompleted || activeItems.length === 0) {
    return null;
  }

  const collapsedHeight = "80px";
  const expandedHeight = "auto";

  return (
    <div 
      className="onboarding-features-guide-wrapper" 
      style={{ 
        position: "relative",
        overflow: "hidden"
      }}
    >
      <Card 
        style={{ 
          backgroundColor: "#1e1e1e",
          color: "#ffffff",
          borderRadius: "5px",
          border: `1px solid ${colors.stroke}`,
          overflow: "hidden",
          height: isCollapsed ? collapsedHeight : expandedHeight,
          transition: "height 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
          willChange: "height"
        }}
      >
        <div
          style={{ 
            cursor: "pointer", 
            backgroundColor: "#252525"
          }}
          onClick={headerClickHandler}
        >
          {isCollapsed ? (
            <CollapsedHeader 
              welcomeText={t('homeScreen.welcomeToCluby')}
              progressPercentage={progressPercentage}
              completedText={t('completed')}
            />
          ) : (
            <ExpandedHeader
              welcomeText={t('homeScreen.welcomeToCluby')}
              completeProfileText={t('homeScreen.completeYourProfile')}
            />
          )}
        </div>

        <GuideContent 
          isCollapsed={isCollapsed}
          guideItems={guideItems}
          skippedItems={skippedItems}
          onSkip={handleSkipItem}
        />

        {!isCollapsed && (
          <SkippedItems 
            skippedItems={skippedItems} 
            guideItems={guideItems}
            onSkip={handleSkipItem}
          />
        )}
      </Card>
    </div>
  );
}));

export default OnboardingFeaturesGuide; 