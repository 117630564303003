import { faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, FieldInputProps, FieldProps, useField } from "formik";
import { toJS } from "mobx";
import { useEffect, useState } from "react";
import { Col, Container, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";
import { WithTranslation } from "react-i18next";
import { ServerVenue } from "../../models/server/ServerVenue";
import { IUserSessionStore } from "../../models/UserSessionStore";

type LocationType = ServerVenue["locations"][number];

interface SingleLocationFieldProps extends WithTranslation {
  userSessionStore?: IUserSessionStore;
  fieldName?: string;
  showName?: boolean;
  restrictToCity?: string;
}

function stdFieldName<T>(field: FieldInputProps<T>) {
  return field.name.replace(/\[\d+\]/g, '')
}

const findExactCityName = (cityName: string, AVAILABLE_CITIES_LIST: string[]): string | undefined => {
  return AVAILABLE_CITIES_LIST.find(city => city.localeCompare(cityName, undefined, { sensitivity: "base" }) === 0);
}

const SingleLocationField = ({ t, userSessionStore, fieldName, showName, restrictToCity }: SingleLocationFieldProps) => {
  const AVAILABLE_CITIES_LIST = Array.from(new Set(toJS(userSessionStore!.countryConfig!.cities).sort((a, b) => a.localeCompare(b))));
  const [isGPSOpen, setGPSOpen] = useState(false);
  const concreteFieldName = fieldName ?? "locations[0]";
  const [, , nameHelpers] = useField<LocationType["name"]>(`${concreteFieldName}.name`);
  const [cityField, , cityHelpers] = useField<LocationType["city"]>(`${concreteFieldName}.city`);
  const [latField, latMeta] = useField<LocationType["city"]>(`${concreteFieldName}.coordinates.lat`);
  const [lngField, lngMeta] = useField<LocationType["city"]>(`${concreteFieldName}.coordinates.lng`);
  const restrictCityName = restrictToCity ? findExactCityName(restrictToCity, AVAILABLE_CITIES_LIST) : undefined;

  useEffect(() => {
    // Set the default city to the first city in the list if not in initial values
    if (!cityField.value && AVAILABLE_CITIES_LIST.length > 0) {
      cityHelpers.setValue(AVAILABLE_CITIES_LIST[0]);
    }
  }, [])

  return (
    <>
      {showName === true ? (<Row>
        <Col xs={12}>
          <Field name={`${concreteFieldName}.name`}>
            {({ field, meta }: FieldProps<LocationType["name"] | undefined>) =>
              <FormGroup controlId="name">
                <FormLabel
                  className={`textInputLabel ${meta.error && meta.touched ? "text-danger" : ""}`}
                >
                  {t(`manageVenuesScreen.locationName`)}
                </FormLabel>
                <FormControl
                  className="textInput"
                  type={"text"}
                  isInvalid={meta.error && meta.touched ? true : false}
                  {...field}
                  value={field.value ?? ""}
                  onChange={(e) => {
                    // For the initial version, set the name to the address.
                    nameHelpers.setValue(field.value);
                    field.onChange(e);
                  }}
                />
                {meta.error && meta.touched ? <small className="text-danger d-block">{t(`manageVenuesScreen.errors.${stdFieldName(field)}`)}</small> : null}
              </FormGroup>
            }
          </Field>
        </Col>
      </Row>) : null}
      <Row>
        <Col xs={12}>
          <Field name={`${concreteFieldName}.address`}>
            {({ field, meta }: FieldProps<LocationType["address"] | undefined>) =>
              <FormGroup controlId="address">
                <FormLabel
                  className={`textInputLabel ${meta.error && meta.touched ? "text-danger" : ""}`}
                >
                  {t(`manageVenuesScreen.streetAddress`)}*
                </FormLabel>
                <FormControl
                  className="textInput"
                  type={"text"}
                  isInvalid={meta.error && meta.touched ? true : false}
                  {...field}
                  value={field.value ?? ""}
                  onChange={(e) => {
                    // For the initial version, set the name to the address.
                    if (showName !== true) {
                      nameHelpers.setValue(field.value);
                    }
                    field.onChange(e);
                  }}
                />
                {meta.error && meta.touched ? <small className="text-danger d-block">{t(`manageVenuesScreen.errors.${stdFieldName(field)}`)}</small> : null}
              </FormGroup>
            }
          </Field>
        </Col>
      </Row>

      <Row>
        <Col xs={12} lg={4}>
          <Field name={`${concreteFieldName}.postcode`}>
            {({ field, meta }: FieldProps<LocationType["postcode"] | undefined>) =>
              <FormGroup controlId="postcode">
                <FormLabel
                  className={`textInputLabel ${meta.error && meta.touched ? "text-danger" : ""}`}
                >
                  {t(`manageVenuesScreen.postalCode`)}
                </FormLabel>
                <FormControl
                  className="textInput"
                  type={"text"}
                  isInvalid={!!(meta.error && meta.touched)}
                  {...field}
                  value={field.value ?? ""}
                />
                {meta.error && meta.touched ? <small className="text-danger d-block">{t(`manageVenuesScreen.errors.${stdFieldName(field)}`)}</small> : null}
              </FormGroup>
            }
          </Field>
        </Col>
        <Col xs={12} lg={8}>
          <Field name={`${concreteFieldName}.city`}>
            {({ field, meta }: FieldProps<LocationType["city"] | undefined>) =>
              <FormGroup controlId="city">
                <FormLabel className={`textInputLabel ${meta.error && meta.touched ? "text-danger" : ""}`}>
                  {t(`manageVenuesScreen.city`)}*
                </FormLabel>
                <FormControl
                  className="textInput"
                  as="select"
                  isInvalid={!!(meta.error && meta.touched)}
                  {...field}
                  value={field.value ?? ""}
                >
                  {(restrictCityName ? [restrictCityName] : AVAILABLE_CITIES_LIST).map((city) => (
                    <option key={city}>{city}</option>
                  ))}
                </FormControl>
                {meta.error && meta.touched ? <small className="text-danger d-block">{t(`manageVenuesScreen.errors.${stdFieldName(field)}`)}</small> : null}
              </FormGroup>
            }
          </Field>
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="mb-1" onClick={() => setGPSOpen(old => !old)}>
          <FontAwesomeIcon
            icon={faChevronUp}
            className={`arrow-icon ${isGPSOpen ? "rotate-down" : "rotate-right"}`}
          /> <small>Precise Location*</small>
        </Col>
      </Row>
      <Container className={`p-0 gps-wrapper ${isGPSOpen ? "is-open" : ""}`}>
        <div className="inner">
          <Row>
            <Col xs={12} lg={5}>
              <Field name={`${concreteFieldName}.coordinates.lat`}>
                {({ field, meta }: FieldProps<LocationType["coordinates"]["lat"] | undefined>) =>
                  <FormGroup controlId="latitude">
                    <FormLabel className={`mb-0 textInputLabel ${meta.error && meta.touched ? "text-danger" : ""}`}>
                      {t(`manageVenuesScreen.latitude`)}*
                    </FormLabel>
                    <br />
                    <FormControl
                      className="textInput"
                      type={"text"}
                      isInvalid={!!(meta.error && meta.touched)}
                      {...field}
                      value={field.value ?? ""}
                      placeholder="23.4"
                    />
                    {meta.error && meta.touched ? <small className="text-danger d-block invalid-feedback">{t(`manageVenuesScreen.errors.${stdFieldName(field)}`)}</small> : null}
                  </FormGroup>
                }
              </Field>
            </Col>
            <Col xs={12} lg={5}>
              <Field name={`${concreteFieldName}.coordinates.lng`}>
                {({ field, meta }: FieldProps<LocationType["coordinates"]["lng"] | undefined>) =>
                  <FormGroup controlId="lng">
                    <FormLabel className={`mb-0 textInputLabel ${meta.error && meta.touched ? "text-danger" : ""}`}>
                      {t(`manageVenuesScreen.longitude`)}*
                    </FormLabel>
                    <br />
                    <FormControl
                      className="textInput"
                      type={"text"}
                      isInvalid={!!(meta.error && meta.touched)}
                      {...field}
                      value={field.value ?? ""}
                      placeholder="12.3"
                    />
                    {meta.error && meta.touched ? <small className="text-danger d-block invalid-feedback">{t(`manageVenuesScreen.errors.${stdFieldName(field)}`)}</small> : null}
                  </FormGroup>
                }
              </Field>
            </Col>

          </Row>
          {!latMeta.error && latField.value && !lngMeta.error && lngField.value ?
            <Row>
              <Col xs={12}>
                <a className="small" href={`https://www.google.com/maps/place/${latField.value},${lngField.value}`} target="_blank" rel="noreferrer" referrerPolicy="no-referrer">
                  Open in Google Maps
              </a>
              </Col>
            </Row> : null}
        </div>
      </Container>
    </>
  );
};

export default SingleLocationField;
