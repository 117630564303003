import { IBenefitStore } from '../../models/BenefitStore';
import { IEventStore } from '../../models/EventStore';
import { IInvitationStore } from '../../models/InvitationsStore';
import { IMemberStore } from '../../models/MemberStore';
import { IMessageStore } from '../../models/MessageStore';
import { IUserSessionStore } from '../../models/UserSessionStore';
import { PAYMENT_TYPE, STATUS_TYPE } from '../../screens/ManageMemberStatusesScreen';



export const getHasOrganizationVenues = ({userSessionStore}: {userSessionStore?: IUserSessionStore}) => {
  return Boolean(userSessionStore?.currentOrganization?.venues?.length)
}

export const hasBenefits = ({
  benefitStore,
  isOrganizationContext,
  selectedVenueId
}: {
  benefitStore: any | IBenefitStore,
  isOrganizationContext: boolean | undefined,
  selectedVenueId: string | null | undefined
}): boolean => {
  if (!benefitStore?.benefits?.length || isOrganizationContext === undefined) return false;
  
  if (isOrganizationContext) {
    return Boolean(benefitStore.benefits.length);
  } else {
    return Boolean(benefitStore.benefits.filter((benefit: any) => 
      benefit.restaurantId?.id === selectedVenueId
    ).length);
  }
};


export const hasMessages = ({
  messageStore,
}: {
  messageStore: any | IMessageStore,
}): boolean => {
  return Boolean(messageStore?.getTotalPushNotificationsSent());
};


export const hasSubscriptions = ({
  memberStore,
  isOrganizationContext,
  selectedVenueId
}: {
  memberStore: any | IMemberStore,
  isOrganizationContext: boolean | undefined,
  selectedVenueId: string | null | undefined
}): boolean => {
  if (!memberStore?.statusSubscriptions?.length || isOrganizationContext === undefined) return false;
  
  const filteredSubscriptions = memberStore.statusSubscriptions.filter((status: any) => {
    const matchesType = status.payments.type === PAYMENT_TYPE.RECURRING && 
                      status.subscriptionState === 'ACTIVE';
    if (isOrganizationContext) {
      return matchesType;
    } else {
      return matchesType && status.restaurantId?.id === selectedVenueId;
    }
  });
  
  return Boolean(filteredSubscriptions.length);
};


export const hasPrivateMembershipCards = ({
  memberStore,
  isOrganizationContext,
  selectedVenueId
}: {
  memberStore: any | IMemberStore,
  isOrganizationContext: boolean | undefined,
  selectedVenueId: string | null | undefined
}): boolean => {
  if (!memberStore?.statuses?.length || isOrganizationContext === undefined) return false;
  
  const filteredStatuses = memberStore.statuses.filter((status: any) => {
    const isPrivate = status.type === STATUS_TYPE.PRIVATE;
    if (isOrganizationContext) {
      return isPrivate;
    } else {
      return isPrivate && status.restaurantId?.id === selectedVenueId;
    }
  });
  
  return Boolean(filteredStatuses.length);
};


export const hasPublicMembershipCards = ({
  memberStore,
  isOrganizationContext,
  selectedVenueId
}: {
  memberStore: any | IMemberStore,
  isOrganizationContext: boolean | undefined,
  selectedVenueId: string | null | undefined
}): boolean => {
  if (!memberStore?.statuses?.length || isOrganizationContext === undefined) return false;
  
  const filteredStatuses = memberStore.statuses.filter((status: any) => {
    const isPublicVisible = status.type === STATUS_TYPE.PUBLIC && status.visible;
    if (isOrganizationContext) {
      return isPublicVisible;
    } else {
      return isPublicVisible && status.restaurantId?.id === selectedVenueId;
    }
  });
  
  return Boolean(filteredStatuses.length);
};


export const hasInvitedUsers = ({
  invitationsStore,
}: {
  invitationsStore: any | IInvitationStore,
}): boolean => {
  return Boolean(invitationsStore?.invited?.length || invitationsStore?.users?.length);
};


export const hasEvents = ({
  eventStore,
  isOrganizationContext,
  selectedVenueId
}: {
  eventStore: any | IEventStore,
  isOrganizationContext: boolean | undefined,
  selectedVenueId: string | null | undefined
}): boolean => {
  if (!eventStore?.events?.length || isOrganizationContext === undefined) return false;
  
  if (isOrganizationContext) {
    return Boolean(eventStore.events.length);
  } else {
    return Boolean(eventStore.events.filter((event: any) => 
      event.restaurantId?.id === selectedVenueId
    ).length);
  }
};


const GIVEN_PRIVATE_CARD_LOCAL_STORAGE_KEY = 'cluby_private_card_given'

export const markPrivateCardGiven = ({
  isOrganizationContext, 
  contextId
}: {
  isOrganizationContext: boolean | undefined, 
  contextId: string | null | undefined
}): void => {
  if (!contextId || isOrganizationContext === undefined) return;

  try {
    const storeKey = GIVEN_PRIVATE_CARD_LOCAL_STORAGE_KEY
    const currentStore = JSON.parse(localStorage.getItem(storeKey) || '{}');
    
    if (isOrganizationContext) {
      currentStore.organizations = currentStore.organizations || {};
      currentStore.organizations[contextId] = true;
    } else {
      currentStore.venues = currentStore.venues || {};
      currentStore.venues[contextId] = true;
    }
    
    localStorage.setItem(storeKey, JSON.stringify(currentStore));
  } catch (error) {
    console.error('Failed to mark private card as given', error);
  }
};

export const isPrivateCardGivenInLocalStorage = ({
  isOrganizationContext, 
  contextId
}: {
  isOrganizationContext: boolean | undefined, 
  contextId: string | null | undefined
}): boolean => {
  if (!contextId || isOrganizationContext === undefined) return false;

  try {
    const storeKey = GIVEN_PRIVATE_CARD_LOCAL_STORAGE_KEY;
    const storedData = localStorage.getItem(storeKey);
    
    if (!storedData) return false;
    
    const parsedData = JSON.parse(storedData);
    
    if (isOrganizationContext) {
      return Boolean(parsedData.organizations && parsedData.organizations[contextId]);
    } else {
      return Boolean(parsedData.venues && parsedData.venues[contextId]);
    }
  } catch (error) {
    console.error('Failed to check private card status in localStorage', error);
    return false;
  }
};

export const hasPrivateCardMembers = ({
  memberStore,
  isOrganizationContext,
  selectedVenueId
}: {
  memberStore: any | IMemberStore,
  isOrganizationContext: boolean | undefined,
  selectedVenueId: string | null | undefined
}): boolean => {
  if (isPrivateCardGivenInLocalStorage({isOrganizationContext, contextId: selectedVenueId})) {
    return true;
  }
  
  if (!memberStore?.stats?.venueStatuses?.length || !memberStore?.statuses?.length || isOrganizationContext === undefined) return false;
  
  const venueStatusesStats = memberStore.stats.venueStatuses;
  
  if (isOrganizationContext) {
    return Boolean(
      venueStatusesStats.some(
        (venueStatusStat: any) => 
          memberStore.statuses.some(
            (status: any) => 
              status.id === venueStatusStat.id.id && 
              status.type === STATUS_TYPE.PRIVATE && 
              venueStatusStat.membersCount > 0
          )
      )
    );
  } else {
    const venueSpecificStatuses = venueStatusesStats.filter(
      (venueStat: any) => venueStat.id.restaurantId?.id === selectedVenueId
    );
    
    return Boolean(
      venueSpecificStatuses.some(
        (venueStatusStat: any) => 
          memberStore.statuses.some(
            (status: any) => 
              status.id === venueStatusStat.id.id && 
              status.type === STATUS_TYPE.PRIVATE && 
              status.restaurantId?.id === selectedVenueId && 
              venueStatusStat.membersCount > 0
          )
      )
    );
  }
}; 