import { inject, observer } from "mobx-react";
import posthog from "posthog-js";
import { Spinner } from "react-bootstrap";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import FeatureStartGuideCard, { GuideType } from "../../components/FeatureStartGuideCard";
import OnboardingFeaturesGuide from "../../components/OnboardingFeaturesGuide";
import { SummaryHeader, SummaryItem } from "../../components/SummaryHeader";
import { IBenefitReportStore } from "../../models/BenefitReportStore";
import { IEventStore } from "../../models/EventStore";
import { ILoyaltyCardStore } from "../../models/LoyaltyCardStore";
import { IMemberStore } from "../../models/MemberStore";
import { IMessageStore } from "../../models/MessageStore";
import { IRewardStore } from "../../models/RewardStore";
import { IUserSessionStore } from "../../models/UserSessionStore";
import MemberDemography from "./MemberDemography";
import MemberGrowthTimeline from "./MemberGrowthTimeline";
import NewMembersList from "./NewMembersList";
import RecentActivitiesList from "./RecentActivitiesList";
import { getEventsOrganizationSummary, isSummaryShowable, sortSummariesByValueDescending } from "./utils";

interface HomeScreenProps extends RouteComponentProps, WithTranslation {
  userSessionStore?: IUserSessionStore;
  memberStore?: IMemberStore;
  messageStore?: IMessageStore;
  benefitReportStore?: IBenefitReportStore;
  rewardStore?: IRewardStore;
  loyaltyCardStore?: ILoyaltyCardStore;
  eventStore?: IEventStore;
}


const hasVenueMembers = ({
  memberStore, 
}: {
  memberStore: IMemberStore | undefined, 
}): boolean => {
  if (!memberStore?.stats) return false;

  
  return Boolean(memberStore.stats.membersCount);
};


const HomeScreen = inject(
  "userSessionStore",
  "memberStore",
  "messageStore",
  "benefitReportStore",
  "loyaltyCardStore",
  "rewardStore",
  "eventStore"
)(
  observer((props: HomeScreenProps) => {
    const { t, userSessionStore, memberStore, messageStore, benefitReportStore, loyaltyCardStore, rewardStore, eventStore } = props;

    const hasVenues = Boolean(userSessionStore?.currentOrganization?.venues?.length);
    
    const hasCurrentVenueMembers = hasVenueMembers({memberStore});

    const rewardsSummariesData = loyaltyCardStore?.getRewardsSummaries()

    const venueStatusesStats = memberStore!.stats?.venueStatuses?.slice() || []

    const pushNotificationsSentLast30Days = messageStore?.getPushNotificationsSentLast30Days() || '0'

    const membersSummaries: Array<SummaryItem> = [
      {
        title: t("memberScreen.summary.totalMembersTitle"),
        value: memberStore?.stats?.membersCount?.toString() || '0',
        footer: t("messagesScreen.summary.total"),
      },
      {
        title: t("memberScreen.summary.newMembersTitle"),
        value: memberStore?.stats?.membersLastMonth?.toString() || '0',
        footer: t("memberScreen.summary.newMembersFooter"),
      },
    ]

    const benefitsSummaries: Array<SummaryItem> = [
      {
        title: t("benefits"),
        value: benefitReportStore!.reporting.benefitsUsedTotal.toString(),
        footer: t("messagesScreen.summary.total"),
      },
    ] 

    const messagesSummaries: Array<SummaryItem> = [
      {
        title: t("homeScreen.messages"),
        value: messageStore?.getTotalPushNotificationsSent().toString() || '0',
        footer: t("messagesScreen.summary.total"),
      },
    ]

    const rewardsSummaries: Array<SummaryItem> = [
      ...(Boolean(rewardsSummariesData?.totalRewardsCollected) ? [     {
        title: t("homeScreen.rewards"),
        value: String(rewardsSummariesData?.totalRewardsCollected),
        footer: t("messagesScreen.summary.total"),
      },]: []),
    ]

    const eventsSummaries: Array<SummaryItem> = eventStore
      ? [
          {
            title: t("eventScreen.summary.ticketsSoldHeader"),
            value: (() => {
              if (userSessionStore?.isOrganizationContext()) {
                return getEventsOrganizationSummary({eventStore, field: "entrances"}).toString();
              } else {
                const branchId = userSessionStore?.selectedBranch?.id;
                const branchSummary = branchId ? eventStore.summaries.get(branchId) : undefined;
                return branchSummary?.entrances?.toString() || '0';
              }
            })(),
            footer: t("eventScreen.summary.ticketsSoldFooter"),
          },
        ]
      : [];

    const venueStatusSummaries: Array<SummaryItem> = venueStatusesStats?.map((venueStatusStat) => {
      return {
        venue: userSessionStore!.currentOrganization!.isSingleVenue() ? undefined : venueStatusStat.id?.restaurantId?.name,
        title: venueStatusStat.id.localeTitle,
        value: venueStatusStat.membersCount?.toString() || '0',
        footer: t("memberScreen.summary.statusMembersFooter"),
      }
    }) || []

    const sortedSummaries = [
      ...membersSummaries,
      ...benefitsSummaries,
      ...rewardsSummaries,
      ...eventsSummaries,
      ...messagesSummaries,
      ...sortSummariesByValueDescending(venueStatusSummaries)
    ].filter(isSummaryShowable)

    const membersTotalCountTimelineTotals = memberStore!.getMembersTotalCountTimeline()
    const memberDemographicsTotals = memberStore!.getMemberDemographics()
    

    const shouldCollapseGuide = Boolean(memberStore?.stats?.membersCount && memberStore?.stats?.membersCount > 10);

    const hasNewMembers = Boolean(memberStore?.newMembers?.length)
    const hasRecentActivities = Boolean(memberStore?.recentActivities?.length)
    

    if (posthog.isFeatureEnabled('onboarding')) {
    if (!hasVenues) {
      return <FeatureStartGuideCard guideType={GuideType.CREATE_VENUE} />;
    }
  }

    return (
      <div>


      {hasCurrentVenueMembers &&
 <SummaryHeader 
 summaries={sortedSummaries}
 isSingleVenue={userSessionStore!.currentOrganization!.isSingleVenue()} 
 noContainerMargin
/>
      }
       
      
{posthog.isFeatureEnabled('onboarding') && (
  <div className="mt-2">
    <OnboardingFeaturesGuide defaultCollapsed={shouldCollapseGuide} />
  </div>
)}
        
{hasCurrentVenueMembers &&
        <div className="mt-2" style={{
          display: 'flex',
          justifyContent: 'space-between',
          minHeight: 270,
          height: 270,
          maxHeight: 500,
        }}>
          <MemberGrowthTimeline data={membersTotalCountTimelineTotals} showTooltip={true} />
          <MemberDemography data={memberDemographicsTotals} />
        </div>

      }
     

        <div className="row mt-2">
          {memberStore?.isDataLoading ? (
            <div className="text-center w-100 mt-4">
              <Spinner animation="border" role="status" />
            </div>
          ) : (
            <>
              {hasNewMembers && (
                <div className={`${!hasRecentActivities ? 'col-md-12' : 'col-md-4 pr-2'}`}>
                  <NewMembersList />
                </div>
              )}
              {hasRecentActivities && (
                <div className={`${!hasNewMembers ? 'col-md-12' : 'col-md-8 pl-0'}`}>
                  <RecentActivitiesList />
                </div>
              )}
            </>
          )}
        </div>


      </div>
    );
  })
);

export default withTranslation()(withRouter(HomeScreen));