import React from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IUserSessionStore } from '../../models/UserSessionStore';
import { IVenue } from '../../models/Venue';
import { MembersStatusesModifyLocation } from '../../Locations';
import { STATUS_TYPE } from '../../screens/ManageMemberStatusesScreen';

interface GiveCardsButtonProps {
  userSessionStore?: IUserSessionStore;
  variant?: 'primary' | 'secondary' | 'success';
  className?: string;
  onPremiumRequired: () => void;
}

const GiveCardsButton: React.FC<GiveCardsButtonProps> = inject('userSessionStore')(observer(({ 
  userSessionStore,
  variant = 'success',
  className = '',
  onPremiumRequired
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isOrganizationPremium = userSessionStore?.currentOrganization?.isPremium;

  const handleNavigation = (venue: IVenue) => {
    if (!isOrganizationPremium) {
      onPremiumRequired();
      return;
    }

    history.push(MembersStatusesModifyLocation.toUrl({
      ...venue.urlFields(),
      statusType: STATUS_TYPE.PRIVATE
    }));
  };

  if (userSessionStore?.isOrganizationContext()) {
    return (
      <Dropdown
        onClick={(e: any) => isOrganizationPremium ? e.stopPropagation() : onPremiumRequired()}
      >
        <Dropdown.Toggle variant={variant} id="dropdown-edit-products-org">
          <FontAwesomeIcon className="mr-2" icon={['fal', 'star']} />
          {t('memberScreen.giveCards')}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {userSessionStore.currentOrganization!.venues!.map((venue: IVenue) => (
            <Dropdown.Item 
              key={venue.id}
              onClick={() => handleNavigation(venue)}
            >
              {venue.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  return (
    <Button
      className={className}
      onClick={() => handleNavigation(userSessionStore!.selectedBranch! as IVenue)}
      variant={variant}
    >
      <FontAwesomeIcon className="mr-2" icon={['fal', 'star']} />
      {t('memberScreen.giveCards')}
    </Button>
  );
}));

export default GiveCardsButton; 