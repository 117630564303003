import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { colors } from '../../theme/colors';
import SkipConfirmationModal from './SkipConfirmationModal';
import { useTranslation } from 'react-i18next';

interface OnboardingItemProps {
  title: string;
  text: string;
  isCompleted: boolean;
  onClick: () => void;
  showBottomBorder?: boolean;
  id: string; 
  isSkipped?: boolean;
  onSkip?: (id: string) => void;
}

const OnboardingItem: React.FC<OnboardingItemProps> = ({
  title,
  text,
  isCompleted,
  onClick,
  showBottomBorder = true,
  id,
  isSkipped = false,
  onSkip
}) => {
  const [showSkipModal, setShowSkipModal] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const { t } = useTranslation();

  const handleSkipButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isCompleted && !isSkipped && onSkip) {
      setShowSkipModal(true);
    }
  };

  const handleSkip = () => {
    if (onSkip) {
      onSkip(id);
    }
    setShowSkipModal(false);
  };

  return (
    <>
      <div
        onClick={onClick}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        style={{
          padding: '1rem',
          borderBottom: showBottomBorder ? `1px solid ${colors.stroke}` : 'none',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          minHeight: '64px',
          position: 'relative'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{
              display: 'inline-block',
              width: 40,
            }}>
            {isCompleted ? (
              <FontAwesomeIcon
                icon={['fas', 'check']}
                className="mr-3"
                style={{ color: colors.green, fontSize: 28 }}
              />
            ) : isSkipped ? (
              <FontAwesomeIcon
                icon={['fas', 'forward']}
                className="mr-3"
                style={{ color: colors.secondaryText, fontSize: 24 }}
              />
            ) : (
              <div 
                className="mr-3" 
                style={{ 
                  width: '22px', 
                  height: '22px', 
                  borderRadius: '50%', 
                  border: '2px solid rgba(255, 255, 255, 0.5)'
                }} 
              />
            )}
            </div>
          
            <div>
              <div style={{ fontWeight: 500 }}>{title}</div>
              <div style={{ fontSize: '0.9em', color: colors.secondaryText }}>{text}</div>
            </div>
          </div>
          
          {isHovering && !isCompleted && !isSkipped && onSkip ? (
            <button
              onClick={handleSkipButtonClick}
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                border: 'none',
                borderRadius: '4px',
                padding: '5px 10px',
                color: colors.secondaryText,
                fontSize: '0.9em',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                transition: 'background-color 0.2s',
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.2)';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
              }}
            >
              <FontAwesomeIcon
                icon={['fal', 'forward']}
                style={{ marginRight: '5px' }}
              />
              {t('onboardingFeaturesGuide.skipModal.confirm')}
            </button>
          ) : (
            <FontAwesomeIcon
              icon={['fal', 'chevron-right']}
              style={{ color: 'rgba(255, 255, 255, 0.5)' }}
            />
          )}
        </div>
      </div>
      <SkipConfirmationModal 
        show={showSkipModal}
        onHide={() => setShowSkipModal(false)}
        onConfirm={handleSkip}
        itemTitle={title}
      />
    </>
  );
};

export default OnboardingItem; 