import React from "react";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import { IUserSessionStore } from "../models/UserSessionStore";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ILoyaltyCardStore } from "../models/LoyaltyCardStore";
import { PostHogFeature } from "posthog-js/react";
import "./SideNavbar.css";
import { 
  HomeLocation, 
  MembersLocation, 
  MessagesLocation, 
  BenefitsLocation, 
  EventsLocation, 
  RewardsLocation,
  ProductsLocation
} from "../Locations";
import { IProductReportStore } from "../models/ProductReportStore";
import {
  faHome,
  faUsers,
  faEnvelope,
  faStar,
  faGiftCard,
  faCalendarAlt,
  faShoppingCart
} from "@fortawesome/pro-light-svg-icons";

export interface SideNavbarProps {
  userSessionStore: IUserSessionStore;
  loyaltyCardStore?: ILoyaltyCardStore;
  productReportStore?: IProductReportStore;
}

const SideNavbar: React.FC<SideNavbarProps> = observer(({ 
  userSessionStore, 
  loyaltyCardStore,
  productReportStore
}) => {
  const { t } = useTranslation();

  if (!userSessionStore.isLoggedIn() || !userSessionStore.selectedBranch) {
    return null;
  }

  const rewardsSummaries = loyaltyCardStore?.getRewardsSummaries();
  const isAllSummariesZeroValue = (summaries: any) => {
    if (!summaries) return true;
    return Object.values(summaries).every(value => value === 0);
  };

  const urlFields = userSessionStore.selectedBranch.urlFields();
  
  return (
    <div className="side-navbar">
      <Nav className="flex-column">
        <Nav.Link 
          as={NavLink} 
          to={HomeLocation.toUrl(urlFields)}
          className="side-nav-link"
        >
          <FontAwesomeIcon icon={faHome} className="side-nav-icon" />
          <span className="side-nav-text">{t("home")}</span>
        </Nav.Link>
        
        <Nav.Link 
          as={NavLink} 
          to={MembersLocation.toUrl(urlFields)}
          className="side-nav-link"
        >
          <FontAwesomeIcon icon={faUsers} className="side-nav-icon" />
          <span className="side-nav-text">{t("members")}</span>
        </Nav.Link>
        
        <Nav.Link 
          as={NavLink} 
          to={MessagesLocation.toUrl(urlFields)}
          className="side-nav-link"
        >
          <FontAwesomeIcon icon={faEnvelope} className="side-nav-icon" />
          <span className="side-nav-text">{t("messages")}</span>
        </Nav.Link>
        
        <Nav.Link 
          as={NavLink} 
          to={BenefitsLocation.toUrl(urlFields)}
          className="side-nav-link"
        >
          <FontAwesomeIcon icon={faStar} className="side-nav-icon" />
          <span className="side-nav-text">{t("benefits")}</span>
        </Nav.Link>

        <PostHogFeature 
          flag="partnerhub-rewards-editing" 
          match={true}
          fallback={
            !isAllSummariesZeroValue(rewardsSummaries) && (
              <Nav.Link 
                as={NavLink} 
                to={RewardsLocation.toUrl(urlFields)}
                className="side-nav-link"
              >
                <FontAwesomeIcon icon={faGiftCard} className="side-nav-icon" />
                <span className="side-nav-text">{t("rewards")}</span>
              </Nav.Link>
            )
          }
        >
          <Nav.Link 
            as={NavLink} 
            to={RewardsLocation.toUrl(urlFields)}
            className="side-nav-link"
          >
            <FontAwesomeIcon icon={faGiftCard} className="side-nav-icon" />
            <span className="side-nav-text">{t("rewards")}</span>
          </Nav.Link>
        </PostHogFeature>
        
        <Nav.Link 
          as={NavLink} 
          to={EventsLocation.toUrl(urlFields)}
          className="side-nav-link"
        >
          <FontAwesomeIcon icon={faCalendarAlt} className="side-nav-icon" />
          <span className="side-nav-text">{t("events")}</span>
        </Nav.Link>

        {Boolean(productReportStore?.reporting?.soldTotal) && (
          <Nav.Link 
            as={NavLink} 
            to={ProductsLocation.toUrl(urlFields)}
            className="side-nav-link"
          >
            <FontAwesomeIcon icon={faShoppingCart} className="side-nav-icon" />
            <span className="side-nav-text">{t("products")}</span>
          </Nav.Link>
        )}
      </Nav>
    </div>
  );
});

export default SideNavbar; 