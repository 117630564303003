import { observer } from "mobx-react"
import posthog from "posthog-js"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { SummaryHeader, SummaryItem } from "../../components/SummaryHeader"
import UpgradeToPremiumModal from "../../components/UpgradeToPremiumModal"
import GiveCardsButton from "../../components/buttons/GiveCardsButton"
import SellCardsButton from "../../components/buttons/SellCardsButton"
import SellSubscriptionsButton from "../../components/buttons/SellSubscriptionsButton"
import { useStores } from "../../hooks/UseStores"
import { sortSummariesByValueDescending } from "../HomeScreen/utils"




const MembersScreenSummaryHeaders = observer(() => {
  const { userSessionStore, memberStore} = useStores()

  const { t } = useTranslation()
  const history = useHistory()
  const [isPremiumModalShown, setIsPremiumModalShown] = useState(false)



  const venueStatusesStats  = memberStore!.stats?.venueStatuses?.slice() || []


  const venueStatusSummaries: Array<SummaryItem> = venueStatusesStats?.map((venueStatusStat: any) => {
    return {
      venue: userSessionStore!.currentOrganization!.isSingleVenue() ? undefined : venueStatusStat.id?.restaurantId?.name,
      title: venueStatusStat.id.localeTitle,
      value: venueStatusStat.membersCount?.toString() || '0',
      footer: t("memberScreen.summary.statusMembersFooter"),
    }
  }) || []

  const summaries: Array<SummaryItem> = [
    {
      title: t("memberScreen.summary.totalMembersTitle"),
      value: memberStore?.stats?.membersCount?.toString() || '0',
      footer: t("memberScreen.summary.totalMembersFooter"),
    },
    {
      title: t("memberScreen.summary.newMembersTitle"),
      value: memberStore?.stats?.membersLastMonth?.toString() || '0',
      footer: t("memberScreen.summary.newMembersFooter"),
    },
    ...sortSummariesByValueDescending(venueStatusSummaries)
  ]

  const isOrganizationPremium = userSessionStore?.currentOrganization?.isPremium

  const hasSalesEnabled = !!userSessionStore.countryConfig.salesEnabled;
  const isSalesConstraintFFEnabled = posthog.isFeatureEnabled('sales-enabled-constraint-ff')

  const showSalesButtons = !isSalesConstraintFFEnabled || hasSalesEnabled;

  const actionButtons = (
    <>
  <UpgradeToPremiumModal 
showModal={isPremiumModalShown} 
setShowModal={setIsPremiumModalShown} 
/>
      {showSalesButtons &&
      <>
<SellSubscriptionsButton />
<SellCardsButton />
</>
        }
<GiveCardsButton onPremiumRequired={() => setIsPremiumModalShown(true)} />
    </>
  )



  return   <SummaryHeader summaries={summaries} isSingleVenue={userSessionStore!.currentOrganization!.isSingleVenue()} buttons={actionButtons}/>
})


export default MembersScreenSummaryHeaders
