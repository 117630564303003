import {Redirect, Route, RouteProps} from "react-router"
import {IUserSessionStore} from "../models/UserSessionStore"
import {inject} from "mobx-react"
import React from "react"
import Container from "react-bootstrap/Container"
import { HomeLocation } from "../Locations";

interface AppRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
  userSessionStore?: IUserSessionStore
  enabled?: boolean
  authenticationRequired: boolean
  salesRequired?: boolean
  loggedInRedirect?: string
  loggedOutRedirect?: string
  navBar?: any
  forceRemount?: boolean
}

export const AppRoute = inject("userSessionStore")((props: AppRouteProps) => {
  // @ts-ignore
  const {
      component: Component,
      userSessionStore,
      enabled=true,
      authenticationRequired,
      salesRequired,
      loggedInRedirect,
      loggedOutRedirect,
      navBar,
      forceRemount,
      ...rest
  } = props

  const hasSalesEnabled = !!props.userSessionStore?.countryConfig?.salesEnabled

  const remountProps = forceRemount ? { key: Date.now() } : {}
  return (
    <Route
      {...rest}
      // FIXME: refactor :)
      render={(routeProps) => {
        if (enabled) {
          if (!authenticationRequired) {
            // no authentication required but logged in & redirect set
            if (userSessionStore!.isLoggedIn() && loggedInRedirect) {
              return (<Redirect
                  to={{
                    pathname: loggedInRedirect,
                    state: {from: routeProps.location},
                  }}/>
              )
            } else {
              return (<>
                {navBar && navBar()}
                  <Container className="mt-4" {...remountProps}>
                      <Component {...routeProps} />
                  </Container>
              </>)
            }
          } else {

            const id = routeProps.match.params.id
            const statusType = routeProps.match.params.statusType

            // auth required & logged in
            if (userSessionStore!.isLoggedIn()) {
              if(salesRequired && (!hasSalesEnabled && (id === "PURCHASE" || statusType === "PURCHASE"))) {
                 return (
                     <Redirect to={HomeLocation.toUrl()} />
                 )
              }

              return (<>
                {navBar && navBar()}
                <Container className="mt-4" {...remountProps}>
                  <Component {...routeProps} />
                </Container>
              </>)
            } else {
              // logged out redirect set
              if (loggedOutRedirect) {
                return (<Redirect
                    to={{
                      pathname: loggedOutRedirect,
                      state: {from: routeProps.location},
                    }}/>
                )
              } else {
                return (<Redirect
                    to={{
                      pathname: "/login",
                      state: {from: routeProps.location},
                    }}/>
                )
              }
            }
          }

        } else {
          return null
        }
      }}
    />
  )
})
