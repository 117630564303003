import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, FieldProps, Form, Formik, FormikProps, useField } from "formik";
import { inject, observer } from "mobx-react";
import { useFeatureFlagEnabled } from 'posthog-js/react';
import React, { SetStateAction, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container, Form as BootstrapForm,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Modal,
  Row
} from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { MerchantAdminApi } from "../../Api";
import AlertMessage from "../../components/AlertMessage";
import ImageUploadModal from "../../components/ImageUploadModal";
import { ManageVenuesLocation } from "../../Locations";
import { ServerVenue, ServerVenueLocation, ServerVenueSchema } from "../../models/server/ServerVenue";
import { mobxVenueToServerVenue } from "../../models/server/utils";
import { IUserSessionStore } from "../../models/UserSessionStore";
import { urlToFile } from "../../utils";
import CategoryField from "./CategoryField";
import "./CreateUpdateVenueScreen.scss";
import MultiLocationModalField from "./MultiLocationModalField";
import SingleLocationField from "./SingleLocationField";
import { populateCategories, removeEmptyStringKeys } from "./utils";


const ADD_VENUE_ERROR = "addVenueError";
const CHECK_VENUE_HANDLE_ERROR = "checkVenueHandleError";

const FA_ICONS = {
  'www': <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={{ display: "inline-block" }} viewBox="0 0 512 512" fill="currentColor"><path d="M352 256c0 22.2-1.2 43.6-3.3 64l-185.3 0c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64l185.3 0c2.2 20.4 3.3 41.8 3.3 64zm28.8-64l123.1 0c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64l-123.1 0c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32l-116.7 0c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0l-176.6 0c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0L18.6 160C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192l123.1 0c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64L8.1 320C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6l176.6 0c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352l116.7 0zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6l116.7 0z" /></svg>,
  'email': <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={{ display: "inline-block" }} viewBox="0 0 512 512" fill="currentColor"><path d="M64 112c-8.8 0-16 7.2-16 16l0 22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1l0-22.1c0-8.8-7.2-16-16-16L64 112zM48 212.2L48 384c0 8.8 7.2 16 16 16l384 0c8.8 0 16-7.2 16-16l0-171.8L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z" /></svg>,
  'phone': <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={{ display: "inline-block" }} viewBox="0 0 512 512" fill="currentColor"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>,
  'facebook': <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={{ display: "inline-block" }} viewBox="0 0 512 512" fill="currentColor"><path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" /></svg>,
  'messenger': <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={{ display: "inline-block" }} viewBox="0 0 512 512" fill="currentColor"><path d="M256.6 8C116.5 8 8 110.3 8 248.6c0 72.3 29.7 134.8 78.1 177.9 8.4 7.5 6.6 11.9 8.1 58.2A19.9 19.9 0 0 0 122 502.3c52.9-23.3 53.6-25.1 62.6-22.7C337.9 521.8 504 423.7 504 248.6 504 110.3 396.6 8 256.6 8zm149.2 185.1l-73 115.6a37.4 37.4 0 0 1 -53.9 9.9l-58.1-43.5a15 15 0 0 0 -18 0l-78.4 59.4c-10.5 7.9-24.2-4.6-17.1-15.7l73-115.6a37.4 37.4 0 0 1 53.9-9.9l58.1 43.5a15 15 0 0 0 18 0l78.4-59.4c10.4-8 24.1 4.5 17.1 15.6z" /></svg>,
  'whatsapp': <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={{ display: "inline-block" }} viewBox="0 0 448 512" fill="currentColor"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" /></svg>,
  'instagram': <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={{ display: "inline-block" }} viewBox="0 0 448 512" fill="currentColor"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
} as const;

export interface AddVenueScreenProps extends WithTranslation {
  api?: MerchantAdminApi;
  userSessionStore?: IUserSessionStore;
  history?: any;
}

const IMAGE_UPLOAD_STATUS = {
  UPLOADING: "UPLOADING",
  ERROR: "ERROR",
};

declare module "react-bootstrap" {
  interface CardProps {
    children?: React.ReactNode;
  }
}

const getVenueIDFromURL = () => {
  const urlVars = window.location.pathname.split("/");
  if (urlVars.length >= 5 && urlVars[3] === "venue") {
    return urlVars[4];
  } else {
    return null;
  }
};

const flattenErrorObject = (obj: any, parentKey = "", result: Record<string, any> = {}): Record<string, any> => {
  for (const [key, value] of Object.entries(obj)) {
    const newKey = parentKey ? `${parentKey}.${key}` : key;

    if (Array.isArray(value)) {
      value.forEach((item, index) => flattenErrorObject(item, `${newKey}`, result)); // drop the index
    } else if (typeof value === "object" && value !== null) {
      flattenErrorObject(value, newKey, result);
    } else {
      result[newKey] = value;
    }
  }

  return result;
};

const CreateUpdateVenueScreen = (props: AddVenueScreenProps) => {
  const { t, userSessionStore, api, history } = props;

  const isMultilocationEnabled = useFeatureFlagEnabled('multilocation')

  const [croppedLogo, setCroppedLogo] = useState("");
  const [croppedCover, setCroppedCover] = useState("");
  const [showHideVenueModal, setShowHideVenueModal] = useState(false);
  const [showGoBackModal, setShowGoBackModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showUploadLogoPhotoModal, setShowUploadLogoPhotoModal] = useState(false);
  const [showUploadCoverPhotoModal, setShowUploadCoverPhotoModal] = useState(false);
  const [isClubyHandleValid, setIsClubyHandleValid] = useState(true);

  const venueId = getVenueIDFromURL();
  const selectedVenue = userSessionStore?.currentOrganization?.venues.find((venue) => venue.id === venueId);
  const [uploadedLogoImage, setUploadedLogoImage] = useState(selectedVenue?.images?.logoImage ?? undefined);
  const [uploadedCoverImage, setUploadedCoverImage] = useState(selectedVenue?.images?.mainImage ?? undefined);

  const emptyVenue = { id: "NEW__", locations: [] as ServerVenueLocation[] } as Partial<ServerVenue>
  const initValues = selectedVenue ? mobxVenueToServerVenue(selectedVenue, false) : emptyVenue as Partial<ServerVenue>;

  const checkVenueHandle = async (clubyHandle: string) => {
    const CLUBY_HANDLE_REGEX = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
    if (clubyHandle.length < 1 || !CLUBY_HANDLE_REGEX.test(clubyHandle)) {
      setIsClubyHandleValid(false);
      return;
    }

    const handleValidity = await props.api!.checkVenueHandle(clubyHandle, undefined, CHECK_VENUE_HANDLE_ERROR);

    if (handleValidity === "FREE" || clubyHandle === selectedVenue?.handle) {
      setIsClubyHandleValid(true);
    } else {
      setIsClubyHandleValid(false);
    }
  };


  const ClubyHandleApproval = () => {
    const [field] = useField<ServerVenue['handle']>("handle")
    if ((field.value?.length ?? 0) === 0) {
      return null;
    }

    if (isClubyHandleValid) {
      return <FontAwesomeIcon className="ml-2 text-success" icon={["fas", "check"]} />;
    } else {
      return <small className="text-danger d-block">{t("manageVenuesScreen.tryDifferentHandle")}</small>;
    }
  };

  const onBackToVenues = (isFormEdited: boolean) => {
    if (isFormEdited) setShowGoBackModal(true);
    else history.push(ManageVenuesLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()));
  };

  const handleUploadLogoImage = (imageBlob: any) => handleUploadImage(imageBlob, "venue/logo", setUploadedLogoImage);
  const handleUploadCoverImage = (imageBlob: any) => handleUploadImage(imageBlob, "venue/main", setUploadedCoverImage);
  const formikRef = useRef<FormikProps<Partial<ServerVenue>>>(null);

  const handleUploadImage = async (
    imageBlob: any,
    imageType: "venue/logo" | "venue/main",
    setUploadedImage: (value: SetStateAction<string | undefined>) => void
  ) => {
    setUploadedImage(IMAGE_UPLOAD_STATUS.UPLOADING);
    const imageFile = await urlToFile(imageBlob, "image.jpeg", "image/jpeg");
    const imageURL = await api!.uploadImage(imageType, imageFile);
    if (imageURL) {
      setUploadedImage(imageURL);
      switch (imageType) {
        case "venue/logo":
          formikRef.current?.setFieldValue("images.logoImage", imageURL)
          break;
        case "venue/main":
          formikRef.current?.setFieldValue("images.mainImage", imageURL)
          break;
      }
    } else {
      setUploadedImage(IMAGE_UPLOAD_STATUS.ERROR);
    }
  };

  const handleSaveAndPublish = async (venue: ServerVenue) => {
    const response = await api!.postVenues([venue], undefined, ADD_VENUE_ERROR);
    if (!response || response.venues.length === 0) {
      return;
    }

    const isNewVenue = venue.id === "NEW__";
    if (isNewVenue) {
      userSessionStore?.currentOrganization?.addVenue(response.venues[0]);
    } else {
      userSessionStore?.currentOrganization?.updateVenue(response.venues[0]);
    }
    history.push(ManageVenuesLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()));
  };

  const LogoImage = () => {
    if (uploadedLogoImage === "") {
      return <Card className="croppedLogo" />;
    } else if (uploadedLogoImage === IMAGE_UPLOAD_STATUS.UPLOADING) {
      return <Skeleton circle={true} height={200} width={200} />;
    } else if (uploadedLogoImage === IMAGE_UPLOAD_STATUS.ERROR) {
      return (
        <Card className="croppedLogo">
          <div className="alert alert-danger mt-5" role="alert">
            {t("manageVenuesScreen.imageUploadFailed")}
          </div>
        </Card>
      );
    } else {
      return <img className="croppedLogo" src={uploadedLogoImage} alt="logo" />;
    }
  };

  const CoverImage = () => {
    if (uploadedCoverImage === "") {
      return <Card className="croppedCover" />;
    } else if (uploadedCoverImage === IMAGE_UPLOAD_STATUS.UPLOADING) {
      return <Skeleton height={198} width={330} />;
    } else if (uploadedCoverImage === IMAGE_UPLOAD_STATUS.ERROR) {
      return (
        <Card className="croppedCover">
          <div className="alert alert-danger mt-5" role="alert">
            {t("manageVenuesScreen.imageUploadFailed")}
          </div>
        </Card>
      );
    } else {
      return <img className="croppedCover" src={uploadedCoverImage} alt="logo" />;
    }
  };

  const hasVenues = Boolean(userSessionStore?.currentOrganization?.venues?.length);

  return (
    <>
      <AlertMessage source={ADD_VENUE_ERROR} />

      <Modal
        show={showGoBackModal}
        centered
        onHide={() => {
          setShowGoBackModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("manageVenuesScreen.goBackPopup.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("manageVenuesScreen.goBackPopup.description")}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={() => setShowGoBackModal(false)}
          >
            {t("manageVenuesScreen.goBackPopup.cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              history.push(ManageVenuesLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()));
            }}
          >
            {t("manageVenuesScreen.goBackPopup.confirm")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Formik
        innerRef={formikRef}
        initialValues={initValues}
        validationSchema={ServerVenueSchema}
        onSubmit={(values) => {
          const categories = populateCategories({ "default": values.categories?.default ?? "" })
          const locations = values.locations?.map(removeEmptyStringKeys);
          const finalVenue = { ...values, categories, locations } as ServerVenue

          handleSaveAndPublish(finalVenue);
        }}
      >
        {({ dirty, values, errors, handleSubmit, resetForm, setFieldValue }) => {
          return (
            <Form>
              <Modal
                show={showRevertModal}
                centered
                onHide={() => {
                  setShowRevertModal(false);
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title>{t("manageVenuesScreen.revertChangesPopup.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("manageVenuesScreen.revertChangesPopup.description")}</Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      setShowRevertModal(false);
                    }}
                  >
                    {t("manageVenuesScreen.revertChangesPopup.cancel")}
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      resetForm();
                      setUploadedLogoImage(selectedVenue?.images?.logoImage || "");
                      setUploadedCoverImage(selectedVenue?.images?.mainImage || "");
                      setShowRevertModal(false);
                    }}
                  >
                    {t("manageVenuesScreen.revertChangesPopup.confirm")}
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                show={showSaveModal}
                centered
                onHide={() => {
                  setShowSaveModal(false);
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title>{t("manageVenuesScreen.saveChangesPopup.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("manageVenuesScreen.saveChangesPopup.description")}</Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      setShowSaveModal(false);
                    }}
                  >
                    {t("manageVenuesScreen.saveChangesPopup.cancel")}
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      handleSubmit();
                      setShowSaveModal(false);
                    }}
                  >
                    {t("manageVenuesScreen.saveChangesPopup.confirm")}
                  </Button>
                </Modal.Footer>
              </Modal>

              <Row>
                <Col xs={12} md={4}>
                  {hasVenues &&
                    <Button variant="secondary" className="font-weight-bold" onClick={() => onBackToVenues(dirty)}>
                      <FontAwesomeIcon className="mr-2" icon={["fas", "reply-all"]} />
                      {t("manageVenuesScreen.backToVenues")}
                    </Button>
                  }

                </Col>
                <Col xs={12} md={8}>
                  <div className="float-right">
                    <Button
                      variant="outline-primary font-weight-bold"
                      className="mr-3"
                      onClick={() => setShowRevertModal(true)}
                    >
                      <FontAwesomeIcon className="mr-2" icon={["fas", "trash-alt"]} />
                      {t("manageVenuesScreen.revert")}
                    </Button>
                    <Button variant="outline-success" className="font-weight-bold" onClick={() => {
                      if (errors && Object.keys(errors).length > 0) {
                        setShowErrorModal(true)
                      } else {
                        setShowSaveModal(true)
                      }
                    }}>
                      <FontAwesomeIcon className="mr-2" icon={["fal", "cloud-arrow-up"]} />
                      {t("manageVenuesScreen.saveAndPublish")}
                    </Button>
                  </div>
                </Col>
              </Row>

              <h4 className={`text-primary text-center mt-4 ${values.isHidden ? "text-muted text-italics" : ""}`}>
                {values.isHidden ? <span><FontAwesomeIcon className="ml-2" icon={["fal", "eye-slash"]} /> </span> : null}
                {values.name}
              </h4>

              <Container className="formContainer">
                <h6>{t(`manageVenuesScreen.basicInformation`)}</h6>

                <Row>
                  <Col xs={12} md={6}>
                    <Field name="name">
                      {({ field, meta }: FieldProps<ServerVenue['name']>) =>
                        <FormGroup controlId="name">
                          <FormLabel className={`textInputLabel ${meta.error && meta.touched ? "text-danger" : ""}`}>
                            {t(`manageVenuesScreen.name`)}*
                          </FormLabel>
                          <FormControl
                            className="textInput"
                            type={"text"}
                            isInvalid={!!(meta.error && meta.touched)}
                            {...field}
                            value={field.value ?? ""}
                          />
                          {meta.error && meta.touched ? <small className="text-danger d-block">{t(`manageVenuesScreen.errors.${field.name}`)}</small> : null}
                        </FormGroup>
                      }
                    </Field>


                    <Field name="description.default">
                      {({ field, meta }: FieldProps<ServerVenue['description']['default']>) => (
                        <FormGroup controlId="description">
                          <FormLabel
                            className={`textInputLabel ${meta.error && meta.touched ? "text-danger" : ""}`}
                          >
                            {t(`manageVenuesScreen.description`)}
                          </FormLabel>
                          <FormControl
                            className="textInput"
                            as="textarea"
                            rows={4}
                            isInvalid={!!(meta.error && meta.touched)}
                            {...field}
                            value={field.value ?? ""}
                          />
                          {meta.error && meta.touched ? <small className="text-danger d-block">{t(`manageVenuesScreen.errors.${field.name}`)}</small> : null}
                        </FormGroup>
                      )}
                    </Field>
                  </Col>

                  <Col xs={12} md={6}>
                    <Field name="handle">
                      {({ field, form, meta }: FieldProps<ServerVenue['handle']>) => (
                        <>
                          <FormGroup controlId="handle" className="form-horizontal clubyHandleInput ml-1">
                            <FormLabel
                              className={`textInputLabel ${meta.error && meta.touched ? "text-danger" : ""}`}
                            >
                              {t(`manageVenuesScreen.handle`)}*
                            </FormLabel>

                            <InputGroup className="textInput">
                              <InputGroup.Prepend>
                                <InputGroup.Text className="pr-1">cluby.com/</InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                htmlSize={40}
                                className=""
                                type={"text"}
                                isInvalid={!isClubyHandleValid || !!(meta.error && meta.touched)}
                                {...field}
                                value={field.value ?? ""}
                                onChange={(e) => {
                                  const lowerHandle = e.target.value.toLowerCase()
                                  form.setFieldValue("handle", lowerHandle);
                                  checkVenueHandle(lowerHandle);
                                }}
                              />
                              <ClubyHandleApproval />
                            </InputGroup>

                          </FormGroup>
                          {meta.error && meta.touched ? <small className="text-danger d-block">{t(`manageVenuesScreen.errors.${field.name}`)}</small> : null}
                        </>)}
                    </Field>

                    <CategoryField {...props} />

                    <Field name="isHidden">
                      {({ field, meta }: FieldProps<boolean>) => (
                        <FormGroup controlId="isHidden">
                          <FormLabel
                            className={`d-block pt-2 mb-0 textInputLabel ${meta.error && meta.touched ? "text-danger" : ""}`}
                          >
                            {t("manageVenuesScreen.hideVenue")}
                          </FormLabel>
                          <BootstrapForm.Check
                            type="switch"
                            id="isHidden"
                            checked={field.value}
                            onChange={() => {
                              if (field.value) {
                                setFieldValue("isHidden", false);
                              } else {
                                setShowHideVenueModal(true);
                              }
                            }}
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                </Row>

                <h6>{t(`manageVenuesScreen.locationInfo`)}</h6>
                <Row>
                  <Col sm={12} md={6}>
                    {isMultilocationEnabled || ((values.locations ?? []).length > 1) ? <MultiLocationModalField {...props} /> : <SingleLocationField {...props} />}
                  </Col>
                </Row>


                <h6>{t(`manageVenuesScreen.mediaSectionTitle`)}</h6>
                <Row>
                  <Col sm={12} md={6}>
                    <Field name="images.logoImage">
                      {({ field, meta }: FieldProps<ServerVenue['images']['logoImage']>) => (
                        <FormGroup controlId="logoImage">
                          <FormLabel
                            className={`d-block textInputLabel ${meta.error && meta.touched ? "text-danger" : ""}`}
                          >
                            {t(`manageVenuesScreen.logo`)}
                          </FormLabel>
                          <label className="btn btn-secondary">
                            {t(`manageVenuesScreen.uploadLogo`)}
                            <input
                              id="logoImage"
                              name="file"
                              type="file"
                              accept="image/*"
                              hidden
                              onBlur={field.onBlur}
                              onChange={(event) => {
                                if (event.currentTarget?.files?.[0]?.type.includes("image")) {
                                  setFieldValue("images.logoImage", URL.createObjectURL(event.currentTarget.files[0]));
                                  setShowUploadLogoPhotoModal(true);
                                }
                              }}
                            />
                          </label>
                        </FormGroup>
                      )}
                    </Field>

                    <LogoImage />
                  </Col>


                  <Col xs={12} md={6}>
                    <Field name="images.mainImage">
                      {({ field, meta }: FieldProps<ServerVenue['images']['mainImage']>) => (
                        <FormGroup controlId="mainImage">
                          <FormLabel
                            className={`d-block textInputLabel mt-3 mb-0 ${meta.error && meta.touched ? "text-danger" : ""
                              }`}
                          >
                            {t(`manageVenuesScreen.coverPhoto`)}
                          </FormLabel>

                          <label className="btn btn-secondary">
                            {t(`manageVenuesScreen.uploadMainImage`)}
                            <input
                              type="file"
                              id="coverURL"
                              hidden
                              name="file"
                              accept="image/*"
                              onBlur={field.onBlur}
                              onChange={(event) => {
                                if (event.currentTarget?.files?.[0]?.type.includes("image")) {
                                  setFieldValue("images.mainImage", URL.createObjectURL(event.currentTarget.files[0]));
                                  setShowUploadCoverPhotoModal(true);
                                }
                              }}
                            />
                          </label>
                        </FormGroup>
                      )}
                    </Field>
                    <div className="mb-2">
                      <small>{t("manageVenuesScreen.coverDescription")}</small>
                    </div>

                    <CoverImage />
                  </Col>
                </Row>

                <ImageUploadModal
                  show={showUploadLogoPhotoModal}
                  imageURL={values.images?.logoImage ?? ""}
                  aspectRatio={1}
                  cropShape="round"
                  onCroppedImage={(croppedImage: SetStateAction<string>) => {
                    setCroppedLogo(croppedImage);
                  }}
                  onCancel={() => {
                    setShowUploadLogoPhotoModal(false);
                  }}
                  onSave={() => {
                    setShowUploadLogoPhotoModal(false);
                    handleUploadLogoImage(croppedLogo);
                  }}
                />


                <ImageUploadModal
                  show={showUploadCoverPhotoModal}
                  imageURL={values.images?.mainImage ?? ""}
                  aspectRatio={1500 / 900}
                  cropShape="rect"
                  onCroppedImage={(croppedImage: SetStateAction<string>) => {
                    setCroppedCover(croppedImage);
                  }}
                  onCancel={() => {
                    setShowUploadCoverPhotoModal(false);
                  }}
                  onSave={() => {
                    setShowUploadCoverPhotoModal(false);
                    handleUploadCoverImage(croppedCover);
                  }}
                />


                <h6>{t(`manageVenuesScreen.businessSectionTitle`)}</h6>
                <Row>
                  <Col sm={12} md={6}>

                    <Field name="businessId">
                      {({ field, form, meta }: FieldProps<ServerVenue['businessId']>) => (
                        <FormGroup controlId="businessId">
                          <FormLabel
                            className={`textInputLabel ${meta.error && meta.touched ? "text-danger" : ""}`}
                          >
                            {t(`manageVenuesScreen.businessID`)}
                          </FormLabel>
                          <FormControl
                            className="textInput"
                            type={"text"}
                            isInvalid={!!(meta.error && meta.touched)}
                            {...field}
                            value={field.value ?? ""}
                          />
                          {meta.error && meta.touched ? <small className="text-danger d-block">{t(`manageVenuesScreen.errors.${field.name}`)}</small> : null}
                        </FormGroup>
                      )}
                    </Field>
                  </Col>

                  <Col sm={12} md={6}>
                    <Field name="_bankAccount" >
                      {({ field, meta }: FieldProps<ServerVenue['_bankAccount']>) => (
                        <FormGroup controlId="_bankAccount">
                          <FormLabel className={`textInputLabel ${meta.error && meta.touched ? "text-danger" : ""}`}>
                            {t(`manageVenuesScreen.iban`)}
                          </FormLabel>
                          <FormControl
                            className="textInput"
                            type={"text"}
                            isInvalid={!!(meta.error && meta.touched)}
                            {...field}
                            value={field.value ?? ""}
                          />
                          {meta.error && meta.touched ? <small className="text-danger d-block">{t(`manageVenuesScreen.errors.${field.name}`)}</small> : null}
                        </FormGroup>
                      )}</Field>
                  </Col>
                </Row>

                <h6>{t(`manageVenuesScreen.additionalInfo`)}</h6>
                <Row>
                  {
                    (['www', 'email', 'phone', 'facebook', 'messenger', 'whatsapp', 'instagram'] as const).map((contactType) => (
                      <Col sm={12} md={6} key={contactType}>
                        <Field name={`contactInformation.${contactType}`}>
                          {({ field, meta }: FieldProps<string>) => (
                            <>
                              <FormLabel className={`d-block textInputLabel ${meta.error && meta.touched ? "text-danger" : ""}`}>
                                {t(`manageVenuesScreen.contactInformation.${contactType}`)}
                              </FormLabel>
                              <FormGroup controlId={contactType}>
                                <InputGroup className="textInput">
                                  <InputGroup.Prepend>
                                    <InputGroup.Text className="pr-1 flex align-items-center justify-content-center">
                                      {FA_ICONS[contactType]}
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>

                                  <FormControl
                                    className="textInput"
                                    type={"text"}
                                    isInvalid={!!(meta.error && meta.touched)}
                                    {...field}
                                    value={field.value ?? ""}
                                  />
                                </InputGroup>
                                {meta.error && meta.touched ? <small className="text-danger d-block">{t(`manageVenuesScreen.errors.${field.name}`)}</small> : null}
                              </FormGroup>
                            </>
                          )}
                        </Field>
                      </Col>
                    ))
                  }

                  <Col sm={12} md={6}>
                    <Field name={`enabledFeatures.tableBookingUri`}>
                      {({ field, meta }: FieldProps<string>) => (
                        <FormGroup controlId="tableBookingUri">
                          <FormLabel className={`textInputLabel ${meta.error && meta.touched ? "text-danger" : ""}`}>
                            {t(`manageVenuesScreen.bookingURL`)}
                          </FormLabel>
                          <FormControl
                            className="textInput"
                            type={"text"}
                            isInvalid={!!(meta.error && meta.touched)}
                            {...field}
                            value={field.value ?? ""}
                          />
                          {meta.error && meta.touched ? <small className="text-danger d-block">{t(`manageVenuesScreen.errors.${field.name}`)}</small> : null}
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                </Row>

                <Modal
                  show={showHideVenueModal}
                  centered
                  onHide={() => {
                    setShowHideVenueModal(false);
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{t("manageVenuesScreen.doYouWantToHideVenue")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{t("manageVenuesScreen.hideVenueDescription")}</Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        setShowHideVenueModal(false);
                        setFieldValue("isHidden", false);
                      }}
                    >
                      {t("manageVenuesScreen.cancel")}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setShowHideVenueModal(false);
                        setFieldValue("isHidden", true);
                      }}
                    >
                      {t("manageVenuesScreen.hide")}
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showErrorModal}
                  centered
                  onHide={() => {
                    setShowErrorModal(false);
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{t("manageVenuesScreen.unableToSaveTitle")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>{t("manageVenuesScreen.unableToSaveDescription")}</div>
                    <div><ul>
                      {Object.keys(flattenErrorObject(errors)).map((key) => (<li key={key}>{t(`manageVenuesScreen.errors.${key}`)}</li>))}</ul></div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setShowErrorModal(false);
                      }}
                    >
                      {t("manageVenuesScreen.hide")}
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Row>
                  <Col sm={12} md={6}>
                    <Button variant="outline-success" onClick={() => {
                      if (errors && Object.keys(errors).length > 0) {
                        setShowErrorModal(true)
                      } else {
                        setShowSaveModal(true)
                      }
                    }} className="font-weight-bold">
                      <FontAwesomeIcon className="mr-2" icon={["fal", "cloud-arrow-up"]} />
                      {t("manageVenuesScreen.saveAndPublish")}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          )
        }
        }
      </Formik>
    </>
  );
};

export default withTranslation()(inject("userSessionStore", "api")(observer(CreateUpdateVenueScreen)));
