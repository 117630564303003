import { useField } from "formik";
import {
  FormLabel
} from "react-bootstrap";
import { WithTranslation } from "react-i18next";
import Select from "react-select";
import i18n from "../../i18n";
import { colors } from "../../theme/colors";
import { categories } from "./categories";
import "./CreateUpdateVenueScreen.scss";
import { CategoryKey, LanguageKey } from "./utils";


const CategoryField = (props: WithTranslation) => {
  const { t } = props;
  const [field, meta, helper] = useField<string>({
    name: "categories.default",
    validate: (value: any) => {
      if ((value?.length ?? 0) === 0) {
        return t('manageVenuesScreen.errors.categories.default') as string
      }
    },
  })

  const allOptions = Object.keys(categories).map(categoryName => {
    const currentLanguage = i18n.languages[0];
    const key = categoryName as CategoryKey
    const locale = currentLanguage as LanguageKey
    return {
      value: key,
      label: categories[key][locale],
    }
  });

  const selectedCategories = field.value?.split(", ").map(a => a.trim()) ?? []
  const selectedValues = allOptions.filter(o => selectedCategories.includes(o.value))

  return (
    <>
      <FormLabel className="textInputLabel">{t(`manageVenuesScreen.categories`)}</FormLabel>
      <Select
        placeholder={t("manageVenuesScreen.selectCategories")}
        onChange={(selectedOptions: typeof allOptions) => {
          const newCategories = (selectedOptions ?? []).slice(0, 5).map(option => option.value.trim()).join(", ")
          helper.setValue(newCategories)
        }}
        isMulti
        options={allOptions}
        onBlur={() => helper.setTouched(true)}
        value={selectedValues}
        closeMenuOnSelect={false}
        className="mb-2 category-input"
        styles={{
          control: (provided: any) => ({
            ...provided,
            backgroundColor: "transparent",
          }),
          menu: (provided: any) => ({
            ...provided,
            backgroundColor: colors.background,
          }),
          multiValue: (provided: any) => ({
            ...provided,
            backgroundColor: colors.primaryText,
          }),
          option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isFocused ? colors.primaryText : colors.background,
            color: state.isFocused ? colors.black : colors.primaryText,
          }),
          multiValueRemove: (provided: any) => ({
            ...provided,
            color: colors.background,
            ":hover": {
              color: "hoverColorForXIcon",
              backgroundColor: "hoverBackgroundColorForXIcon",
            },
          }),
        }}
      />
      {meta.error && meta.touched ? (
        <small className="text-danger d-block">{meta.error}</small>
      ) : <small className="text-secondary">{t(`manageVenuesScreen.categoriesDescription`)}</small>}
    </>
  )
}

export default CategoryField
