import React from "react"
import "./App.css"
import {Link, match, NavLink, Redirect, RouteComponentProps, Switch, withRouter} from "react-router-dom"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import LoginScreen from "./screens/Login"
import {IUserSessionStore} from "./models/UserSessionStore"
import {inject, observer} from "mobx-react"
import {MerchantAdminApi} from "./Api"
import NavDropdown from "react-bootstrap/NavDropdown"
import clubyLogo from "./img/cluby_logo.png"
import {
  ModifyVenueLocation,
  BenefitsLocation,
  BenefitsModifyLocation,
  ChangeOrgLocation,
  EventsLocation,
  EventsModifyLocation,
  LoginLocation,
  LogoutLocation, ManageVenuesLocation, MemberLocation,
  MembersLocation,
  ProductModifyLocation,
  ProductsLocation,
  ProductsModifyLocation,
  ProfileLocation,
  ResetPasswordLocation,
  RootLocation,
  InvitationsLocation,
  SignupLocation, SuperAdminLocation, CreateOrganizationLocation, MembersStatusesModifyLocation, CreateVenueLocation, MemberStatusModifyLocation, MessagesLocation, SendPushNotificationLocation, SendEmailLocation, ManagePremiumLocation, MembersStatusSubscriptionsModifyLocation, MemberStatusSubscriptionModifyLocation, EmailOctopusApiKeyLocation, ManageEmailOctopusApiKeyLocation, RewardsLocation, StampCardModifyLocation, RewardModifyLocation,
  HomeLocation,
  EventLocation,
  RecentActivitiesLocation
} from "./Locations"
import LogoutScreen from "./screens/Logout"
import {AppRoute} from "./components/AppRoute"
import Dropdown from "react-bootstrap/Dropdown"
import Container from "react-bootstrap/Container"
import {WithTranslation, withTranslation} from "react-i18next"
import i18n from "./i18n"
import {resolveIdentifier} from "mobx-state-tree"
import {IOrganization, Organization} from "./models/Organization"
import {IVenue, Venue} from "./models/Venue"
import BenefitsScreen from "./screens/Benefits"
import MembersScreen from "./screens/MembersScreen"
import EventsScreen from "./screens/Events"
import EventsModifyScreen from "./screens/EventsModify"
import ProfileScreen from "./screens/Profile"
import ChangeOrgScreen from "./screens/ChangeOrg"
import ManagePremiumScreen from "./screens/ManagePremium"
import ResetPasswordScreen from "./screens/ResetPassword"
import {library} from "@fortawesome/fontawesome-svg-core"
import {
  faCalendarCheck,
  faCalendarPlus,
  faCalendarLines as falCalendarLines,
  faCheckCircle,
  faCheck as falCheck,
  faEdit,
  faEnvelope,
  faEyeSlash as falEyeSlash,
  faStar as falStar,
  faTrashAlt,
  faTrash,
  faUser,
  faUserCircle,
  faClone as falClone,
  faPlusSquare as falPlusSquare,
  faCreditCard as falCreditCard,
  faAddressCard as falAddressCard,
} from "@fortawesome/pro-light-svg-icons"
import {
  faBeer,
  faCalendarAlt,
  faCaretDown,
  faCaretUp,
  faCheck,
  faClock,
  faCocktail,
  faCoffee,
  faGlassCheers,
  faHamburger,
  faHome,
  faReplyAll,
  faTrashAlt as fasTrashAlt,
  faStar,
  faUser as fasUser,
  faUsers,
  faWineBottle,
  faPlus as fasPlus,
  faSync as fasSync,
} from "@fortawesome/free-solid-svg-icons"
import {
  faCalendarStar,
  faCaretDown as falCaretDown,
  faClipboardCheck,
  faCloudArrowUp as falCloudArrowUp,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFilter,
  faKey,
  faMinusCircle,
  faPlusCircle,
  faSave,
  faSignIn,
  faSort,
  faTrashUndo,
  faUndo,
  faAlarmClock,
  faShoppingCart,
  faUserCrown as falUserCrown,
  faSpinner as falSpinner,
  faCopy as falCopy,
  faLink as falLink,
  faCreditCardBlank as falCreditCardBlank,
  faUserFriends as falUserFriends,
  faUsdCircle as falUsdCircle,
  faArrowsRotate as falArrowsRotate,
  faMobile as falMobile,
  faPencil as falPencil,
  faPenToSquare as falPenToSquare,
  faChevronDown as falChevronDown,
  faChevronsDown as falChevronsDown,
  faChevronsLeft as falChevronsLeft,
  faChevronRight as falChevronRight,
  faChevronLeft as falChevronLeft,
  faGiftCard as falGiftCard,
  faTasks as falTasks,
  faUsers as falUsers,
  faCalendarAlt as falCalendarAlt,
} from "@fortawesome/pro-light-svg-icons"
import {
  faBox,
  faCheese,
  faCheeseburger,
  faDrumstick,
  faFrenchFries,
  faGlass,
  faGlassChampagne,
  faIceCream,
  faMugHot,
  faPie,
  faPizza,
  faSalad,
  faSandwich,
  faSoup,
  faStroopwafel,
  faTicketAlt,
  faUtensilsAlt,
  faWineGlass,
  faWineGlassAlt,
  faPlus,
} from "@fortawesome/pro-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {IRootStore} from "./models/RootStore"
import SignupScreen from "./screens/Signup"
// @ts-ignore
// eslint-disable-next-line
import Intercom from "react-intercom"
import BenefitsModifyScreen from "./screens/BenefitsModify"
import Cookies from "universal-cookie"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import moment from "moment"
import MemberScreen from "./screens/MemberScreen";
import SuperAdminScreen from "./screens/SuperAdmin";
import Bugsnag from "@bugsnag/js"
import ManageVenues from "./screens/ManageVenues"
import CreateUpdateProductScreen from "./screens/CreateUpdateProductScreen"
import ProductsScreen from "./screens/ProductsScreen"
import ProductsModifyScreen from "./screens/ProductsModifyScreen"
import CreateUpdateVenueScreen from "./screens/CreateUpdateVenueScreen/CreateUpdateVenueScreen"
import CreateOrganization from "./screens/OrganizationsManager/CreateOrganization"
import ManageMemberStatusesScreen from "./screens/ManageMemberStatusesScreen"
import CreateUpdateMembershipCardScreen from "./screens/CreateUpdateMemberStatusScreen/CreateUpdateMemberStatusScreen"
import MessagesScreen from "./screens/MessagesScreen"
import SendPushNotificationScreen from "./screens/MessagesScreen/SendPushNotificationScreen"
import SendEmailScreen from "./screens/MessagesScreen/SendEmailScreen"
import ManageMemberStatusSubscriptionsScreen from "./screens/ManageMemberStatusSubscriptionsScreen"
import CreateUpdateMemberStatusSubscriptionScreen from "./screens/CreateUpdateMemberStatusSubscriptionScreen/CreateUpdateMemberStatusSubscriptionScreen"
import InvitationsScreen from "./screens/ManageUsersScreen"
import { IMemberStore } from "./models/MemberStore"
import { colors } from "./theme/colors"
import EmailOctopusApiKeyScreen from "./screens/EmailOctopusApiKeyScreen"
import EmailOctopusManageScreen from "./screens/EmailOctopusManageScreen"
import RewardsScreen from "./screens/RewardsScreen"
import CreateUpdateStampCardScreen from "./screens/CreateUpdateStampCardScreen"
import CreateUpdateRewardScreen from "./screens/CreateUpdateRewardScreen"
import { ILoyaltyCardStore } from "./models/LoyaltyCardStore"
import HomeScreen from "./screens/HomeScreen"
import EventScreen from "./screens/EventScreen"
import { IProductReportStore } from "./models/ProductReportStore"
import RecentMembersActivitiesScreen from "./screens/RecentMembersActivitiesScreen"
import { PostHogFeature } from 'posthog-js/react'
import posthog from "posthog-js"

import { identifyUser } from "./utils/analytics"
import SideNavbar from "./components/SideNavbar"


library.add(
  falArrowsRotate,
  falLink,
  falCopy,
  falPencil,
  faTrash,
  falCreditCardBlank,
  falSpinner,
  falUserCrown,
  falCreditCard,
  falPlusSquare,
  falAddressCard,
  falGiftCard,
  falClone,
  falUserFriends,
  falUsdCircle,
  faAlarmClock,
  faUser,
  faEnvelope,
  faReplyAll,
  faHome,
  faKey,
  faStar,
  faUsers,
  falUsers,
  faCalendarAlt,
  falCalendarAlt,
  faShoppingCart,
  faExclamationCircle,
  faCaretUp,
  faCaretDown,
  faSort,
  faFilter,
  faCheck,
  falChevronsDown,
  falChevronDown,
  faEdit,
  falChevronsLeft,
  falChevronLeft,
  falChevronRight,
  falCloudArrowUp,
  faTrashUndo,
  faSave,
  faUndo,
  faEyeSlash,
  faEye,
  faClipboardCheck,
  faPlusCircle,
  faTrashAlt,
  fasTrashAlt,
  faClock,
  faSignIn,
  falStar,
  falCaretDown,
  faUserCircle,
  faCheckCircle,
  falCheck,
  faMinusCircle,
  faCalendarStar,
  faCalendarCheck,
  falCalendarLines,
  falTasks,
  faCalendarPlus,
  falEyeSlash,
  fasUser,
  falMobile,
  falPenToSquare,
  fasPlus,
  fasSync,
  // benefits
  faCocktail,
  faCoffee,
  faGlassCheers,
  faUtensilsAlt,
  faPizza,
  faBeer,
  faWineBottle,
  faHamburger,
  faDrumstick,
  faTicketAlt,
  faGlassChampagne,
  faSandwich,
  faIceCream,
  faWineGlassAlt,
  faStroopwafel,
  faFrenchFries,
  faMugHot,
  faWineGlass,
  faSalad,
  faSoup,
  faPie,
  faCheeseburger,
  faCheese,
  faGlass,
  faBox,
)

interface OrganizationParam {
  organizationId?: string
  venueId?: string
  groupId?: string
}

interface AppProps extends RouteComponentProps, WithTranslation {
  userSessionStore?: IUserSessionStore
  loyaltyCardStore?: ILoyaltyCardStore
  memberStore?: IMemberStore
  rootStore?: IRootStore
  api?: MerchantAdminApi
  productReportStore?: IProductReportStore
  match: match<OrganizationParam>
}

interface AppState {
  initialAuthenticationDone: boolean
  initialLoading: boolean
}



@(withRouter as any)
@inject("userSessionStore")
@inject("loyaltyCardStore")
@inject("memberStore")
@inject("rootStore")
@inject("api")
@inject("productReportStore")
@observer
class App extends React.Component<AppProps, AppState> {
  constructor(props: Readonly<AppProps>) {
    super(props)
    this.state = {
      initialLoading: true,
      initialAuthenticationDone: false,
    }
  }

  async componentDidMount(): Promise<void> {

    console.log("Current language:", i18n.languages[0])
    moment.locale(i18n.languages[0])
    const {match: {params}, api, userSessionStore, rootStore} = this.props
    try {
      const loggedIn = await api!.refreshLoginStatus()
      if (loggedIn) {
        if (params.venueId && params.venueId !== 'new') {
          const venue = resolveIdentifier(Venue, userSessionStore!.organizations, params.venueId) as IVenue
          await userSessionStore!.setSelectedBranch(venue)

        } else if (params.groupId) {

        } else if (params.organizationId) {
          const organization = resolveIdentifier(Organization, userSessionStore!.organizations, params.organizationId) as IOrganization
          await userSessionStore!.setSelectedBranch(organization)

        } else {
          await userSessionStore!.setSelectedBranchFallback()
        }

        this.setState({initialAuthenticationDone: true})
        await rootStore!.fetchData()
      }
    } finally {
      this.setState({initialLoading: false})
      userSessionStore!.clearAlertMessage()
    }
  }

  changeLanguage(lng: any) {
    i18n.changeLanguage(lng)
    const cookies = new Cookies()
    cookies.set("i18next", lng, {path: "/"})
    console.log("Current language:", i18n.languages[0])
  }

  navBarLoggedOut() {
    return (
      <>
        {/*
      // @ts-ignore */}
        <Navbar variant="dark" expand={"lg"} fixed="top" className="navbar">
          <Container className="px-3 px-lg-0" style={{ maxWidth: "1250px" }}>
            <Navbar.Brand>
              <img
                src={clubyLogo}
                height="38"
                className="d-inline-block align-middle"
                alt="Cluby"
              />
            </Navbar.Brand>

            <Nav className="ml-auto">
              {this.languageDropdown()}
              <Nav.Link as={Link} to={LoginLocation.toUrl()}>{this.props.t("login")}</Nav.Link>
            </Nav>
          </Container>
        </Navbar>
      </>
    )
  }

  languageDropdown() {
    return (
      <Nav className="mr-1">
        <NavDropdown
          /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
          onClick={(e: any) => e.stopPropagation()}
          title={<span className="text-uppercase">{i18n.languages[0]}</span>}
          alignRight
          id="collasible-nav-dropdown">
          <NavDropdown.Item className={i18n.languages[0] === "en" ? "active" : ""}
                           onClick={() => this.changeLanguage("en")}>English</NavDropdown.Item>
          <NavDropdown.Item className={i18n.languages[0] === "fi" ? "active" : ""}
                           onClick={() => this.changeLanguage("fi")}>Suomi</NavDropdown.Item>
          <NavDropdown.Item className={i18n.languages[0] === "es" ? "active" : ""}
                           onClick={() => this.changeLanguage("es")}>Español</NavDropdown.Item>
          <NavDropdown.Item className={i18n.languages[0] === "nb" ? "active" : ""}
                           onClick={() => this.changeLanguage("nb")}>Norsk</NavDropdown.Item>
        </NavDropdown>
      </Nav>
    )
  }

  changeSelectedBranch(branch: IOrganization | IVenue, currentLocation: any) {
    const isMemberProfilePage = this.props.history.location.pathname.includes("/profile/");
    if (isMemberProfilePage) {
      this.props.history.goBack()
    }
    this.props.userSessionStore!.setSelectedBranch(branch)

    this.props.memberStore!.fetchData(undefined)
  }

  truncateText(text: string | undefined) {
    if (text && text.length > 15) {
      return text.substring(0, 14) + "...";
    }

    return text
  }

  navBarLoggedIn(currentLocation: any = undefined) {
    const {userSessionStore, t, loyaltyCardStore} = this.props

    const rewardsSummaries = loyaltyCardStore?.getRewardsSummaries()

    const isAllSummariesZeroValue = (summaries: any) => {
      if (!summaries) return true;
      return Object.values(summaries).every(value => value === 0);
    };

    const navLink = userSessionStore?.selectedBranch ? (
      <Nav.Link as={NavLink} className="px-2 menuGray"
        to={RewardsLocation.toUrl(userSessionStore.selectedBranch.urlFields())}>
        {t("rewards")}
      </Nav.Link>
    ) : null;

    const NavToRewardsPageLink = () => {
      if (!userSessionStore?.selectedBranch) return null;
      
      return <>
        <PostHogFeature flag='partnerhub-rewards-editing' match={true}
          fallback={!isAllSummariesZeroValue(rewardsSummaries) && navLink}>
          {navLink}
        </PostHogFeature>
      </>
    }

    const hasVenues = Boolean(userSessionStore?.currentOrganization?.venues?.length);

    const MoreMenuDropdown = () => {
      if (!userSessionStore?.selectedBranch || !userSessionStore?.profile) {
        return null;
      }
      
      if (!hasVenues) {
       return  <Nav className="mr-1">
    
        <NavDropdown
          /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
          onClick={(e: any) => e.stopPropagation()}
          alignRight
          title={<>
                                    {userSessionStore.profile.name!.split(" ")[0]}</>}
          id="collasible-nav-dropdown">
          <NavDropdown.Item as={Link} to={ProfileLocation.toUrl()}>{t("profile")}</NavDropdown.Item>
          {userSessionStore.organizations && userSessionStore.organizations!.length > 1 ? [
              <Dropdown.Divider key="dividerForChangeOrg"/>,
              <NavDropdown.Item key="changeOrg" as={Link}
                                to={ChangeOrgLocation.toUrl()}>{t("changeOrg")}</NavDropdown.Item>]
            : null}

<Dropdown.Divider/>
  <NavDropdown.Item as={Link} to={ManageVenuesLocation.toUrl(userSessionStore.selectedBranch.urlFields())}>
    {t("manageVenues")}
  </NavDropdown.Item>
          {userSessionStore.isSuperAdmin && <>
              <Dropdown.Divider/>
              <NavDropdown.Item as={Link} to={SuperAdminLocation.toUrl()} ><FontAwesomeIcon size="1x" className="mr-2"
                                                                          icon={["fal", "user-crown"]}/>{t("superAdmin")}
              </NavDropdown.Item>
          </>}
          <Dropdown.Divider/>
          <NavDropdown.Item as={Link} to={LogoutLocation.toUrl()}>{t("logout")}</NavDropdown.Item>
        </NavDropdown>
      </Nav>
      }

return    <Nav className="mr-1">
<NavDropdown
  /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
  data-testid="profile-dropdown"
  onClick={(e: any) => e.stopPropagation()}
  alignRight
  title={<>
  {/* <FontAwesomeIcon className="mr-2"
                            icon={["fal", "user"]}/> */}
                            {userSessionStore.profile.name!.split(" ")[0]}</>}
  id="collasible-nav-dropdown">
  <NavDropdown.Item as={Link} to={ProfileLocation.toUrl()}>{t("profile")}</NavDropdown.Item>
  {userSessionStore.organizations && userSessionStore.organizations!.length > 1 ? [
      <Dropdown.Divider key="dividerForChangeOrg"/>,
      <NavDropdown.Item key="changeOrg" as={Link}
                        to={ChangeOrgLocation.toUrl()}>{t("changeOrg")}</NavDropdown.Item>]
    : null}
  <Dropdown.Divider/>
  <NavDropdown.Item as={Link} to={ManageVenuesLocation.toUrl(userSessionStore.selectedBranch.urlFields())}>
    {t("manageVenues")}
  </NavDropdown.Item>
  <Dropdown.Divider/>
  <NavDropdown.Item as={Link} to={InvitationsLocation.toUrl(userSessionStore.selectedBranch.urlFields())}>
    {t("manageUsers")}
  </NavDropdown.Item>
  {userSessionStore.isSuperAdmin && <>
      <Dropdown.Divider/>
      <NavDropdown.Item as={Link} to={SuperAdminLocation.toUrl()} ><FontAwesomeIcon size="1x" className="mr-2"
                                                                  icon={["fal", "user-crown"]}/>{t("superAdmin")}
      </NavDropdown.Item>
  </>}
  <Dropdown.Divider/>
  <NavDropdown.Item onClick={() => window.open('https://help.cluby.com', '_blank')}>
    {t("supportCenter")}
  </NavDropdown.Item>
  <Dropdown.Divider/>
  <NavDropdown.Item as={Link} to={LogoutLocation.toUrl()}>{t("logout")}</NavDropdown.Item>
</NavDropdown>
</Nav>
    }

    const VenueSelectionDropdown = () => {
      if (!userSessionStore?.selectedBranch || !userSessionStore?.currentOrganization || !hasVenues) {
        return null;
      }

      if (!userSessionStore.currentOrganization.isSingleVenue()) {
        return <NavDropdown
                /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
                onClick={(e: any) => e.stopPropagation()}
                style={{width: 140, minWidth: 140, maxWidth: 140, textAlign: 'center'}}
                title={<span
                  className="menuGray">{userSessionStore.isOrganizationContext() ? t("allVenues") : this.truncateText(userSessionStore.selectedBranch.name)}</span>}
                id="navbar-company-selection-dropdown" className="menuGray"
              >

                <NavDropdown.Item
                  className={userSessionStore.isOrganizationContext() ? "active" : ""}
                  key={"navchangecompany" + userSessionStore.currentOrganization}
                  onClick={this.changeSelectedBranch.bind(this, userSessionStore.currentOrganization, currentLocation)}
                  as={Link}
                  to={currentLocation.toUrl({...currentLocation.parseLocationParams(), ...userSessionStore.currentOrganization.urlFields()})}>{t("allVenues")}</NavDropdown.Item>

                <Dropdown.Divider/>
                {userSessionStore.currentOrganization.venues!.map((venue: IVenue) => {
                  return (
                    <NavDropdown.Item
                      className={!userSessionStore.isOrganizationContext() && userSessionStore.selectedBranch === venue ? "active" : ""}
                      key={"navchangecompany" + venue.id}
                      onClick={this.changeSelectedBranch.bind(this, venue)} as={Link}
                      to={currentLocation.toUrl({...currentLocation.parseLocationParams(), ...venue.urlFields()})}>{venue.name}</NavDropdown.Item>
                  )
                })}
              </NavDropdown>
      }
              
      return <div className="menuGray" style={{width: 140, minWidth: 140, maxWidth: 140, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>{this.truncateText(userSessionStore.selectedBranch.name)}</div>
    }

    const isMinimalDataNotAvailable = !userSessionStore?.selectedBranch || !userSessionStore?.profile
    if (isMinimalDataNotAvailable) {
      return null;
    }

    return (
      <>
        {/*
      // @ts-ignore */}
        <Navbar variant="dark" expand={"lg"} fixed="top" className="navbar">
          <Container className="px-3 px-lg-0" style={{ maxWidth: "1250px" }}>
            <Navbar.Brand>
              <img
                src={clubyLogo}
                style={{
                  width: 68,
                  objectFit: 'cover'
                }}
                className="d-inline-block align-middle"
                alt="Cluby"
              />
            </Navbar.Brand>
            <VenueSelectionDropdown/>
            
            <div className="ml-auto d-flex align-items-center">
              {this.languageDropdown()}
              <MoreMenuDropdown/>
            </div>
          </Container>
        </Navbar>
      </>
    )
  }

  getNavBar(this: this, currentLocation: any) {
    return !this.props.location!.pathname.includes("modify") ? this.props.userSessionStore!.isLoggedIn() ? this.navBarLoggedIn(currentLocation) : this.navBarLoggedOut() : null
  }

  render() {
    const {userSessionStore, t, rootStore, loyaltyCardStore, productReportStore} = this.props
    const {initialAuthenticationDone, initialLoading} = this.state
    let user: any = {}
    if (userSessionStore && userSessionStore.profile) {
      user = {
        email: userSessionStore.profile.email,
        name: userSessionStore.profile.name,
        user_hash: userSessionStore.profile.intercomHash,
      }
      Bugsnag.setUser(user.user_hash, user.email, user.name)
    }
    if (userSessionStore && userSessionStore.profile) {
      identifyUser(userSessionStore.profile.id, {
        email: userSessionStore.profile.email,
        name: userSessionStore.profile.name
      })
    }

    const isSalesConstraintFFEnabled = posthog.isFeatureEnabled('sales-enabled-constraint-ff')

    return (
      <div className="App">
        {rootStore!.appStatus.dataLoadingDim && <div className="loading-dim"></div>}
     
        {!this.props.location!.pathname.includes("modify") ? 
          (this.props.userSessionStore!.isLoggedIn() && this.props.userSessionStore!.selectedBranch) ? 
            this.navBarLoggedIn(HomeLocation) : 
            this.navBarLoggedOut() : 
          null
        }
        
        {initialLoading || rootStore!.appStatus.dataLoading || rootStore!.appStatus.dataLoadingError ?
          <Container className="mt-5 pt-4">
            <Row>
              <div className="col-5 mx-auto mt-5 text-center">
                <Card className="text-center" style={{
                  border: `solid 1px ${colors.stroke}`
                }}>
                  <Card.Header as="h5" style={{
                    background: colors.boxFill
                  }}>{t("auracardMerchants")}</Card.Header>
                  <Card.Body className="py-4">
                    {
                      rootStore!.appStatus.dataLoadingError ?
                        <div className="text-danger" role="alert">
                          <FontAwesomeIcon className="mr-2" icon={["fal", "exclamation-circle"]}/>{t("initErrorText")}
                        </div>
                        : rootStore!.appStatus.dataLoading ?
                        <>
                          <h5>{t("loadingData")}.. { /* rootStore!.appStatus.dataLoadingPercentage% */}</h5>
                        </>
                        : !initialAuthenticationDone ?
                          <>
                            <h5>{t("loadingAuthentication")}..</h5>
                          </>
                          : <h5>...</h5>
                    }
                    <h5 className="mt-3"><FontAwesomeIcon size="1x" icon={["fal", "spinner"]} spin={true}/></h5>

                  </Card.Body>
                </Card>
                <small style={{ color: "#999999"}}>v{process.env.REACT_APP_VERSION}</small>
              </div>
            </Row>
          </Container>
          :
          <div className="app-container">
            {userSessionStore!.isLoggedIn() && (
              <SideNavbar 
                userSessionStore={userSessionStore!} 
                loyaltyCardStore={loyaltyCardStore}
                productReportStore={productReportStore}
              />
            )}
            <div className={userSessionStore!.isLoggedIn() ? "main-content-with-sidebar" : "w-100"}>
              <Switch>
                {/* Switch does not support custom routes without path, so we need to pass location.path and the location object itself */}
                <AppRoute path={LoginLocation.path} component={LoginScreen} exact
                          authenticationRequired={false} navBar={this.getNavBar.bind(this, LoginLocation)}
                          loggedInRedirect={RootLocation.toUrl()}
                />
                <AppRoute path={ResetPasswordLocation.path}
                          component={ResetPasswordScreen} exact
                          authenticationRequired={false} navBar={this.getNavBar.bind(this, ResetPasswordLocation)}
                          loggedInRedirect={RootLocation.toUrl()}
                />
                <AppRoute path={SignupLocation.path} component={SignupScreen} exact
                          authenticationRequired={false} navBar={this.getNavBar.bind(this, SignupLocation)}
                          loggedInRedirect={RootLocation.toUrl()}
                />
                <AppRoute path={ChangeOrgLocation.path} component={ChangeOrgScreen} exact
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, ChangeOrgLocation)}
                />
                <AppRoute path={ManagePremiumLocation.path} component={ManagePremiumScreen} exact
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, ManagePremiumLocation)}
                />
                <AppRoute path={ManageVenuesLocation.path} component={ManageVenues} exact
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, ManageVenuesLocation)}
                />
                <AppRoute path={ModifyVenueLocation.path} component={CreateUpdateVenueScreen} exact
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, ModifyVenueLocation)}
                />
                <AppRoute path={CreateVenueLocation.path} component={CreateUpdateVenueScreen} exact
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, CreateVenueLocation)}
                />
                <AppRoute path={EmailOctopusApiKeyLocation.path} component={EmailOctopusApiKeyScreen} exact
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, EmailOctopusApiKeyLocation)}
                />
                <AppRoute path={ManageEmailOctopusApiKeyLocation.path} component={EmailOctopusManageScreen} exact
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, ManageEmailOctopusApiKeyLocation)}
                />

                <AppRoute path={BenefitsLocation.path} exact component={BenefitsScreen}
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, BenefitsLocation)}
                />
                <AppRoute path={BenefitsModifyLocation.path} exact component={BenefitsModifyScreen}
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, BenefitsModifyLocation)}
                />
                <AppRoute path={MemberLocation.path} exact component={MemberScreen}
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, MemberLocation)}
                />
                    <AppRoute path={HomeLocation.path} exact component={HomeScreen}
                authenticationRequired={true} navBar={this.getNavBar.bind(this, HomeLocation)}
              />

                <AppRoute path={RecentActivitiesLocation.path} exact component={RecentMembersActivitiesScreen}
                  authenticationRequired={true} navBar={this.getNavBar.bind(this, RecentActivitiesLocation)}
                />

                <AppRoute path={MembersLocation.path} exact component={MembersScreen}
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, MembersLocation)}
                />
                <AppRoute path={MembersStatusesModifyLocation.path} exact component={ManageMemberStatusesScreen}
                          authenticationRequired={true} salesRequired={isSalesConstraintFFEnabled} navBar={this.getNavBar.bind(this, MembersStatusesModifyLocation)}
                />
                <AppRoute path={MembersStatusSubscriptionsModifyLocation.path} exact component={ManageMemberStatusSubscriptionsScreen}
                          authenticationRequired={true} salesRequired={isSalesConstraintFFEnabled} navBar={this.getNavBar.bind(this, MembersStatusSubscriptionsModifyLocation)}
                />
                <AppRoute path={EventsLocation.path} exact component={EventsScreen}
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, EventsLocation)}
                />
                <AppRoute path={ProductsLocation.path} exact component={ProductsScreen}
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, ProductsLocation)}
                />
                <AppRoute path={MessagesLocation.path} exact component={MessagesScreen}
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, MessagesLocation)}
                />
                <AppRoute path={RewardsLocation.path} exact component={RewardsScreen}
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, RewardsLocation)}
                />

                <AppRoute path={InvitationsLocation.path} exact component={InvitationsScreen}
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, InvitationsLocation)}
                />
                <AppRoute path={SendEmailLocation.path} exact component={SendEmailScreen}
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, SendEmailLocation)}
                />
                <AppRoute path={SendPushNotificationLocation.path} exact component={SendPushNotificationScreen}
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, SendPushNotificationLocation)}
                />
                <AppRoute path={ProductModifyLocation.path} exact component={CreateUpdateProductScreen}
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, ProductModifyLocation)}
                />
                 <AppRoute path={StampCardModifyLocation.path} exact component={CreateUpdateStampCardScreen}
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, StampCardModifyLocation)}
                />
                  <AppRoute path={RewardModifyLocation.path} exact component={CreateUpdateRewardScreen}
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, RewardModifyLocation)}
                />
                <AppRoute path={MemberStatusModifyLocation.path} exact component={CreateUpdateMembershipCardScreen}
                          authenticationRequired={true} salesRequired={isSalesConstraintFFEnabled} navBar={this.getNavBar.bind(this, MemberStatusModifyLocation)}
                />

                <AppRoute path={MemberStatusSubscriptionModifyLocation.path} exact component={CreateUpdateMemberStatusSubscriptionScreen}
                          authenticationRequired={true} salesRequired={isSalesConstraintFFEnabled} navBar={this.getNavBar.bind(this, MemberStatusModifyLocation)}
                />

                <AppRoute path={ProductsModifyLocation.path} exact component={ProductsModifyScreen}
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, ProductsModifyLocation)}
                />
                <AppRoute path={EventsModifyLocation.path} exact component={EventsModifyScreen}
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, EventsModifyLocation)}
                />
                <AppRoute path={EventLocation.path} exact component={EventScreen}
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, EventLocation)}
                />
                <AppRoute path={ProfileLocation.path} exact component={ProfileScreen}
                          authenticationRequired={true} navBar={this.getNavBar.bind(this, ProfileLocation)}
                />

                <AppRoute path={SuperAdminLocation.path} exact component={SuperAdminScreen}
                          enabled={this.props.userSessionStore!.isSuperAdmin}
                          authenticationRequired={false} navBar={this.getNavBar.bind(this, LogoutLocation)}
                />
                <AppRoute path={CreateOrganizationLocation.path} exact component={CreateOrganization}
                          enabled={this.props.userSessionStore!.isSuperAdmin}
                          authenticationRequired={false} navBar={this.getNavBar.bind(this, LogoutLocation)}
                />

                <AppRoute path={LogoutLocation.path} exact component={LogoutScreen}
                          authenticationRequired={false} navBar={this.getNavBar.bind(this, LogoutLocation)}
                />

                {this.props.userSessionStore!.isLoggedIn() ?
                  <Redirect to={HomeLocation.toUrl(this.props.userSessionStore!.selectedBranch!.urlFields())}/>
                  :
                  <Redirect to={LoginLocation.toUrl()}/>
                }
              </Switch>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default withTranslation()(App)