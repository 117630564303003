import React from 'react'
import { Field } from 'formik'
import { FormGroup, InputGroup, FormControl, FormLabel } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

interface FormikTitleFieldProps {
  name: string
  languageCode: string
  errors: any
  touched: any
  values: any
  onChange?: (e: React.ChangeEvent<any>) => void
  setFieldValue?: (field: string, value: any) => void
  labelTextKey?: string
  placeholderTextKey: string
  showLabel?: boolean
  isAdditionalField?: boolean
  syncWithField?: string 
}

export const FormikTitleField: React.FC<FormikTitleFieldProps> = ({
  name,
  languageCode,
  errors,
  touched,
  values,
  onChange,
  setFieldValue,
  labelTextKey,
  placeholderTextKey,
  showLabel = true,
  isAdditionalField = false,
  syncWithField
}) => {
  const { t } = useTranslation()
  const fieldPath = name.split('.')
  const error = fieldPath.reduce((obj, key) => obj?.[key], errors)
  const isTouched = fieldPath.reduce((obj, key) => obj?.[key], touched)
  const value = fieldPath.reduce((obj, key) => obj?.[key], values)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setFieldValue) {
      setFieldValue(name, e.target.value)
      if (syncWithField) {
        setFieldValue(syncWithField, e.target.value)
      }
    } else {
      onChange?.(e)
    }
  }

  return (
    <Field
      name={name}
      render={() => (
        <FormGroup controlId={name}>
          {showLabel && labelTextKey && (
            <FormLabel 
              className={`textInputLabel ${(error && isTouched) ? 'text-danger' : ''}`}>
              {t(labelTextKey)}
            </FormLabel>
          )}
          <InputGroup className="mb-2">
            <InputGroup.Prepend>
              <InputGroup.Text className={`pr-${isAdditionalField ? '3' : '2'}`} style={{minWidth: 48}}>
                {languageCode.toUpperCase()}
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl 
              className="textInput" 
              placeholder={t(placeholderTextKey, { lng: languageCode })}
              type="text"
              isInvalid={error && isTouched}
              value={value || ''}
              onChange={handleChange}
            />
          </InputGroup>
          
          {error && isTouched && (
            <small className="text-danger">{error}</small>
          )}
        </FormGroup>
      )}
    />
  )
}