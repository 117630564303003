import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/fi';
import 'moment/locale/nb';
import { initReactI18next } from 'react-i18next';

const en = require('./i18n/en')
const fi = require('./i18n/fi')
const es = require('./i18n/es')
const nb = require('./i18n/nb')

const resources = { en, fi, es, nb }

i18n.on('languageChanged', function(lng) {
    moment.locale(lng);
});

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    detection: {
      lookupCookie: 'i18next',
      order: ['querystring', 'cookie'],
    },
    resources,
    //lng: 'en',
    supportedLngs: Object.keys(resources),
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function (value, format, lng) {
        return value
      },
    },
  })

export default i18n
