import { array, boolean, InferType, number, object, string } from 'yup';
import { LocalizableStringSchema } from '../LocalizableString';

const HTTPS_PREFIX_REGEX = /^https:\/\/.+/i
const FACEBOOK_PAGE_REGEX = /^https:\/\/(www\.)?facebook\.com\/[a-zA-Z0-9.\-]+\/?$/;

const ContactInformationSchema = object({
  www: string().url().matches(HTTPS_PREFIX_REGEX),
  email: string().email(),
  phone: string(),
  facebook: string().url().matches(FACEBOOK_PAGE_REGEX),
  messenger: string().url(),
  whatsapp: string(),
  instagram: string()
})

const EnabledFeaturesSchema = object({
  tableBookingUri: string().url().matches(HTTPS_PREFIX_REGEX)
})

const ImagesSchema = object({
  logoImage: string().url(),
  mainImage: string().url()
})

export const ServerVenueLocationSchema = object({
  id: string(),
  publicId: string(),
  name: string(),
  address: string().required(),
  postcode: string(),
  city: string().required(),
  country: string(),
  coordinates: object({
    lat: number().required().min(-90.0).max(90.0),
    lng: number().required().min(-180.0).max(180.0)
  }),
  placeId: string()
})

export const ServerVenueSchema = object({
  id: string().required(),
  name: string().required().min(1).max(64),

  handle: string().required().matches(/^[a-z0-9]+(?:\-[a-z0-9]+)*$/),
  description: LocalizableStringSchema,
  categories: LocalizableStringSchema,

  images: ImagesSchema,
  enabledFeatures: EnabledFeaturesSchema,
  contactInformation: ContactInformationSchema,

  businessId: string(),
  _bankAccount: string(),

  isHidden: boolean().default(true),
  locations: array(ServerVenueLocationSchema).required().min(1)
});


export type ServerVenue = InferType<typeof ServerVenueSchema>
export type ServerVenueLocation = InferType<typeof ServerVenueLocationSchema>
