import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { inject, observer } from "mobx-react"
import React from "react"
import Button from "react-bootstrap/Button"
import Dropdown from "react-bootstrap/Dropdown"
import { withTranslation, WithTranslation } from "react-i18next"
import { RouteComponentProps, withRouter } from "react-router"
import AlertMessage from "../components/AlertMessage"
import EventTable from "../components/EventTable"
import { SummaryHeader, SummaryItem } from "../components/SummaryHeader"
import CreateEventButton from "../components/buttons/CreateEventButton"
import i18n from "../i18n"
import { EventLocation, EventsModifyLocation } from "../Locations"
import { IEventReportStore } from "../models/EventReportStore"
import { IEventStore } from "../models/EventStore"
import { IUserSessionStore } from "../models/UserSessionStore"
import { IVenue } from "../models/Venue"
import { trackEvent } from "../tracking"
import trackingConstants from "../tracking/trackingConstants"
import { getEventsOrganizationSummary } from "./HomeScreen/utils"
import FeatureStartGuideCard, { GuideType } from "../components/FeatureStartGuideCard"
import { getHasOrganizationVenues, featureChecks } from "../components/FeatureStartGuideCard/utils"
import posthog from "posthog-js";

const EVENT_SCREEN_ERROR = "eventScreen"

export interface EventsScreenProps extends RouteComponentProps, WithTranslation {
  userSessionStore?: IUserSessionStore
  eventStore?: IEventStore
  eventReportStore?: IEventReportStore
}

@(withRouter as any)
@inject("userSessionStore")
@inject("eventReportStore")
@inject("eventStore")
@observer
class EventsScreen extends React.Component<EventsScreenProps> {

  componentDidMount(): void {
    trackEvent(trackingConstants.screens.events, {selectedBranch: this.props.userSessionStore!.selectedBranch!.name})
  }

  forwardToAddScreen(venue: IVenue) {
    this.props.history.push(EventsModifyLocation.toUrl(venue.urlFields()))
  }

  forwardToModifyScreen(venue: IVenue, eventId: string, createBasedOn: boolean = false) {
    this.props.history.push(EventsModifyLocation.toUrl({...venue.urlFields(), eventId: eventId, createBasedOn: createBasedOn}))
  }

   navigateToEvent = ({venue, eventId}: {venue: IVenue, eventId: string}) =>{
    this.props.history.push(EventLocation.toUrl({...venue.urlFields(), eventId: eventId}))
   }

  render() {
    const {userSessionStore, eventStore, eventReportStore, t} = this.props
    
    const summaryLocale = i18n.languages[0] === "fi" ? "fi-FI" : "en-US"

    // eslint-disable-next-line
    const summaries: Array<SummaryItem> = (!userSessionStore!.isOrganizationContext() && eventStore!.summaries.has(userSessionStore!.selectedBranch!.id)) || userSessionStore!.isOrganizationContext() && eventStore!.summaries.size > 0 ?
      [
        {
          title: t("eventScreen.summary.ticketsSoldHeader"),
          value: userSessionStore!.isOrganizationContext() ? getEventsOrganizationSummary({eventStore: eventStore!, field: "entrances"}) : eventStore!.summaries.get(userSessionStore!.selectedBranch!.id)!.entrances.toString(),
          footer: t("eventScreen.summary.ticketsSoldFooter"),
        },
        {
          title: t("eventScreen.summary.ticketsSoldRecentlyHeader"),
          value: userSessionStore!.isOrganizationContext() ? getEventsOrganizationSummary({eventStore: eventStore!, field: "entrances30Days"}) : eventStore!.summaries.get(userSessionStore!.selectedBranch!.id)!.entrances30Days.toString(),
          footer: t("eventScreen.summary.ticketsSoldRecentlyFooter"),
        },
        {
          title: t("eventScreen.summary.revenueHeader"),
          value: ((userSessionStore!.isOrganizationContext() ? getEventsOrganizationSummary({eventStore: eventStore!, field: "revenue"}) : eventStore!.summaries.get(userSessionStore!.selectedBranch!.id)!.revenue) / 100).toLocaleString(summaryLocale, {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
          footer: t("eventScreen.summary.revenueFooter"),
        },
        {
          title: t("eventScreen.summary.revenueRecentlyHeader"),
          value: ((userSessionStore!.isOrganizationContext() ? getEventsOrganizationSummary({eventStore: eventStore!, field: "revenue30Days"}) : eventStore!.summaries.get(userSessionStore!.selectedBranch!.id)!.revenue30Days) / 100).toLocaleString(summaryLocale, {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
          footer: t("eventScreen.summary.revenueRecentlyFooter"),
        },
      ] : []

      const hasVenues = getHasOrganizationVenues({userSessionStore})
      const isOrganizationContext = userSessionStore?.isOrganizationContext();
      const selectedVenueId = !isOrganizationContext ? userSessionStore?.selectedBranch?.id : null;
      const hasEvents = featureChecks.hasEvents({eventStore, isOrganizationContext, selectedVenueId});

      if (posthog.isFeatureEnabled('onboarding')) {
        if (!hasVenues) {
          return <FeatureStartGuideCard guideType={GuideType.CREATE_VENUE} />;
        }
  
        if (!hasEvents) {
          return <FeatureStartGuideCard guideType={GuideType.CREATE_EVENT} />
        }
      }

      

    return (
      <div>
        <AlertMessage source={EVENT_SCREEN_ERROR}/>
        {eventStore!.events.length === 0 &&
        <div className="text-center mt-5 mb-5">
          <h2
            className="p-0 m-0 text-primary font-weight-bold">{userSessionStore!.isOrganizationContext() ? t("eventScreen.header.title") : userSessionStore!.selectedBranch!.name}</h2>
          <p className="p-0 m-0 font-size-medium">{t("eventScreen.header.promotionText")}</p>
        </div>
        }
       <SummaryHeader summaries={summaries} isSingleVenue={userSessionStore!.currentOrganization!.isSingleVenue()} buttons={<CreateEventButton />}/>
            <EventTable data={eventReportStore!} forwardToModify={this.forwardToModifyScreen.bind(this)}
                        eventStore={eventStore!}
                        navigateToEvent={this.navigateToEvent}
                        isOrganizationContext={userSessionStore!.isOrganizationContext()}/>
      </div>
    )
  }
}

export default withTranslation()(EventsScreen)
