import { toJS } from "mobx";
import { Instance } from "mobx-state-tree";
import { transformToLocalizableString } from "../LocalizableString";
import type { Venue as MobxVenue } from "../Venue";
import { ServerVenue, ServerVenueSchema } from "./ServerVenue";

type MobxVenueType = Instance<typeof MobxVenue>

const getImagesAsPlainObject = (images: MobxVenueType['images'] | null): ServerVenue['images'] => {
  return {
    logoImage: images?.logoImage ?? undefined,
    mainImage: images?.mainImage ?? undefined,
  } as ServerVenue['images'];
};

export const mobxVenueToServerVenue = <T extends boolean>(venue: MobxVenueType, validate: T): T extends true ? ServerVenue : Partial<ServerVenue> => {
  const v = toJS(venue); // Get the object and not mobx

  // Fill the default categories from now forward
  const categories = transformToLocalizableString(v.categories)
  if (!categories.default && categories.en) categories.default = categories.en

  const locations = v.locations ?? [{
    address: v.street,
    postcode: v.postcode,
    city: v.city,
    country: v.country,
    coordinates: {
      lat: v.lat,
      lng: v.lng
    }
  }]

  const partialServerVenue = removeNullValues({
    id: v.id,
    name: v.name ?? undefined,
    handle: v.handle ?? undefined,
    description: transformToLocalizableString(v.description),
    categories,
    images: getImagesAsPlainObject(v.images),
    enabledFeatures: {
      tableBookingUri: v.enabledFeatures?.tableBookingUri ?? undefined
    },
    contactInformation: v.contactInformation,
    businessId: v.businessId,
    _bankAccount: v._bankAccount,
    isHidden: v.isHidden,
    locations
  })

  // Remove tel prefix from data, we know it's tel!
  if (partialServerVenue.contactInformation?.phone?.startsWith("tel:")) {
    partialServerVenue.contactInformation.phone = partialServerVenue.contactInformation.phone.slice(4)
  }

  if (validate) {
    ServerVenueSchema.validateSync(partialServerVenue);
  }

  return partialServerVenue as unknown as T extends true ? ServerVenue : Partial<ServerVenue>;
}

export const removeNullValues = (obj: Record<string, any>): Record<string, any> => {
  if (typeof obj !== 'object' || obj === null) return obj;

  if (Array.isArray(obj)) {
    // Filter out null values from arrays and recursively process each element
    return obj.map(item => removeNullValues(item)).filter(item => item !== null);
  }

  return Object.entries(obj)
    .filter(([, value]) => value !== null) // Remove null values
    .reduce((acc, [key, value]) => {
      acc[key] = typeof value === 'object' ? removeNullValues(value) : value;
      return acc;
    }, {} as Record<string, any>);
}
