import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { categories } from './categories';


/**
 * useDebounceValue Hook - Delays updating the value until after a specified delay.
 *
 * @param value The input value to debounce
 * @param delay The delay time in milliseconds
 * @returns The debounced value
 */
export const useDebounceValue = <T>(value: T, delay: number = 500): [T, Dispatch<SetStateAction<T>>] => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return [debouncedValue, setDebouncedValue];
}

export type CategoryKey = keyof typeof categories
export type LanguageKey = keyof typeof categories[CategoryKey];

export const populateCategories = (original: { default: string }) => {
  const categoriesList = original.default.split(', ').map(cat => cat.trim()) as CategoryKey[];

  // Initialize the result object with default value
  const result: Record<string, string> = {
    default: original.default
  };

  // Languages to support
  const languages = Object.keys(categories["Bar"]) as LanguageKey[];

  // Populate translations
  for (const lang of languages) {
    result[lang] = categoriesList.map(cat => categories[cat]?.[lang] ?? cat).join(', ');
  }

  return result as Record<LanguageKey | 'default', string>;
}

export const removeEmptyStringKeys = <T extends Record<string, any>>(obj: T): Partial<T> => {
  const result: Partial<T> = {};

  for (const [key, value] of Object.entries(obj)) {
    // Skip empty strings
    if (value === "") {
      continue;
    }

    // Recursively process nested objects
    if (typeof value === "object" && value !== null) {
      result[key as keyof T] = removeEmptyStringKeys(value) as T[keyof T];
    } else {
      // Keep non-empty values
      result[key as keyof T] = value;
    }
  }

  return result;
}
