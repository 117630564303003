import React from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IUserSessionStore } from '../../models/UserSessionStore';
import { IVenue } from '../../models/Venue';
import { MembersStatusSubscriptionsModifyLocation } from '../../Locations';
import { STATUS_TYPE } from '../../screens/ManageMemberStatusesScreen';

interface SellSubscriptionsButtonProps {
  userSessionStore?: IUserSessionStore;
  variant?: 'primary' | 'secondary' | 'success';
  className?: string;
}

const SellSubscriptionsButton: React.FC<SellSubscriptionsButtonProps> = inject('userSessionStore')(observer(({ 
  userSessionStore,
  variant = 'success',
  className = 'mr-2'
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleNavigation = (venue: IVenue) => {
    history.push(MembersStatusSubscriptionsModifyLocation.toUrl({
      ...venue.urlFields(),
      statusType: STATUS_TYPE.PUBLIC
    }));
  };

  if (userSessionStore?.isOrganizationContext()) {
    return (
      <Dropdown
        className={className}
        onClick={(e: any) => e.stopPropagation()}
      >
        <Dropdown.Toggle variant={variant} id="dropdown-edit-products-org">
          <FontAwesomeIcon className="mr-2" icon={['fal', 'arrows-rotate']} />
          {t('memberScreen.sellSubscriptions')}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {userSessionStore.currentOrganization!.venues!.map((venue: IVenue) => (
            <Dropdown.Item 
              key={venue.id}
              onClick={() => handleNavigation(venue)}
            >
              {venue.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  return (
    <Button
      className={className}
      onClick={() => handleNavigation(userSessionStore!.selectedBranch! as IVenue)}
      variant={variant}
    >
      <FontAwesomeIcon className="mr-2" icon={['fal', 'arrows-rotate']} />
      {t('memberScreen.sellSubscriptions')}
    </Button>
  );
}));

export default SellSubscriptionsButton; 