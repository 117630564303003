import { Formik } from "formik";
import { toJS } from "mobx";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Container, FormControl, FormGroup, FormLabel, ListGroup, ListGroupItem, Modal } from "react-bootstrap";
import { WithTranslation } from "react-i18next/*";
import useSWR from "swr";
import { v4 as uuidv4 } from "uuid";
import { object } from 'yup';
import { MerchantAdminApi } from "../../Api";
import { ServerAutocompleteAddress } from "../../models/server/ServerAutocompleteAddress";
import { ServerVenueLocation, ServerVenueLocationSchema } from "../../models/server/ServerVenue";
import { ServerVerifiedAddress } from "../../models/server/ServerVerifiedAddress";
import { IUserSessionStore } from "../../models/UserSessionStore";
import SingleLocationField from "./SingleLocationField";
import { useDebounceValue } from "./utils";


interface EditLocationModalProps extends WithTranslation {
  api?: MerchantAdminApi;
  userSessionStore?: IUserSessionStore;
  show: boolean;
  restrictToCity?: string;
  venueLocation?: ServerVenueLocation;
  handleClose: (location?: ServerVenueLocation) => void;
}

const findExactCityName = (cityName: string, AVAILABLE_CITIES_LIST: string[]): string | undefined => {
  return AVAILABLE_CITIES_LIST.find(city => city.localeCompare(cityName, undefined, { sensitivity: "base" }) === 0);
}

const EditLocationModal = (props: EditLocationModalProps) => {
  const [placesSessionId, setPlacesSessionId] = useState(uuidv4());
  const { api, show, venueLocation: oldLocation, t, userSessionStore, restrictToCity } = props;
  const [searchValue, setSearchValue] = useState<string>();
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceValue(searchValue, 800);
  const [placesError, setPlacesError] = useState<string>();
  const [isLoadingPlaceIndex, setLoadingPlaceIndex] = useState<number>();
  const resetSessionId = () => setPlacesSessionId(uuidv4());
  const AVAILABLE_CITIES_LIST = Array.from(new Set(toJS(userSessionStore!.countryConfig!.cities).sort((a, b) => a.localeCompare(b))));
  const restrictCityName = restrictToCity ? findExactCityName(restrictToCity, AVAILABLE_CITIES_LIST) : undefined;

  const handleClose = (location?: ServerVenueLocation) => {
    props.handleClose(location);
    setSearchValue(undefined);
    setDebouncedSearchValue(undefined);
    resetSessionId();
  }

  const fetchLocationFromPlaceId = useCallback(async (placeId: string) => {
    if (!api || !placeId) return undefined;
    const returnResult = await api.fetchPlace(placeId, placesSessionId);
    const data: ServerVerifiedAddress | undefined = returnResult?.payload?.data;
    if (!data) return undefined;

    // Once we make the request, we should reset the session id for the
    // Google Places API Request
    resetSessionId();

    return {
      name: data.name,
      address: data.address,
      postcode: data.postcode,
      city: data.city,
      country: data.country,
      coordinates: {
        lat: data.lat, lng: data.lng
      },
      placeId: data.placeId
    } as ServerVenueLocation;
  }, [api, placesSessionId]);

  const locationFetcher = useCallback(async (searchValue: string) => {
    if (!api || searchValue.length < 4) return [];
    const returnResult = await api.searchPlaces(searchValue, placesSessionId);
    const data = returnResult?.payload?.data;
    return (data ?? []) as ServerAutocompleteAddress[];
  }, [api, placesSessionId]);
  const { data, error } = useSWR(debouncedSearchValue, locationFetcher);
  const isLoading = searchValue && data === undefined && !error;

  const initialValues: { locations: ServerVenueLocation } = {
    locations: oldLocation ?? {
      id: "",
      publicId: "",
      name: "",
      address: "",
      postcode: "",
      city: "",
      country: "",
      coordinates: {
        lat: 0,
        lng: 0
      },
      placeId: ""
    }
  }

  const locationModalSchema = object({
    locations: ServerVenueLocationSchema
  })

  useEffect(() => {
    setPlacesError(undefined);
  }, [searchValue])

  return (

    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        {
          oldLocation ?
            <Modal.Title>
              {t("editLocationModal.editLocationTitle")} - {(oldLocation.name && oldLocation.name !== "") ? oldLocation.name : oldLocation.address}
            </Modal.Title> :
            <Modal.Title>
              {t("editLocationModal.addLocationTitle")}
            </Modal.Title>
        }
      </Modal.Header>

      <Formik
        initialValues={initialValues}
        validationSchema={locationModalSchema}
        onSubmit={values => {
          handleClose(values.locations);
        }}>
        {({ handleSubmit, setValues }) => {
          return (<>
            <Modal.Body className="d-flex justify-content-center">
              <Container className="location-container">
                <h5>{t('editLocationModal.searchLocationTitle')}</h5>
                <FormGroup controlId="tableBookingUri">
                  <FormLabel className="textInputLabel">
                    {t('editLocationModal.searchNameOrAddressTitle')}
                  </FormLabel>
                  <FormControl
                    className="textInput"
                    type={"text"}
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue ?? ""}
                  />
                </FormGroup>
                {isLoading && <div>{t('editLocationModal.loadingMessage')}</div>}
                {placesError && <div><small>{placesError}</small></div>}
                {(data && data.length > 0) ? (
                  <ListGroup className="mx-0 px-0 justify-content-stretch">
                    {data.map(({ placeId, text }, index) => {
                      const [name, ...address] = text.split(",");
                      return (
                        <ListGroupItem key={placeId} className="px-0 py-2">
                          <Button
                            disabled={isLoadingPlaceIndex !== undefined}
                            variant="link"
                            style={{ width: "100%", whiteSpace: "normal", textAlign: "left", display: "flex", flexDirection: "row", alignItems: "center" }}
                            onClick={async () => {
                              setSearchValue(undefined);
                              setDebouncedSearchValue(undefined);

                              setLoadingPlaceIndex(index);
                              const newLocation = await fetchLocationFromPlaceId(placeId);
                              setLoadingPlaceIndex(undefined);

                              if (!newLocation) {
                                setPlacesError(t('editLocationModal.fetchLocationError'));
                                console.error("Unable to fetch location from placeId", placeId);
                                return;
                              }

                              const properCityName = findExactCityName(newLocation.city, AVAILABLE_CITIES_LIST);
                              if (!properCityName) {
                                setPlacesError(t('editLocationModal.unsupportedCityError'));
                                return;
                              }

                              const restrictCityName = restrictToCity ? findExactCityName(restrictToCity, AVAILABLE_CITIES_LIST) : undefined;
                              if (restrictCityName && properCityName !== restrictCityName) {
                                setPlacesError(t('editLocationModal.multicityVenueUnavailableError', { city: restrictCityName }));
                                return;
                              }

                              setValues({ locations: { ...newLocation, city: properCityName } });
                            }}
                          >
                            <div style={{ flexGrow: 1 }}>
                              <div>{name}</div>
                              <div className="small">{address.join(",")}</div>
                            </div>

                          </Button>
                        </ListGroupItem>
                      )
                    })}
                  </ListGroup>) : null}
              </Container>
              <Container className="location-container">
                <h5>{t('editLocationModal.editLocationTitle')}{isLoadingPlaceIndex !== undefined ? <span className="throbber ml-2"></span> : null}</h5>
                <SingleLocationField fieldName="locations" {...props} showName />
              </Container>

            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={() => handleClose()}>
                {t("editLocationModal.closeButton")}
              </Button>
              <Button variant="primary" onClick={() => handleSubmit()}>
                {t("editLocationModal.saveButton")}
              </Button>
            </Modal.Footer>
          </>)
        }}
      </Formik>
    </Modal >

  );
}

export default EditLocationModal;
