import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  BenefitsLocation,
  CreateVenueLocation,
  EventsLocation,
  MembersLocation,
  MessagesLocation,
  RewardsLocation
} from '../Locations';
import { IUserSessionStore } from '../models/UserSessionStore';
import AddBenefitButton from '../screens/BenefitsScreen/AddBenefitButton';
import NewRewardButton from '../screens/RewardsScreen/NewRewardButton';
import { colors } from '../theme/colors';
import CreateEventButton from './buttons/CreateEventButton';
import SendPushMessageButton from './buttons/SendPushMessageButton';
import UpgradeToPremiumModal from './UpgradeToPremiumModal';

interface WindowWithIntercom extends Window {
  Intercom?: (command: string, ...args: any[]) => void;
}

export enum GuideType {
  CREATE_VENUE = 'createVenue',
  CREATE_BENEFIT = 'createBenefit',
  SEND_MESSAGE = 'sendMessage',
  CREATE_SUBSCRIPTION = 'createSubscription',
  CREATE_MEMBERSHIP_CARD = 'createMembershipCard',
  CREATE_EVENT = 'createEvent',
  CREATE_REWARD = 'createReward'
}

interface FeatureStartGuideCardProps {
  guideType: GuideType;
  userSessionStore?: IUserSessionStore;
}

const FeatureStartGuideCard: React.FC<FeatureStartGuideCardProps> = inject('userSessionStore')(
  observer(({ guideType, userSessionStore }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [showPremiumModal, setShowPremiumModal] = useState(false);

    const getActionConfig = () => {
      const urlFields = userSessionStore?.selectedBranch?.urlFields();

 
      switch (guideType) {
        case GuideType.CREATE_VENUE:
          return {
            title: t('manageVenuesScreen.addVenue'),
            icon: 'building',
            onClick: () => history.push(CreateVenueLocation.toUrl({ ...urlFields, action: 'new' }))
          };
        case GuideType.CREATE_BENEFIT:
          return {
            title: t('benefits.addBenefit'),
            icon: 'gift',
            onClick: () => history.push(BenefitsLocation.toUrl({ ...urlFields, action: 'new' }))
          };
        case GuideType.SEND_MESSAGE:
          return {
            title: t('messages.sendPushMessage'),
            icon: 'paper-plane',
            onClick: () => history.push(MessagesLocation.toUrl({ ...urlFields, action: 'new' }))
          };
        case GuideType.CREATE_SUBSCRIPTION:
          return {
            title: t('members.createSubscription'),
            icon: 'credit-card',
            onClick: () => history.push(MembersLocation.toUrl({ ...urlFields, tab: 'subscriptions', action: 'new' }))
          };
        case GuideType.CREATE_MEMBERSHIP_CARD:
          return {
            title: t('members.createMembershipCard'),
            icon: 'id-card',
            onClick: () => history.push(MembersLocation.toUrl({ ...urlFields, tab: 'cards', action: 'new' }))
          };
        case GuideType.CREATE_EVENT:
          return {
            title: t('eventScreen.addButton'),
            icon: 'calendar-plus',
            onClick: () => history.push(EventsLocation.toUrl({ ...urlFields, action: 'new' }))
          };
        case GuideType.CREATE_REWARD:
          return {
            title: t('rewardsScreen.newReward'),
            icon: 'award',
            onClick: () => history.push(RewardsLocation.toUrl({ ...urlFields }))
          };
        default:
          return {
            title: '',
            icon: 'question',
            onClick: () => {}
          };
      }
    };

    const actionConfig = getActionConfig();

    const handleLearnMore = () => {

      const articleIdMap = {
        [GuideType.CREATE_VENUE]: '10494410',
        [GuideType.CREATE_BENEFIT]: '8850836',
        [GuideType.SEND_MESSAGE]: '8855935',
        [GuideType.CREATE_SUBSCRIPTION]: '9771855',
        [GuideType.CREATE_MEMBERSHIP_CARD]: '9771837',
        [GuideType.CREATE_REWARD]: '10938355',
      };

      if (guideType === GuideType.CREATE_EVENT) {
        const hasSalesEnabled = !!userSessionStore?.countryConfig?.salesEnabled;
       
        const articleId = hasSalesEnabled ? '9771885' : '9772316';
        const windowWithIntercom = window as WindowWithIntercom;
        if (windowWithIntercom.Intercom) {
          windowWithIntercom.Intercom('showArticle', articleId);
        } else {
          window.open(`https://help.cluby.com/en/articles/${articleId}`, '_blank');
        }
        return;
      }

      const articleId = articleIdMap[guideType];
      
      if (articleId) {
        const windowWithIntercom = window as WindowWithIntercom;
        if (windowWithIntercom.Intercom) {
          windowWithIntercom.Intercom('showArticle', articleId);
        } else {
          window.open(`https://help.cluby.com/en/articles/${articleId}`, '_blank');
        }
      } else {
        window.open('https://help.cluby.com', '_blank');
      }
    };

    const renderActionButton = () => {
      if (guideType === GuideType.CREATE_BENEFIT) {
        return <AddBenefitButton />;
      }

      if (guideType === GuideType.SEND_MESSAGE) {
        return <SendPushMessageButton onPremiumRequired={() => setShowPremiumModal(true)} />;
      }

      if (guideType === GuideType.CREATE_EVENT) {
        return <CreateEventButton />;
      }

      if (guideType === GuideType.CREATE_REWARD) {
        return <NewRewardButton />;
      }

      return (
        <Button
          variant="primary"
          onClick={actionConfig.onClick}
          className="px-4"
        >
          {actionConfig.title}
        </Button>
      );
    };

    return (
        <>
            <UpgradeToPremiumModal
          showModal={showPremiumModal}
          setShowModal={(value: boolean) => setShowPremiumModal(value)}/>
      <Card style={{
        backgroundColor: "#1e1e1e",
        color: "#ffffff",
        borderRadius: "5px",
        border: `1px solid ${colors.stroke}`,
        margin: '20px'
      }}>
        <Card.Body className="text-center p-5">
         
          <h3 className="mb-3">{t(`featureGuide.${guideType}.title`)}</h3>
          <p className="mb-4">{t(`featureGuide.${guideType}.description`)}</p>
          <div className="d-flex justify-content-center gap-3">
            {renderActionButton()}
            <Button
              variant="outline-light"
              onClick={handleLearnMore}
              className="px-4 ml-4"
            >
              {t('learnMore')}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
    );
  })
);

export default FeatureStartGuideCard; 