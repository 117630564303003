import { getParent, Instance, types } from "mobx-state-tree";
import { getUrlSlug } from "../Locations";
import { MobxLocalizableString as LocalizableString } from "./LocalizableString";
import { IOrganization } from "./Organization";

const ContactInformation = types.model("ContactInformation")
  .props({
    email: types.maybeNull(types.string),
    facebook: types.maybeNull(types.string),
    messenger: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    www: types.maybeNull(types.string),
    whatsapp: types.maybeNull(types.string),
    instagram: types.maybeNull(types.string),
  })

const EnabledFeatures = types.model("EnabledFeatures")
  .props({
    tableBookingUri: types.maybeNull(types.string)
  })

const Images = types.model("Images")
  .props({
    logoImage: types.maybeNull(types.string),
    mainImage: types.maybeNull(types.string)
  })

const LocationCoordinates = types.model("LocationCoordinates")
  .props({
    lat: types.number,
    lng: types.number,
  })

const Location = types.model("Locations")
  .props({
    id: types.maybeNull(types.identifier),
    name: types.maybeNull(types.string),
    address: types.string,
    city: types.string,
    postcode: types.string,
    country: types.string,
    coordinates: types.maybeNull(LocationCoordinates),
    placeId: types.maybeNull(types.string),
    publicId: types.maybeNull(types.string),
  })

export const Venue = types.model("Venue")
  .props({
    id: types.identifier,
    name: types.maybe(types.string),
    namePrefix: types.maybe(types.string),
    isHidden: types.optional(types.boolean, false),
    timezone: types.string,
    lat: types.maybeNull(types.number),
    lng: types.maybeNull(types.number),
    businessId: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    bookingUrl: types.maybeNull(types.string),
    categories: types.maybeNull(LocalizableString),
    contactEmail: types.maybeNull(types.string),
    contactInformation: types.maybeNull(ContactInformation),
    country: types.maybeNull(types.string),
    description: types.maybeNull(LocalizableString),
    enabledFeatures: types.maybeNull(EnabledFeatures),
    fbPageId: types.maybeNull(types.string),
    handle: types.maybeNull(types.string),
    images: types.maybeNull(Images),
    logoUrl: types.maybeNull(types.string),
    postcode: types.maybeNull(types.string),
    street: types.maybeNull(types.string),
    locations: types.maybeNull(types.array(Location)),
    _bankAccount: types.maybeNull(types.string)
  }).views(self => ({
    urlFields(): any {
      const parentOrganization: IOrganization = getParent(self, 2) as IOrganization
      const organizationName = getUrlSlug(parentOrganization.name!)

      return {
        id: self.id,
        idType: "venue",
        venue: getUrlSlug(self.name!),
        organization: organizationName,
      }
    },
  }))

export type IVenue = Instance<typeof Venue>
