import { Instance, types } from "mobx-state-tree";
import { Benefit } from "./Benefit";
import { MobxLocalizableString as LocalizableString } from "./LocalizableString";
import { Product } from "./Product";
import { Venue } from "./Venue";

const VenueItemReference = types.late(() =>
  types.union(
    types.reference(Product),
    types.reference(Benefit)
  )
);


const RewardData = types.model("RewardData")
  .props({
    venue_item_id: VenueItemReference,
    venue_item_type: types.optional(
      types.enumeration("VenueItemType", ["VENUE_PRODUCT", "VENUE_BENEFIT"]),
      "VENUE_BENEFIT"
    ),
    title: types.optional(LocalizableString, {}),
    description: types.optional(LocalizableString, {}),
  });

const ValidItems = types.model("ValidItems")
  .props({
    items: types.array(types.integer),
    groups: types.array(types.integer),
    excluded: types.array(types.integer),
  });

const LoyaltyCardSettings = types.model("LoyaltyCardSettings")
  .props({
    cap: types.integer,
    includeCap: types.boolean,
    validItems: ValidItems,
    ignoreZeroAmount: types.maybe(types.boolean),
  });

type ILoyaltyCardSettings = Instance<typeof LoyaltyCardSettings>;

const LoyaltyCardName = types.model("LoyaltyCardName")
  .props({
    text: LocalizableString,
  })

export const LoyaltyCard = types.model("LoyaltyCard")
  .props({
    id: types.identifier,
    restaurantIds: types.array(types.reference(Venue)),
    check_balances: types.maybe(types.integer),
    register_purchases: types.maybe(types.integer),
    total_rewards: types.maybe(types.integer),
    total_rewards_30: types.maybe(types.integer),
    rewards_redeemed: types.maybe(types.integer),
    rewards_redeemed_30: types.maybe(types.integer),
    total_unique_users: types.maybe(types.integer),
    total_unique_users_30: types.maybe(types.integer),
    total_stamps: types.maybe(types.integer),
    total_stamps_30: types.maybe(types.integer),
    total_valid_stamps: types.maybe(types.integer),
    total_valid_stamps_30: types.maybe(types.integer),
    total_expired_stamps: types.maybe(types.integer),
    total_card_count: types.maybe(types.integer),
    total_full_count: types.maybe(types.integer),
    total_full_valid_count: types.maybe(types.integer),
    total_incomplete_valid_count: types.maybe(types.integer),
    settings: types.maybe(LoyaltyCardSettings),
    reward_data: types.maybe(RewardData),
    name: types.maybe(LoyaltyCardName),
  })
  .views(self => ({
    localeName(language: string | undefined = undefined) {
      return self.name?.text.getTranslation(language) || '';
    },
    stampsCountForAReward() {
      return (self.settings?.cap || 0) - (self.settings?.includeCap ? 0 : 1);
    },
    localeRewardTitle(language: string | undefined = undefined) {
      return self.reward_data?.title.getTranslation(language) || '';
    },
    localeRewardDescription(language: string | undefined = undefined) {
      return self.reward_data?.description.getTranslation(language) || '';
    },
  }))
  .actions(self => ({
  }))

export type ILoyaltyCard = Instance<typeof LoyaltyCard>;
