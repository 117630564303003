import { TFunction } from 'i18next';
import { inject, observer } from "mobx-react";
import {useFeatureFlagEnabled} from "posthog-js/react";
import React, { CSSProperties } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { IReward } from '../../models/Reward';
import { IRewardStore } from '../../models/RewardStore';
import { colors } from '../../theme/colors';
import { getRewardAssignedVenuesName, getRewardTypeText } from './utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from "react-i18next"
import { RewardModifyLocation } from '../../Locations';
import { IUserSessionStore } from '../../models/UserSessionStore';
import { IVenue } from '../../models/Venue';
import { truncateText } from '../../utils';
  
  interface RewardProps {
   reward: IReward;
    t: TFunction;
    navToModifyScreen: () => void;
    isSingleVenue?: boolean;
  }
  

  const columnTitleStyle: CSSProperties = {
    textAlign: 'center',
    color: colors.white,
    fontSize: 14,
    marginBottom: 10
  }

  const columnValueStyle: CSSProperties = {
    textAlign: 'center',
    color: colors.white,
    fontSize: 22,
    fontWeight: 'bold'
  }

  const detailStyle: CSSProperties = {
    textAlign: 'left',
    color: colors.secondaryText,
    fontSize: 16,
    marginBottom: 16
  }

 const sortRewards = (rewards: IReward[])=>  {

  
    const activeRewards = rewards.filter(reward => reward.active);
    const inactiveRewards = rewards.filter(reward => !reward.active);
  
    return [...activeRewards, ...inactiveRewards];
  }
  


  const Reward: React.FC<RewardProps> = ({ reward, navToModifyScreen, isSingleVenue, t }) => {
    const isRewardsEditingEnabled = useFeatureFlagEnabled('partnerhub-rewards-editing')


    const rewardTypeTitle = `${getRewardTypeText({rewardType: reward.reward_trigger_id.type, t})} | ${reward.active ? t('active') : t('inactive')}`

    console.log('reward', reward)

    return (
      <Card className="mt-4 smallCardPadding">
        <Card.Header style={{
            background: colors.boxFill,
            border: `1px solid #353535`,
            borderBottom: 'none',
            paddingTop: 5,
            paddingBottom: 5,
        }} className="d-flex justify-content-between align-items-center">
          <div style={{
            fontSize: 20,
            color: colors.white
          }}>{rewardTypeTitle}</div>
          {isRewardsEditingEnabled && <Button
          style={{
            background: 'transparent',
            color: colors.secondaryText,
            fontWeight: 'bold',
            borderColor: 'transparent'
          }}
          onClick={navToModifyScreen}
            variant="success"><FontAwesomeIcon className="mr-2"
                                               icon={["fal", "edit"]}/>{"Modify"}
          </Button>}
        </Card.Header>
        <Card.Body className="pl-2 pr-2" style={{
          paddingBottom: 10
        }}>
          <Container fluid>
            <Row>
              <Col xs={12} md={6}>
              {reward.localeDescription() &&
                <div  style={detailStyle}>{truncateText(reward.localeDescription(), 140)}</div>
                }
                {!isSingleVenue &&  reward.assigned_venues &&
                     <div  style={detailStyle}>{getRewardAssignedVenuesName({
                      venues: reward.assigned_venues,
                      t
                     })}</div>
                }
         
              
                <div >
                  <div style={{
                    display: 'inline-block',
                    width: '50%'
                  }}>
                  
                        {reward.localeTitle() &&

                        <div  style={detailStyle}>{t('rewardsScreen.reward')}: {reward.localeTitle()}</div>
                      }

                      {reward.timing_rules?.validity?.duration &&
                        <div  style={{...detailStyle, fontSize: 14}}>{t('rewardsScreen.rewardExpiration')}: {reward.timing_rules.validity.duration} {t('days')}</div>
                      }
                  </div>

                  <div style={{
                       display: 'inline-block',
                       width: '50%'
                  }}>
                           <div  style={{...detailStyle, fontSize: 14}}>{t('rewardsScreen.redeem')}: {t('rewardsScreen.activation')}</div>
            
               
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <Row>
                  {/* <Col xs={3} md={3}>
                  </Col>
                  <Col xs={3} md={3} >
                  </Col> */}
                  <Col xs={6} md={6} style={{
                    textAlign: 'center'
                  }}>
                    {reward.images?.mainImage &&
                            <img src={reward.images?.mainImage || undefined} style={{
                              width: '80%',
                              height: 'auto',
                              borderRadius: 10
                            }} alt="" />
                    }
              
                    </Col>
                  <Col xs={3} md={3} >
                    <div  style={columnTitleStyle}>{t('rewardsScreen.rewardsGiven')}</div>
                    <div style={columnValueStyle}>{reward.rewards_given}</div>
                  </Col>
                  <Col xs={3} md={3} >
                    <div  style={columnTitleStyle}>{t('rewardsScreen.rewardsRedeemed')}</div>
                    <div style={columnValueStyle}>{reward.rewards_redeemed}</div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    );
  };


export interface RewardsListProps extends RouteComponentProps, WithTranslation {
  isSingleVenue?: boolean, 
  rewardStore?: IRewardStore
  userSessionStore?: IUserSessionStore
}

const RewardsList = ({isSingleVenue, userSessionStore, rewardStore, t, history}: RewardsListProps) => {


  const rewards = rewardStore?.getRewards({
    isOrganizationContext: userSessionStore!.isOrganizationContext(),
    selectedBranchId: userSessionStore!.selectedBranch?.id
  }) || []

  const sortedRewards = sortRewards(rewards)



    const navToModifyScreen = (rewardId: string, rewardType: string) => {
      const selectedVenue = userSessionStore!.selectedBranch! as IVenue
      history.push(RewardModifyLocation.toUrl({...selectedVenue?.urlFields(), rewardId: rewardId, rewardType: rewardType}))
    }

  return (
    <div>
      {sortedRewards.map((reward, index) => (
        <Reward key={index} reward={reward} t={t} isSingleVenue={userSessionStore!.currentOrganization!.isSingleVenue()} navToModifyScreen={() => navToModifyScreen(reward.id, reward.reward_trigger_id.type)} />
      ))}
    </div>
  );
};

export default withRouter(withTranslation()(inject("userSessionStore", "rewardStore")(observer(RewardsList))));
