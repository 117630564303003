import { Instance, types } from "mobx-state-tree"


export const Invitation = types.model("Invitation")
  .props({
    id: types.identifier,
    email: types.string,
    name: types.string,
    created: types.string,
    expiry: types.maybeNull(types.string),
    weeklySummary: types.maybeNull(types.enumeration(['EMAIL'])),
  }).views(self => ({
    hasWeeklySummary(): boolean {
      return self.weeklySummary === 'EMAIL';
    }
  })).actions(self => ({
    toggleWeeklySummary(enabled: boolean) {
      self.weeklySummary = enabled ? 'EMAIL' : null;
    }
  }))

export type IInvitation = Instance<typeof Invitation>
