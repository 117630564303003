import React from 'react'
import { Field } from 'formik'
import { FormGroup, FormLabel, FormControl } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

interface FormikDescriptionFieldProps {
  name: string
  languageCode: string
  errors: any
  touched: any
  values: any
  onChange?: (e: React.ChangeEvent<any>) => void
  setFieldValue?: (field: string, value: any) => void
  labelTextKey: string
  placeholderTextKey: string
  isAdditionalField?: boolean
  syncWithField?: string 
}

export const FormikDescriptionField: React.FC<FormikDescriptionFieldProps> = ({
  name,
  languageCode,
  errors,
  touched,
  values,
  onChange,
  setFieldValue,
  labelTextKey,
  placeholderTextKey,
  isAdditionalField = false,
  syncWithField
}) => {
  const { t } = useTranslation()
  const fieldPath = name.split('.')
  const error = fieldPath.reduce((obj, key) => obj?.[key], errors)
  const isTouched = fieldPath.reduce((obj, key) => obj?.[key], touched)
  const value = fieldPath.reduce((obj, key) => obj?.[key], values)

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (setFieldValue) {
      setFieldValue(name, e.target.value)
      if (syncWithField) {
        setFieldValue(syncWithField, e.target.value)
      }
    } else {
      onChange?.(e)
    }
  }

  return (
    <Field
      name={name}
      render={() => (
        <FormGroup controlId={name}>
          <FormLabel className={`textInputLabel ${error && isTouched ? 'text-danger' : ''}`}>
            {t(labelTextKey)}
          </FormLabel>
          <FormControl 
            className="textInput" 
            as="textarea" 
            rows={4} 
            isInvalid={error && isTouched}
            placeholder={t(placeholderTextKey, { lng: languageCode })}
            value={value || ''}
            onChange={handleChange}
          />
          {error && isTouched && (
            <small className="text-danger">{error}</small>
          )}
        </FormGroup>
      )}
    />
  )
}