import { IUserSessionStore } from "../../models/UserSessionStore";
import * as featureChecks from '../OnboardingFeaturesGuide/featureCompletionChecks';

const getHasOrganizationVenues = ({userSessionStore}: {userSessionStore?: IUserSessionStore}) => {
  return Boolean(userSessionStore?.currentOrganization?.venues?.length)
}

export { 
  getHasOrganizationVenues,
  featureChecks
};