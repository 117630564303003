export interface CategoryType {
  [language: string]: string
}

// @FIXME: Add translations for categories to u18n files
export const categories = {
  "Activity": {en: 'Activity', fi: 'Aktiviteetti', es: 'Actividad', nb: 'Aktivitet'},
  "African": {en: 'African', fi: 'Afrikkalainen', es: 'Africano', nb: 'Afrikansk'},
  "American": {en: 'American', fi: 'Amerikkalainen', es: 'Americano', nb: 'Amerikansk'},
  "Asian": {en: 'Asian', fi: 'Aasialainen', es: 'Asiático', nb: 'Asiatisk'},
  "Bakery": {en: 'Bakery', fi: 'Leipomo', es: 'Panadería', nb: 'Bakeri'},
  "Bar": {en: 'Bar', fi: 'Baari', es: 'Bar', nb: 'Bar'},
  // "Barber shop": {en: 'Barber shop', fi: 'Parturi', es: 'Barbería', nb: 'Frisørsalong'},
  "BBQ": {en: 'BBQ', fi: 'BBQ', es: 'Barbacoa', nb: 'BBQ'},
  // "Beauty salon": {en: 'Beauty salon', fi: 'Kauneushoito', es: 'Salón de belleza', nb: 'Skjønnhetssalong'},
  "Beer": {en: 'Beer', fi: 'Olut', es: 'Cerveza', nb: 'Øl'},
  "Bistro": {en: 'Bistro', fi: 'Bistro', es: 'Bistró', nb: 'Bistro'},
  "Breakfast": {en: 'Breakfast', fi: 'Aamiainen', es: 'Desayuno', nb: 'Frokost'},
  "Brewery": {en: 'Brewery', fi: 'Panimo', es: 'Cervecería', nb: 'Bryggeri'},
  "British": {en: 'British', fi: 'Brittiläinen', es: 'Británico', nb: 'Britisk'},
  "Burger": {en: 'Burger', fi: 'Burgeri', es: 'Hamburguesa', nb: 'Burger'},
  "Cafe": {en: 'Cafe', fi: 'Kahvila', es: 'Café', nb: 'Kafé'},
  "Chinese": {en: 'Chinese', fi: 'Kiinalainen', es: 'Chino', nb: 'Kinesisk'},
  "Cocktail": {en: 'Cocktail', fi: 'Cocktail', es: 'Cóctel', nb: 'Cocktail'},
  "Cuban": {en: 'Cuban', fi: 'Kuubalainen', es: 'Cubano', nb: 'Kubansk'},
  "Deli": {en: 'Deli', fi: 'Deli', es: 'Delicatessen', nb: 'Delikatesse'},
  "Dessert": {en: 'Dessert', fi: 'Jälkiruoka', es: 'Postre', nb: 'Dessert'},
  "Dinner": {en: 'Dinner', fi: 'Illallinen', es: 'Cena', nb: 'Middag'},
  "European": {en: 'European', fi: 'Eurooppalainen', es: 'Europeo', nb: 'Europeisk'},
  "Fastfood": {en: 'Fastfood', fi: 'Pikaruoka', es: 'Comida rápida', nb: 'Hurtigmat'},
  "Fine dining": {en: 'Fine dining', fi: 'Fine dining', es: 'Alta cocina', nb: 'Fine dining'},
  "Finnish": {en: 'Finnish', fi: 'Suomalainen', es: 'Finlandés', nb: 'Finsk'},
  "French": {en: 'French', fi: 'Ranskalainen', es: 'Francés', nb: 'Fransk'},
  "Gastro": {en: 'Gastro', fi: 'Gastro', es: 'Gastro', nb: 'Gastro'},
  "Georgian": {en: 'Georgian', fi: 'Georgialainen', es: 'Georgiano', nb: 'Georgisk'},
  "German": {en: 'German', fi: 'Saksalainen', es: 'Alemán', nb: 'Tysk'},
  "Gin": {en: 'Gin', fi: 'Gin', es: 'Gin', nb: 'Gin'},
  "Greek": {en: 'Greek', fi: 'Kreikkalainen', es: 'Griego', nb: 'Gresk'},
  "Grill": {en: 'Grill', fi: 'Grilli', es: 'Parrilla', nb: 'Grill'},
  "Gym": {en: 'Gym', fi: 'Kuntosali', es: 'Gimnasio', nb: 'Treningssenter'},
  // "Hair salon": {en: 'Hair salon', fi: 'Kampaamo', es: 'Peluquería', nb: 'Frisørsalong'},
  "Healthy": {en: 'Healthy', fi: 'Terveellinen', es: 'Saludable', nb: 'Sunn'},
  "Indian": {en: 'Indian', fi: 'Intialainen', es: 'Indio', nb: 'Indisk'},
  "Irish": {en: 'Irish', fi: 'Irlantilainen', es: 'Irlandés', nb: 'Irsk'},
  "Italian": {en: 'Italian', fi: 'Italialainen', es: 'Italiano', nb: 'Italiensk'},
  "Japanese": {en: 'Japanese', fi: 'Japanilainen', es: 'Japonés', nb: 'Japansk'},
  "Juice Bar": {en: 'Juice Bar', fi: 'Mehubaari', es: 'Bar de jugos', nb: 'Juicebar'},
  "Karaoke": {en: 'Karaoke', fi: 'Karaoke', es: 'Karaoke', nb: 'Karaoke'},
  "Kebab": {en: 'Kebab', fi: 'Kebab', es: 'Kebab', nb: 'Kebab'},
  "Korean": {en: 'Korean', fi: 'Korealainen', es: 'Coreano', nb: 'Koreansk'},
  "Lebanese": {en: 'Lebanese', fi: 'Libanonilainen', es: 'Libanés', nb: 'Libanesisk'},
  "Leisure": {en: 'Leisure', fi: 'Vapaa-aika', es: 'Ocio', nb: 'Fritid'},
  "Live music": {en: 'Live music', fi: 'Live-musiikki', es: 'Música en vivo', nb: 'Live musikk'},
  "Lunch": {en: 'Lunch', fi: 'Lounas', es: 'Almuerzo', nb: 'Lunsj'},
  // "Massage": {en: 'Massage', fi: 'Hieronta', es: 'Masaje', nb: 'Massasje'},
  "Mediterranean": {en: 'Mediterranean', fi: 'Välimerellinen', es: 'Mediterráneo', nb: 'Middelhavsk'},
  "Mexican": {en: 'Mexican', fi: 'Meksikolainen', es: 'Mexicano', nb: 'Meksikansk'},
  "Middle-East": {en: 'Middle-East', fi: 'Lähi-itä', es: 'Medio Oriente', nb: 'Midtøsten'},
  // "Nail salon": {en: 'Nail salon', fi: 'Kynsihoito', es: 'Salón de uñas', nb: 'Neglsalong'},
  "Nightclub": {en: 'Nightclub', fi: 'Yökerho', es: 'Discoteca', nb: 'Nattklubb'},
  "Pizza": {en: 'Pizza', fi: 'Pizza', es: 'Pizza', nb: 'Pizza'},
  "Pub": {en: 'Pub', fi: 'Pub', es: 'Pub', nb: 'Pub'},
  "Raw food": {en: 'Raw food', fi: 'Raakaruoka', es: 'Comida cruda', nb: 'Rå mat'},
  "Restaurant": {en: 'Restaurant', fi: 'Ravintola', es: 'Restaurante', nb: 'Restaurant'},
  "Russian": {en: 'Russian', fi: 'Venäläinen', es: 'Ruso', nb: 'Russisk'},
  "Salad": {en: 'Salad', fi: 'Salaatti', es: 'Ensalada', nb: 'Salat'},
  "Scandinavian": {en: 'Scandinavian', fi: 'Pohjoismainen', es: 'Escandinavo', nb: 'Skandinavisk'},
  "Seafood": {en: 'Seafood', fi: 'Merenelävät', es: 'Mariscos', nb: 'Sjømat'},
  "Soups": {en: 'Soups', fi: 'Keitot', es: 'Sopas', nb: 'Supper'},
  "Spa": {en: 'Spa', fi: 'Kylpylä', es: 'Spa', nb: 'Spa'},
  "Spanish": {en: 'Spanish', fi: 'Espanjainen', es: 'Español', nb: 'Spansk'},
  "Sportbar": {en: 'Sportbar', fi: 'Sporttibaari', es: 'Bar deportivo', nb: 'Sportsbar'},
  "Steakhouse": {en: 'Steakhouse', fi: 'Pihviravintola', es: 'Asador', nb: 'Steakhouse'},
  "Street Food": {en: 'Street Food', fi: 'Katuruoka', es: 'Comida callejera', nb: 'Gatemat'},
  "Sushi": {en: 'Sushi', fi: 'Sushi', es: 'Sushi', nb: 'Sushi'},
  "Swedish": {en: 'Swedish', fi: 'Ruotsalainen', es: 'Sueco', nb: 'Svensk'},
  "Terrace": {en: 'Terrace', fi: 'Terassi', es: 'Terraza', nb: 'Terrasse'},
  "Tequila": {en: 'Tequila', fi: 'Tequila', es: 'Tequila', nb: 'Tequila'},
  "Thai": {en: 'Thai', fi: 'Thaimaalainen', es: 'Tailandés', nb: 'Thai'},
  "Turkish": {en: 'Turkish', fi: 'Turkkilainen', es: 'Turco', nb: 'Tyrkisk'},
  "Vegan": {en: 'Vegan', fi: 'Vegan', es: 'Vegano', nb: 'Vegansk'},
  "Vegetarian": {en: 'Vegetarian', fi: 'Kasvisruoka', es: 'Vegetariano', nb: 'Vegetarisk'},
  "Wellbeing": {en: 'Wellbeing', fi: 'Hyvinvointi', es: 'Bienestar', nb: 'Velvære'},
  "Wine": {en: 'Wine', fi: 'Viini', es: 'Vino', nb: 'Vin'},
  "Wings": {en: 'Wings', fi: 'Wings', es: 'Alitas', nb: 'Wings'},
  "Events": {en: 'Events', fi: 'Tapahtumat', es: 'Eventos', nb: 'Arrangementer'},
}
