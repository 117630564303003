import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface SkipConfirmationModalProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  itemTitle: string;
}

const SkipConfirmationModal: React.FC<SkipConfirmationModalProps> = ({
  show,
  onHide,
  onConfirm,
  itemTitle,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="text-center w-100">
          <div className="font-weight-bold">
            {t('onboardingFeaturesGuide.skipModal.title')}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="mt-2" style={{ fontSize: 18 }}>
          {t('onboardingFeaturesGuide.skipModal.description', { itemTitle })}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="outline-primary" onClick={onHide}>
          {t('onboardingFeaturesGuide.skipModal.cancel')}
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          {t('onboardingFeaturesGuide.skipModal.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SkipConfirmationModal; 