import {RouteComponentProps, withRouter} from "react-router"
import {Selection} from "../components/DateRangeDropdown";
import {IUserSessionStore} from "../models/UserSessionStore"
import {inject, observer} from "mobx-react"
import React from "react"
import {withTranslation, WithTranslation} from "react-i18next"
import {IBenefitReportStore} from "../models/BenefitReportStore"
import {BenefitTable} from "../components/BenefitTable"
import {IRootStore} from "../models/RootStore"
import AlertMessage from "../components/AlertMessage"
import {trackEvent} from "../tracking"
import trackingConstants from "../tracking/trackingConstants"
import log from "loglevelnext"
import Dropdown from "react-bootstrap/Dropdown"
import Button from "react-bootstrap/Button"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {BenefitsModifyLocation} from "../Locations"
import {IVenue} from "../models/Venue"
import {SummaryHeader, SummaryItem} from "../components/SummaryHeader"
import moment from "moment-timezone"
import {getOrganizationTimezone} from "../utils/dateUtils";
import { IBenefitStore } from '../models/BenefitStore';
import FeatureStartGuideCard, { GuideType } from "../components/FeatureStartGuideCard";
import AddBenefitButton from "./BenefitsScreen/AddBenefitButton"
import { getHasOrganizationVenues, featureChecks } from "../components/FeatureStartGuideCard/utils";
import posthog from "posthog-js";

const BENEFIT_SCREEN_ERROR = "benefitScreen"

export interface BenefitsScreenProps extends RouteComponentProps, WithTranslation {
  userSessionStore?: IUserSessionStore
  benefitReportStore?: IBenefitReportStore
  rootStore?: IRootStore
  benefitStore?: IBenefitStore
}

@(withRouter as any)
@inject("userSessionStore")
@inject("benefitReportStore")
@inject("benefitStore")
@observer
class BenefitsScreen extends React.Component<BenefitsScreenProps> {

  formatTimezone: string = getOrganizationTimezone(this.props.userSessionStore)

  handleDateFilter(this: this, selection: { startDate: Date, endDate: Date } | null) {
    log.debug("BenefitsScreen: handleDateFilter, selection:", selection)
    this.props.benefitReportStore!.setDateFilter(selection)
  }

  dateFilterFormatter(this: this, selection: Selection) {
    selection.selection.startDate = new Date(moment(selection.selection.startDate).utc().add(-(new Date().getTimezoneOffset()),
      "minutes").tz(this.formatTimezone, true))

    selection.selection.endDate = new Date(moment(selection.selection.endDate).utc().add(-(new Date().getTimezoneOffset()),
      "minutes").tz(this.formatTimezone, true))

    return selection
  }

  componentDidMount(): void {
    log.debug("BenefitsScreen mounted")
    trackEvent(trackingConstants.screens.benefits, {selectedBranch: this.props.userSessionStore!.selectedBranch!.name})
  }

  forwardToModifyScreen(venue: IVenue) {
    this.props.history.push(BenefitsModifyLocation.toUrl(venue.urlFields()))
  }

  render() {
    const {userSessionStore, benefitReportStore, t, benefitStore} = this.props

    const summaries: Array<SummaryItem> = [
      {
        title: t("benefitScreen.summary.totalActivationsTitle"),
        value: benefitReportStore!.reporting.benefitsUsedTotal.toString(),
        footer: t("benefitScreen.summary.totalActivationsFooter"),
      },
      {
        title: t("benefitScreen.summary.activationsLastMonthTitle"),
        value: benefitReportStore!.reporting.benefitsUsedLast30Days.toString(),
        footer: t("benefitScreen.summary.activationsLastMonthFooter"),
      },
    ]

    const headerButtons = (
      <>
         {userSessionStore!.isOrganizationContext() ?
          <Dropdown
            className="mr-2"
            /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
            onClick={(e: any) => e.stopPropagation()}
          >
            <Dropdown.Toggle variant="secondary" id="dropdown-edit-benefits-org">
              <FontAwesomeIcon className="mr-2" icon={["fal", "pen-to-square"]} />{t("benefitScreen.modifyButton")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {userSessionStore!.currentOrganization!.venues!.map((venue: IVenue) => {
                return (
                  <Dropdown.Item key={venue.id}
                    onClick={this.forwardToModifyScreen.bind(this, venue)}
                  >{venue.name}</Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
          :
          <Button
            className="mr-2"
            onClick={this.forwardToModifyScreen.bind(this, userSessionStore!.selectedBranch! as IVenue)}
            variant="secondary"><FontAwesomeIcon className="mr-2"
            icon={["fal", "pen-to-square"]} />{t("benefitScreen.modifyButton")}
          </Button>}
        <AddBenefitButton />
      </>
    )

    const hasVenues = getHasOrganizationVenues({userSessionStore});
    const isOrganizationContext = userSessionStore?.isOrganizationContext();
    const selectedVenueId = !isOrganizationContext ? userSessionStore?.selectedBranch?.id : null;
    const hasBenefits = featureChecks.hasBenefits({benefitStore, isOrganizationContext, selectedVenueId});

    if (posthog.isFeatureEnabled('onboarding')) {
      if (!hasVenues) {
        return <FeatureStartGuideCard guideType={GuideType.CREATE_VENUE} />;
      }

      if (!hasBenefits) {
        return <FeatureStartGuideCard guideType={GuideType.CREATE_BENEFIT} />;
      }
    }

    return (
      <div>
        <AlertMessage source={BENEFIT_SCREEN_ERROR} />
        <SummaryHeader summaries={summaries} isSingleVenue={userSessionStore!.currentOrganization!.isSingleVenue()}
          buttons={headerButtons} />

        <BenefitTable data={benefitReportStore!.filteredReport}
          dateFilterFormatter={this.dateFilterFormatter.bind(this)}
          onDateFilterChange={this.handleDateFilter.bind(this)}
          timezone={this.formatTimezone}
          isOrganizationContext={userSessionStore!.isOrganizationContext()} />
      </div>
    )
  }
}

export default withTranslation()(BenefitsScreen)
