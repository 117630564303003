import log from "loglevelnext"
import { observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router"
import { MerchantAdminApi } from "../../Api"
import MemberTable from "../../components/MemberTable"
import { useStores } from "../../hooks/UseStores"
import { IBenefitReportStore } from "../../models/BenefitReportStore"
import { IEventStore } from "../../models/EventStore"
import { ILoyaltyCardStore } from "../../models/LoyaltyCardStore"
import { IMemberStore } from "../../models/MemberStore"
import { IProductReportStore } from "../../models/ProductReportStore"
import { IUserSessionStore } from "../../models/UserSessionStore"
import { trackEvent } from "../../tracking"
import trackingConstants from "../../tracking/trackingConstants"
import MembersScreenModals from "./MembersScreenModals"
import MembersScreenSummaryHeaders from "./MembersScreenSummaryHeaders"
import { Spinner } from 'react-bootstrap';
import { getHasOrganizationVenues } from "../../components/FeatureStartGuideCard/utils"
import FeatureStartGuideCard, { GuideType } from "../../components/FeatureStartGuideCard"
import posthog from "posthog-js"

export interface MembersScreenProps extends RouteComponentProps, WithTranslation {
  userSessionStore?: IUserSessionStore
  memberStore?: IMemberStore
  eventStore?: IEventStore
  api?: MerchantAdminApi
  benefitReportStore?: IBenefitReportStore
  productReportStore?: IProductReportStore
  loyaltyCardStore?: ILoyaltyCardStore

}


const MembersScreen: React.FC = observer(() => {
 
  const { userSessionStore, memberStore, benefitReportStore, eventStore, productReportStore, api } = useStores()


  const [hasRefetchedMembersWithResetFilters, setHasRefetchedMembersWithResetFilters] = useState(false)

  useEffect(() => {
    log.debug("MembersScreen mounted")
    trackEvent(trackingConstants.screens.members, { selectedBranch: userSessionStore!.selectedBranch!.name })
  }, [userSessionStore])

  const fetchMembersWithResetFilters = async () => {
    console.log('triggering fetchMembers with reset filters')
 
    await memberStore?.fetchMembers({
     resetFilters: true
   });
   memberStore?.resetFilters()
   setHasRefetchedMembersWithResetFilters(true)
  }

  useEffect(() => {
    const isVenueEventInFilters = Boolean(memberStore?.filter.venueEvent)

    if (isVenueEventInFilters && !hasRefetchedMembersWithResetFilters) {
      fetchMembersWithResetFilters()
    }
  }, [memberStore?.filter.venueEvent, hasRefetchedMembersWithResetFilters])


    const membersData = memberStore!.members.slice()
  
    const hasAnyBenefitActivations = benefitReportStore!.reporting.benefitsUsedTotal > 0
    const hasAnyEventsSales = eventStore?.events?.length
    const hasAnyProductsSales = productReportStore?.reporting.soldTotal
    const hasAnySales = (hasAnyEventsSales || hasAnyProductsSales) ? true : false
  
    const isVenueEventInFilters = Boolean(memberStore?.filter.venueEvent)


   const isRefetchingMembersDataWithResetFilters = isVenueEventInFilters && !hasRefetchedMembersWithResetFilters


   const hasVenues = getHasOrganizationVenues({userSessionStore})

   if (posthog.isFeatureEnabled('onboarding')) {
   if (!hasVenues) {
     return <FeatureStartGuideCard guideType={GuideType.CREATE_VENUE} />;
   }
  }

    return (
      <div>
   
      <MembersScreenModals />

        <MembersScreenSummaryHeaders/>
        {isRefetchingMembersDataWithResetFilters ? (
          <div className="text-center mt-5">
            <Spinner animation="border" role="status" variant="primary">
            </Spinner>
          </div>
        ) : (
                  <MemberTable data={membersData}
                  showStatuses={memberStore!.statuses.length > 0}
                  stats={{...memberStore!.stats}}
                  filter={{...memberStore!.filter}}
                  hasAnyBenefitActivations={hasAnyBenefitActivations}
                  hasAnySales={hasAnySales}
                />
        )}
         
      </div>
  )
})

export default withTranslation()(MembersScreen)
