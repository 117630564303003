import { Instance, types } from "mobx-state-tree";
import { InferType, object, string } from 'yup';
import i18n from '../i18n';

export const LocalizableStringSchema = object({
  default: string().required(),
  en: string(),
  fi: string(),
  es: string(),
  nb: string(),
})

export type LocalizableString = InferType<typeof LocalizableStringSchema>

export const MobxLocalizableString = types.model("LocalizableString")
  .props({
    default: types.maybe(types.string),
    en: types.maybe(types.string),
    fi: types.maybe(types.string),
    es: types.maybe(types.string),
    nb: types.maybe(types.string),
  })
  .views(self => ({
    getTranslation(language: string = i18n.languages[0]): string {
      const isValidKey = language in self;
      const translation = isValidKey ? self[language as keyof typeof self] : undefined;
      return translation || self.en || self.default || '';
    }
  }));

export const transformToLocalizableString = (input: Instance<typeof MobxLocalizableString> | null): LocalizableString => ({
  default: input?.default ?? '',
  en: input?.en ?? undefined,
  fi: input?.fi ?? undefined,
  es: input?.es ?? undefined,
  nb: input?.nb ?? undefined,
})
