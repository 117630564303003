import { Instance, types } from "mobx-state-tree";
import { MobxLocalizableString as LocalizableString } from "./LocalizableString";
import { Venue } from "./Venue";

export enum ProductFormat {
  OFFER = "OFFER",
  OFFER_WITH_PRICE = "OFFER_WITH_PRICE",
  undefined = "undefined",
}

export const DEFAULT_PRODUCT_ACTIVE_PERIOD_SECONDS = 5 * 60

const Images = types.model("Images")
  .props({
    mainImage: types.maybeNull(types.string)
  })


const ProductValidity = types.model("ProductValidity")
  .props({
    duration: types.maybeNull(types.integer),
    durationUnit: types.maybeNull(types.string)
  })

const ProductRules = types.model("ProductRules")
  .props({
    activation: types.maybeNull(types.integer),
    quantity: types.maybeNull(types.integer),
    type: types.maybeNull(types.string),
    isReward: types.maybeNull(types.boolean),
  })

const ProductPricing = types.model("ProductPricing")
  .props({
    amount: types.maybeNull(types.integer),
    vatPercentage: types.maybeNull(types.number),
    currency: types.maybeNull(types.string)
  })

export const Product = types
  .model("Product")
  .props({
    id: types.identifier,
    restaurantId: types.reference(Venue),
    title: types.optional(LocalizableString, {}),
    description: types.optional(LocalizableString, {}),
    validity: ProductValidity,
    rules: ProductRules,
    pricing: ProductPricing,
    images: types.maybeNull(Images),
    visible: types.boolean
  })
  .views(self => ({
    localePrice(language: string | undefined = undefined) {
    },
  }))
  .views(self => ({
    localeTitle(language: string | undefined = undefined) {
      return self.title.getTranslation(language);
    },
    localeDescription(language: string | undefined = undefined) {
      return self.description.getTranslation(language);
    },
  }))
  .actions(self => ({
  }))

export type IProduct = Instance<typeof Product>
